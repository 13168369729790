import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import Constant from "../../../config/constans";
import { PlusOutlined, HomeOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Breadcrumb,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Layout,
  Table,
  Spin,
  message,
  Popconfirm,
  Typography,
  TimePicker,
  DatePicker,
  Flex,
} from "antd";
const { Option } = Select;
const { Text } = Typography;

const DashboardListJadwalPertandingan = () => {
  const [openAddItem, setOpenAddItem] = useState(false);
  const [openEditItem, setOpenEditItem] = useState(false);
  const [data, setData] = useState([]);
  const [dataMasterSeason, setDataMasterSeason] = useState([]);
  const [dataTeam, setDataTeam] = useState([]);
  const [dataSkemaGame, setDataSkemaGame] = useState([]);
  const [dataSkema, setDataSkema] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedMasterSeason, setSelectedMasterSeason] = useState(false);
  const [selectedTeam1, setSelectedTeam1] = useState(false);
  const [getIdTeam1, setGetIdTeam1] = useState(false);
  const [loadingAddItem, setLoadingAddItem] = useState(false);
  const [loadingEditItem, setLoadingEditItem] = useState(false);
  const [loadingDeleteItem, setLoadingDeleteItem] = useState(false);
  const [sortOrder, setSortOrder] = useState({
    columnKey: null,
    order: null,
  });
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const authToken = localStorage.getItem("token");
  const [formAdd] = Form.useForm();
  const [formEdit] = Form.useForm();

  const columns = [
    {
      title: "Nama Kategori Game",
      dataIndex: "nama_kategori_game",
      key: "nama_kategori_game",
      width: "12.5%",
      sorter: (a, b) =>
        a.nama_kategori_game.localeCompare(b.nama_kategori_game),
      sortOrder:
        sortOrder.columnKey === "nama_kategori_game" && sortOrder.order,
    },
    {
      title: "Nama Sub Kategori Game",
      dataIndex: "nama_sub_kategori_game",
      key: "nama_sub_kategori_game",
      width: "12.5%",
      sorter: (a, b) =>
        a.nama_sub_kategori_game.localeCompare(b.nama_sub_kategori_game),
      sortOrder:
        sortOrder.columnKey === "nama_sub_kategori_game" && sortOrder.order,
    },
    {
      title: "Nama Season",
      dataIndex: "nama_season",
      key: "nama_season",
      width: "12.5%",
      sorter: (a, b) => a.nama_season.localeCompare(b.nama_season),
      sortOrder: sortOrder.columnKey === "nama_season" && sortOrder.order,
    },
    {
      title: "Team & Score",
      dataIndex: "teamAndScore",
      key: "teamAndScore",
      width: "15%",
      render: (_, record) => (
        <Flex vertical align="center">
          <Text>
            {record.nama_team_1} ({record.score1})
          </Text>
          <Text>vs</Text>
          <Text>
            {record.nama_team_2} ({record.score2})
          </Text>
        </Flex>
      ),
    },
    {
      title: "Nama Skema",
      dataIndex: "nama_skema",
      key: "nama_skema",
      width: "12.5%",
      sorter: (a, b) => a.nama_skema.localeCompare(b.nama_skema),
      sortOrder: sortOrder.columnKey === "nama_skema" && sortOrder.order,
    },
    {
      title: "Nama Skema Game",
      dataIndex: "nama_skema_game",
      key: "nama_skema_game",
      width: "12.5%",
      sorter: (a, b) => a.nama_skema_game.localeCompare(b.nama_skema_game),
      sortOrder: sortOrder.columnKey === "nama_skema_game" && sortOrder.order,
    },
    {
      title: "Tanggal Pertandingan",
      dataIndex: "tanggal_tanding",
      key: "tanggal_tanding",
      width: "12.5%",
      sorter: (a, b) => moment(a.tanggal_tanding) - moment(b.tanggal_tanding),
      sortOrder: sortOrder.columnKey === "tanggal_tanding" && sortOrder.order,
    },
    {
      title: "Jam Pertandingan",
      dataIndex: "jam_tanding",
      key: "jam_tanding",
      width: "12.5%",
    },
    {
      title: "Flag Live",
      dataIndex: "flag_live",
      key: "flag_live",
      width: "12.5%",
      sorter: (a, b) => a.flag_live.localeCompare(b.flag_live),
      sortOrder: sortOrder.columnKey === "flag_live" && sortOrder.order,
      render: (text) => {
        switch (text) {
          case "0":
            return "Coming Soon";
          case "1":
            return "Live";
          case "2":
            return "End Game";
          default:
            return text;
        }
      },
    },
    {
      title: "Created Date",
      dataIndex: "createdat",
      key: "createDat",
      width: "12.5%",
      sorter: (a, b) => moment(a.createdat) - moment(b.createdat),
      sortOrder: sortOrder.columnKey === "createdat" && sortOrder.order,
    },
    {
      title: "Updated Date",
      dataIndex: "updatedat",
      key: "updatedat",
      width: "12.5%",
      sorter: (a, b) => moment(a.updatedat) - moment(b.updatedat),
      sortOrder: sortOrder.columnKey === "updatedat" && sortOrder.order,
    },
    {
      title: "Actions",
      key: "actions",
      width: "12.5%",
      render: (_, record) => (
        <span style={{ display: "flex" }}>
          <Button onClick={() => showDrawerEdit(record)}>Edit</Button>
          <Popconfirm
            title="Delete Jadwal Pertandingan"
            description="Apakah yakin ingin menghapus Jadwal Pertandingan?"
            onConfirm={() => handleDelete(record)}
            okText="Hapus"
            cancelText="Tidak"
          >
            <Button style={{ marginLeft: 8 }} danger>
              Delete
            </Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  useEffect(() => {
    getListJadwalPertandingan();
    getListSkemaGame();
    getListSkema();
    getListMasterSeason();
  }, [loadData]);

  const getListJadwalPertandingan = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_JADWAL_PERTANDINGAN;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          setLoadData(false);
          setData(response.data.Data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          localStorage.removeItem("token");
          localStorage.removeItem("id_admin");
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };
  const getListSkemaGame = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_SKEMA_GAME;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          setDataSkemaGame(response.data.Data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };
  const getListSkema = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_SKEMA;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          setDataSkema(response.data.Data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };
  const getListMasterSeason = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_MASTER_SEASON;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          setDataMasterSeason(response.data.Data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };

  const addListJadwalPertandingan = (payload) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH +
      Constant.URL_POST_ADD_LISTS_JADWAL_PERTANDINGAN;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setSearchText("");
            setLoadData(true);
            setLoadingAddItem(false);
            setOpenAddItem(false);
            messageApi.open({
              type: "success",
              content: "Success Add Jadwal Pertandingan",
              duration: 4,
            });
            formAdd.resetFields();
          }, 1000);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
            setLoadingAddItem(false);
            setOpenAddItem(false);
            formAdd.resetFields();
          }, 1000);
        }
      });
  };
  const editListJadwalPertandingan = (payload) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH +
      Constant.URL_POST_EDIT_LISTS_JADWAL_PERTANDINGAN;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setSearchText("");
            setLoadData(true);
            setLoadingEditItem(false);
            setOpenEditItem(false);
            messageApi.open({
              type: "success",
              content: "Success Edit Jadwal Pertandingan",
              duration: 4,
            });
            formEdit.resetFields();
          }, 1000);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
            setLoadingEditItem(false);
            setOpenEditItem(false);
            formEdit.resetFields();
          }, 1000);
        }
      });
  };
  const deleteListJadwalPertandingan = (payload) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH +
      Constant.URL_POST_DELETE_LISTS_JADWAL_PERTANDINGAN;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setSearchText("");
            setLoadData(true);
            setLoadingDeleteItem(false);
            messageApi.open({
              type: "success",
              content: "Success Delete Jadwal Pertandingan",
              duration: 4,
            });
          }, 1000);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            setLoadingDeleteItem(false);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };
  const getListTeamByMasterSeason = (payloadId) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const payload = {
      id_master_season: payloadId,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_POST_LISTS_TEAM_BY_MASTER_SEASON;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          setDataTeam(response.data.Data);
          setSelectedMasterSeason(true);
          setLoadingAddItem(false);
          setLoadingEditItem(false);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };

  const dataSourceListJadwalPertandingan = data
    ? data.map((item, index) => {
        const createdat = moment(item.createdat).format("DD MMMM YYYY");
        const updatedat = moment(item.updatedat).format("DD MMMM YYYY");
        const tanggalPertandingan = moment(item.tanggal_tanding).format(
          "DD MMMM YYYY",
        );

        return {
          key: index,
          id_jadwal: item.id_jadwal,
          flag_live: item.flag_live,
          nama_season: item.nama_season,
          nama_skema: item.nama_skema,
          nama_skema_game: item.nama_skema_game,
          nama_kategori_game: item.nama_kategori_game,
          nama_sub_kategori_game: item.nama_sub_kategori_game,
          id_skema: item.id_skema,
          id_skema_game: item.id_skema_game,
          id_team_1: item.id_team_1,
          id_team_2: item.id_team_2,
          id_master_season: item.id_master_season,
          id_kategori_game: item.id_kategori_game,
          id_sub_kategori_game: item.id_sub_kategori_game,
          tanggal_tanding: tanggalPertandingan,
          jam_tanding: item.jam_tanding,
          nama_team_1: item.nama_team_1,
          nama_team_2: item.nama_team_2,
          score1: item.score1,
          score2: item.score2,
          createdat: createdat,
          updatedat: updatedat,
        };
      })
    : [];

  const handleDelete = (record) => {
    setLoadingDeleteItem(true);
    const valueId = {
      id_jadwal_pertandingan: record.id_jadwal,
    };
    deleteListJadwalPertandingan(valueId);
  };

  const showDrawerEdit = (record) => {
    // setSelectedRowKey(record.key);
    // getListTeamByMasterSeason(record.id_master_season);
    // setOpenEditItem(true);
    alert(record.id_master_season);
    const selectedDate = dayjs(record.tanggal_tanding);
    const selectedTime = moment(record.jam_tanding, "HH:mm");
    formEdit.setFieldsValue({
      id_jadwal_pertandingan: record.id_jadwal,
      tanggal_tanding: selectedDate,
      jam_tanding: selectedTime,
      id_master_season: record.id_master_season,
      id_skema: record.id_skema,
      id_team_1: record.id_team_1,
      id_team_2: record.id_team_2,
      flag_live: record.flag_live,
      score1: record.score1,
      score2: record.score2,
      id_skema_game: record.id_skema_game,
      id_kategori_game: record.id_kategori_game,
      id_sub_kategori_game: record.id_sub_kategori_game,
    });
  };

  const showDrawerAdd = () => {
    setOpenAddItem(true);
  };
  const onCloseAdd = () => {
    formAdd.resetFields();
    setOpenAddItem(false);
    setSelectedMasterSeason(false);
    setSelectedTeam1(false);
    setLoadData(true);
  };
  const onCloseEdit = () => {
    formEdit.resetFields();
    setOpenEditItem(false);
    setSelectedMasterSeason(false);
    setSelectedTeam1(false);
    setLoadData(true);
  };
  const onFinishAdd = () => {
    setLoadingAddItem(true);
    setSelectedMasterSeason(false);
    setSelectedTeam1(false);
    const values = formAdd.getFieldsValue();
    const selectedTime = values.jam_tanding.format("HH:mm");
    const selectedDate = values.tanggal_tanding.format("YYYY-MM-DD");
    values.jam_tanding = selectedTime;
    values.tanggal_tanding = selectedDate;
    dataTeam
      .filter((item) => item.id_team === getIdTeam1)
      .map(
        (item) => (
          (values.id_kategori_game = item.id_kategori_game),
          (values.id_sub_kategori_game = item.id_sub_kategori_game)
        ),
      );
    addListJadwalPertandingan(values);
  };
  const onFinishEdit = () => {
    setLoadingEditItem(true);
    setSelectedMasterSeason(false);
    setSelectedTeam1(false);
    const values = formEdit.getFieldsValue();
    const selectedTime = values.jam_tanding.format("HH:mm");
    const selectedDate = values.tanggal_tanding.format("YYYY-MM-DD");
    values.jam_tanding = selectedTime;
    values.tanggal_tanding = selectedDate;
    values.id_jadwal_pertandingan = data[selectedRowKey].id_jadwal;
    dataTeam
      .filter((item) => item.id_team === values.id_team_1)
      .map(
        (item) => (
          (values.id_kategori_game = item.id_kategori_game),
          (values.id_sub_kategori_game = item.id_sub_kategori_game)
        ),
      );
    editListJadwalPertandingan(values);
  };
  const handleAddMasterSeason = (value) => {
    setLoadingAddItem(true);
    getListTeamByMasterSeason(value);
    formAdd.resetFields(["id_team_1", "id_team_2", "score1", "score2"]);
  };
  const handleAddTeam = (value) => {
    setSelectedTeam1(true);
    setGetIdTeam1(value);
    formAdd.resetFields(["id_team_2", "score1", "score2"]);
  };
  const handleEditMasterSeason = (value) => {
    setLoadingEditItem(true);
    getListTeamByMasterSeason(value);
    formEdit.resetFields(["id_team_1", "id_team_2", "score1", "score2"]);
  };
  const handleEditTeam = (value) => {
    setSelectedTeam1(true);
    setGetIdTeam1(value);
    formEdit.resetFields(["id_team_2", "score1", "score2"]);
  };
  const handleSearch = (value) => {
    setSearchText(value);

    const filteredData = dataSourceListJadwalPertandingan.filter((record) => {
      return Object.values(record).some(
        (fieldValue) =>
          fieldValue &&
          fieldValue.toString().toLowerCase().includes(value.toLowerCase()),
      );
    });

    setFilteredDataSource(filteredData);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    setSortOrder({
      columnKey: sorter.columnKey,
      order: sorter.order,
    });
  };
  return (
    <Layout>
      {contextHolder}
      <Breadcrumb
        items={[
          {
            href: "/dashboard/",
            title: (
              <>
                <HomeOutlined />
                <span>Dashboard</span>
              </>
            ),
          },
          {
            title: "List Jadwal Pertandingan",
          },
        ]}
      />
      <Spin spinning={loadingDeleteItem} tip="Loading...">
        <Flex vertical>
          <Space className="dashboard-add-item">
            <Button
              className="dashboard-add-item-button"
              type="primary"
              onClick={showDrawerAdd}
              icon={<PlusOutlined />}
            >
              Tambah Jadwal Pertandingan
            </Button>
            <Drawer
              className="dashboard-add-item-drawer"
              title="Tambah Jadwal Pertandingan"
              width={720}
              closable={false}
              visible={openAddItem}
            >
              <Spin spinning={loadingAddItem} tip="Loading...">
                <Form
                  layout="vertical"
                  form={formAdd}
                  className="dashboard-add-item-drawer-form"
                  onFinish={onFinishAdd}
                >
                  <Row gutter={16}>
                    <Col xs={24} sm={16} lg={10}>
                      <Form.Item
                        name="id_master_season"
                        label="Nama Master Season"
                        rules={[
                          {
                            required: true,
                            message: "Silahkan Masukkan Master Season",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder="Pilih Master Season"
                          onChange={handleAddMasterSeason}
                        >
                          {dataMasterSeason
                            ? dataMasterSeason.map((item) => (
                                <Option
                                  key={item.id_master_season}
                                  value={item.id_master_season}
                                >
                                  {item.nama_master_season}
                                </Option>
                              ))
                            : null}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  {selectedMasterSeason ? (
                    <Row gutter={16}>
                      <Col xs={24} sm={16} lg={10}>
                        <Form.Item
                          name="id_team_1"
                          label="Nama Team 1"
                          rules={[
                            {
                              required: true,
                              message: "Silahkan Masukkan Nama Team 1",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder="Pilih Team"
                            onChange={handleAddTeam}
                          >
                            {dataTeam
                              ? dataTeam.map((item) => (
                                  <Option
                                    key={item.id_team}
                                    value={item.id_team}
                                  >
                                    {item.nama_team}
                                  </Option>
                                ))
                              : null}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  ) : null}
                  {selectedTeam1 ? (
                    <>
                      <Row gutter={16}>
                        <Col xs={24} sm={16} lg={10}>
                          <Form.Item
                            name="id_team_2"
                            label="Nama Team 2"
                            rules={[
                              {
                                required: true,
                                message: "Silahkan Masukkan Nama Team 2",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              placeholder="Pilih Team"
                            >
                              {dataTeam
                                ? dataTeam
                                    .filter(
                                      (item) => item.id_team !== getIdTeam1,
                                    )
                                    .map((item) => (
                                      <Option
                                        key={item.id_team}
                                        value={item.id_team}
                                      >
                                        {item.nama_team}
                                      </Option>
                                    ))
                                : null}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col xs={24} sm={16} lg={10}>
                          <Form.Item
                            name="score1"
                            label="Score Team 1"
                            rules={[
                              {
                                required: true,
                                message: "Silahkan Masukkan Score Team 1",
                              },
                            ]}
                          >
                            <Input placeholder="Masukan Score Team 1" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col xs={24} sm={16} lg={10}>
                          <Form.Item
                            name="score2"
                            label="Score Team 2"
                            rules={[
                              {
                                required: true,
                                message: "Silahkan Masukkan Score Team 2",
                              },
                            ]}
                          >
                            <Input placeholder="Masukan Score Team 2" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  <Row gutter={16}>
                    <Col xs={24} sm={16} lg={10}>
                      <Form.Item
                        name="id_skema"
                        label="Nama Skema"
                        rules={[
                          {
                            required: true,
                            message: "Silahkan Masukkan Skema",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder="Pilih Skema"
                        >
                          {dataSkema
                            ? dataSkema.map((item) => (
                                <Option
                                  key={item.id_skema}
                                  value={item.id_skema}
                                >
                                  {item.nama_skema}
                                </Option>
                              ))
                            : null}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={16} lg={10}>
                      <Form.Item
                        name="id_skema_game"
                        label="Nama Skema Game"
                        rules={[
                          {
                            required: true,
                            message: "Silahkan Masukkan Skema Game",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder="Pilih Skema Game"
                        >
                          {dataSkemaGame
                            ? dataSkemaGame.map((item) => (
                                <Option
                                  key={item.id_skema_game}
                                  value={item.id_skema_game}
                                >
                                  {item.nama_skema_game}
                                </Option>
                              ))
                            : null}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={16} lg={10}>
                      <Form.Item
                        name="jam_tanding"
                        label="Jam Pertandingan"
                        rules={[
                          {
                            required: true,
                            message: "Silahkan Masukkan Jam Pertandingan",
                          },
                        ]}
                      >
                        <TimePicker format="HH:mm" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={16} lg={10}>
                      <Form.Item
                        name="flag_live"
                        label="Flag Live"
                        rules={[
                          {
                            required: true,
                            message: "Silahkan Masukkan Flag Live",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder="Pilih Flag Live"
                        >
                          <Option key="0" value="0">
                            Coming Soon
                          </Option>
                          <Option key="1" value="1">
                            Live
                          </Option>
                          <Option key="2" value="2">
                            End Game
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={16} lg={10}>
                      <Form.Item
                        name="tanggal_tanding"
                        label="Tanggal Pertandingan"
                        rules={[
                          {
                            required: true,
                            message: "Silahkan Masukkan Tanggal Pertandingan",
                          },
                        ]}
                      >
                        <DatePicker format="YYYY-MM-DD" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Space className="dashboard-add-item-drawer-form-button">
                        <Button onClick={onCloseAdd}>Batal</Button>
                        <Button type="primary" htmlType="submit">
                          Tambah
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </Form>
              </Spin>
            </Drawer>
          </Space>
          <Input
            placeholder="Search"
            onChange={(e) => handleSearch(e.target.value)}
            value={searchText}
            style={{ marginBottom: "20px", width: "50%" }}
          />
        </Flex>
        <Table
          bordered={true}
          dataSource={
            searchText ? filteredDataSource : dataSourceListJadwalPertandingan
          }
          columns={columns}
          rowKey="key"
          scroll={{
            x: 1800,
          }}
          pagination={{ pageSize: 20 }}
          onChange={handleTableChange}
        />
        <Drawer
          width={720}
          title="Edit Jadwal Pertandingan"
          closable={false}
          visible={openEditItem}
        >
          <Spin spinning={loadingEditItem} tip="Loading...">
            <Form
              layout="vertical"
              form={formEdit}
              onFinish={onFinishEdit}
              className="dashboard-edit-item-drawer-form"
            >
              <Row gutter={16}>
                <Col xs={24} sm={16} lg={10}>
                  <Form.Item
                    name="id_master_season"
                    label="Nama Master Season"
                    rules={[
                      {
                        required: true,
                        message: "Silahkan Masukkan Master Season",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="Pilih Master Season"
                      // onChange={handleEditMasterSeason}
                    >
                      {dataMasterSeason
                        ? dataMasterSeason.map((item) => (
                            <Option
                              key={item.id_master_season}
                              value={item.id_master_season}
                            >
                              {item.nama_master_season}
                            </Option>
                          ))
                        : null}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Drawer>
      </Spin>
    </Layout>
  );
};

export default DashboardListJadwalPertandingan;
