import { HomeOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Flex,
  Form,
  Image,
  Input,
  Layout,
  message,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Typography,
  Upload,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Constant from "../../../config/constans";
const { Text } = Typography;
const { Option } = Select;

const DashboardListMasterRole = () => {
  const [openAddItem, setOpenAddItem] = useState(false);
  const [openEditItem, setOpenEditItem] = useState(false);
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [loadingAddItem, setLoadingAddItem] = useState(false);
  const [loadingEditItem, setLoadingEditItem] = useState(false);
  const [loadingDeleteItem, setLoadingDeleteItem] = useState(false);
  const [base64ImageEdit, setBase64ImageEdit] = useState("");
  const [base64ImageAdd, setBase64ImageAdd] = useState("");
  const [fileNameEditUploadItem, setFileNameEditUploadItem] = useState("");
  const [fileNameAddUploadItem, setFileNameAddUploadItem] = useState("");
  const [imageDimensionsAddTeam, setImageDimensionsAddTeam] = useState({
    width: 0,
    height: 0,
  });
  const [imageDimensionsEditTeam, setImageDimensionsEditTeam] = useState({
    width: 0,
    height: 0,
  });
  const [informationFile, setInformationFile] = useState(null);
  const [editImage, setEditImage] = useState("");
  const [sortOrder, setSortOrder] = useState({
    columnKey: null,
    order: null,
  });
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const authToken = localStorage.getItem("token");
  const [formAdd] = Form.useForm();
  const [formEdit] = Form.useForm();
  const MAX_FILE_SIZE = 0.6;

  const columns = [
    {
      title: "Nama Role",
      dataIndex: "nama_role",
      key: "nama_role",
      width: "20%",
      sorter: (a, b) => a.nama_role.localeCompare(b.nama_role), //tambah
      sortOrder: sortOrder.columnKey === "nama_role" && sortOrder.order, //tambah
    },
    {
      title: "Image Role",
      dataIndex: "image_role",
      key: "image_role",
      // width: "18%",
    },
    {
      title: "Created Date",
      dataIndex: "createdat",
      key: "createDat",
      width: "20%",
      sorter: (a, b) => moment(a.createdat) - moment(b.createdat), //tambah
      sortOrder: sortOrder.columnKey === "createdat" && sortOrder.order, //tambah
    },
    {
      title: "Updated Date",
      dataIndex: "udpatedat",
      key: "udpatedat",
      width: "20%",
      sorter: (a, b) => moment(a.udpatedat) - moment(b.udpatedat), //tambah
      sortOrder: sortOrder.columnKey === "udpatedat" && sortOrder.order, //tambah
    },
    {
      title: "Actions",
      key: "actions",
      width: "20%",
      render: (_, record) => (
        <span style={{ display: "flex" }}>
          <Button onClick={() => showDrawerEdit(record)}>Edit</Button>
          <Popconfirm
            title="Delete Role"
            description="Apakah yakin ingin menghapus Role?"
            onConfirm={() => handleDelete(record)}
            okText="Hapus"
            cancelText="Tidak"
          >
            <Button style={{ marginLeft: 8 }} danger>
              Delete
            </Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  useEffect(() => {
    getListTeam();
  }, [loadData]);

  const getListTeam = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_MASTER_ROLE;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          setLoadData(false);
          setData(response.data.Data);
          setBase64ImageEdit("");
          setBase64ImageAdd("");
          setFileNameAddUploadItem("");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          localStorage.removeItem("token");
          localStorage.removeItem("id_admin");
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };

  const addListTeam = (payload) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_POST_ADD_LISTS_MASTER_ROLE;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setSearchText(""); //tambah
            setLoadData(true);
            setLoadingAddItem(false);
            setOpenAddItem(false);
            messageApi.open({
              type: "success",
              content: "Success Add Team",
              duration: 4,
            });
            formAdd.resetFields();
          }, 1000);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
            setLoadingAddItem(false);
            setOpenAddItem(false);
          }, 1000);
        }
      });
  };
  const editListTeam = (payload) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_POST_EDIT_LISTS_MASTER_ROLE;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setSearchText(""); //tambah
            setLoadData(true);
            setLoadingEditItem(false);
            setOpenEditItem(false);
            messageApi.open({
              type: "success",
              content: "Success Edit Team",
              duration: 4,
            });
            formAdd.resetFields();
          }, 1000);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
            setLoadingEditItem(false);
            setOpenEditItem(false);
            formAdd.resetFields();
          }, 1000);
        }
      });
  };
  const deleteListTeam = (payload) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_POST_DELETE_LISTS_MASTER_ROLE;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setSearchText(""); //tambah
            setLoadData(true);
            setLoadingDeleteItem(false);
            messageApi.open({
              type: "success",
              content: "Success Delete Role",
              duration: 4,
            });
          }, 1000);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            setLoadingDeleteItem(false);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };

  const dataSourceListTeam = data
    ? data.map((item, index) => {
        const createdat = moment(item.createdat).format("DD MMMM YYYY");
        const updatedat = moment(item.udpatedat).format("DD MMMM YYYY");

        return {
          key: index,
          id_role: item.id_role,
          nama_role: item.nama_role,
          image_role: item.image_role,
          createdat: createdat,
          udpatedat: updatedat,
        };
      })
    : [];
  const handleDelete = (record) => {
    setLoadingDeleteItem(true);
    const valueId = {
      id_role: record.id_role,
    };
    deleteListTeam(valueId);
  };
  const showDrawerEdit = (record) => {
    setSelectedRowKey(record.key);
    setOpenEditItem(true);
    setEditImage(record.image_role);
    formAdd.setFieldsValue({
      id_role: record.id_role,
      nama_role: record.nama_role,
      image_role: record.image_role,
      createdat: record.createdat,
      udpatedat: record.updatedat,
    });
  };
  const showDrawerAdd = () => {
    setOpenAddItem(true);
  };
  const onCloseAdd = () => {
    setInformationFile("");
    setBase64ImageAdd("");
    setFileNameAddUploadItem("");
    formAdd.resetFields();
    setOpenAddItem(false);

    setLoadData(true);
  };
  const onCloseEdit = () => {
    setInformationFile("");
    setBase64ImageEdit("");
    setFileNameEditUploadItem("");
    formAdd.resetFields();
    setOpenEditItem(false);

    setLoadData(true);
  };
  const onFinishAdd = () => {
    setEditImage("");

    setLoadingAddItem(true);

    const values = formAdd.getFieldsValue();
    const regexUpload = /^data:image\/(\w+);base64,/;
    if (base64ImageAdd) {
      const matchUpload = base64ImageAdd.match(regexUpload);
      const imageWithoutPrefix = base64ImageAdd.substring(
        matchUpload[0].length,
      );
      values.image_role = imageWithoutPrefix;
    }
    setInformationFile("");
    addListTeam(values);
  };
  const onFinishEdit = () => {
    setEditImage("");
    setLoadingEditItem(true);

    const values = formAdd.getFieldsValue();
    const regexUpload = /^data:image\/(\w+);base64,/;
    if (base64ImageEdit) {
      const matchUpload = base64ImageEdit.match(regexUpload);
      const imageWithoutPrefix = base64ImageEdit.substring(
        matchUpload[0].length,
      );
      values.image_role = imageWithoutPrefix;
    } else {
      values.image_role = "-";
    }
    values.id_role = data[selectedRowKey].id_role;
    setInformationFile("");
    editListTeam(values);
  };
  const beforeAddUploadItem = (file) => {
    setInformationFile(file);
    const isLt600KB = file.size / 1024 / 1024 < MAX_FILE_SIZE;
    if (!isLt600KB) {
      message.error(`File harus lebih kecil dari 600KB!`);
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setBase64ImageAdd(reader.result);
      getImageDimensions(reader.result, setImageDimensionsAddTeam);
    };

    const fileName = file.name;
    setFileNameAddUploadItem(fileName);

    return false;
  };
  const beforeEditUploadItem = (file) => {
    console.log("cek datanya beforeEditUploadItem", file);

    setInformationFile(file);
    const isLt600KB = file.size / 1024 / 1024 < MAX_FILE_SIZE;
    if (!isLt600KB) {
      message.error(`File harus lebih kecil dari 600KB!`);
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setBase64ImageEdit(reader.result);
      getImageDimensions(reader.result, setImageDimensionsEditTeam);
    };

    const fileName = file.name;
    setFileNameEditUploadItem(fileName);

    return false;
  };

  const handleSearch = (value) => {
    setSearchText(value);

    const filteredData = dataSourceListTeam.filter((record) => {
      return Object.values(record).some(
        (fieldValue) =>
          fieldValue &&
          fieldValue.toString().toLowerCase().includes(value.toLowerCase()),
      );
    });

    setFilteredDataSource(filteredData);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    //tambah
    setSortOrder({
      columnKey: sorter.columnKey,
      order: sorter.order,
    });
  };
  const getImageDimensions = (url, setImageDimensions) => {
    const img = new window.Image();
    img.src = url;
    img.onload = () => {
      setImageDimensions({ width: img.width, height: img.height });
    };
  };
  const checkFileSize = (file) => {
    if (!file || !file.size) {
      return true;
    }
    const isLt600KB = file.size / 1024 / 1024 < MAX_FILE_SIZE;
    return isLt600KB;
  };
  const fileValidator = (_, value) => {
    console.log("cek datanya fileValidator", _, value);

    const isFileValid = checkFileSize(informationFile);
    const valuesEdit = formAdd.getFieldsValue();
    if (!value || value.length === 0) {
      return Promise.reject(new Error("Please upload a file!"));
    }
    if (!isFileValid) {
      return Promise.reject(new Error(`File harus lebih kecil dari 600KB!`));
    }
    if (valuesEdit.image_role && !isFileValid) {
      return Promise.reject(new Error(`File harus lebih kecil dari 600KB!`));
    }
    if (valuesEdit.image_role && isFileValid) {
      return Promise.resolve();
    }
    return Promise.resolve();
  };
  return (
    <Layout>
      {contextHolder}
      <Breadcrumb
        items={[
          {
            href: "/dashboard/",
            title: (
              <>
                <HomeOutlined />
                <span>Dashboard</span>
              </>
            ),
          },
          {
            title: "List Master Role",
          },
        ]}
      />
      <Spin
        spinning={openEditItem ? loadingEditItem : loadingAddItem}
        tip="Loading..."
      >
        <Form
          layout="vertical"
          form={formAdd}
          className="dashboard-add-item-drawer-form"
          onFinish={openEditItem ? onFinishEdit : onFinishAdd}
        >
          <Row gutter={16}>
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="nama_role"
                label="Nama Role"
                rules={[
                  {
                    required: true,
                    message: "Silahkan Masukkan Nama Role",
                  },
                ]}
              >
                <Input placeholder="Masukan Nama Role" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={20} sm={16} lg={8}>
              <Form.Item
                name="image_role"
                label="Upload Image Role"
                rules={[
                  {
                    validator: fileValidator,
                  },
                ]}
              >
                {console.log("cek datanya base64ImageEdit", base64ImageEdit)}
                {base64ImageEdit ? null : (
                  <Flex
                    vertical
                    className="preview-image"
                    style={{ marginBottom: 10 }}
                  >
                    <Text>Preview Image</Text>
                    <Image width={200} src={editImage} />
                  </Flex>
                )}

                <Flex vertical className="upload-images">
                  <Upload
                    customRequest={() => {}}
                    fileList={[]}
                    beforeUpload={
                      openEditItem ? beforeEditUploadItem : beforeAddUploadItem
                    }
                    onRemove={() =>
                      openEditItem
                        ? setBase64ImageEdit("")
                        : setBase64ImageAdd("")
                    }
                  >
                    <Button icon={<UploadOutlined />}>Select Image</Button>
                  </Upload>
                  <Flex vertical style={{ marginTop: 10 }}>
                    {fileNameAddUploadItem || fileNameEditUploadItem}
                    {base64ImageAdd && (
                      <Text>
                        Image Dimensions (Team): {imageDimensionsAddTeam.width}{" "}
                        x {imageDimensionsAddTeam.height}
                      </Text>
                    )}
                    {base64ImageEdit && (
                      <Text>
                        Image Dimensions (Negara):{" "}
                        {imageDimensionsEditTeam.width} x{" "}
                        {imageDimensionsEditTeam.height}
                      </Text>
                    )}
                  </Flex>
                </Flex>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Space className="dashboard-add-item-drawer-form-button">
                <Button onClick={openEditItem ? onCloseEdit : onCloseAdd}>
                  Batal
                </Button>
                <Button type="primary" htmlType="submit">
                  {openEditItem ? "Edit" : "Tambah"}
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Spin>

      <Spin spinning={loadingDeleteItem} tip="Loading...">
        <Flex vertical>
          <Space />

          <Input
            placeholder="Search"
            onChange={(e) => handleSearch(e.target.value)}
            value={searchText}
            style={{ marginBottom: "20px", width: "50%", marginTop: 30 }}
          />
        </Flex>
        <Table
          bordered={true}
          dataSource={searchText ? filteredDataSource : dataSourceListTeam}
          columns={columns}
          rowKey="key"
          scroll={{
            x: 1600,
          }}
          pagination={{ pageSize: 20 }} //tambah
          onChange={handleTableChange} //tambah
        />
      </Spin>
    </Layout>
  );
};

export default DashboardListMasterRole;
