import React, { useState, useEffect } from 'react';
import axios from "axios";
import Moment from "react-moment";
import { useLocation, useNavigate } from 'react-router-dom';
import Constant from '../../../../config/constans';

import { PlusOutlined, UploadOutlined, HomeOutlined } from '@ant-design/icons';
import { Button, Col, Breadcrumb, Form, Input, Row, Select, Space, Layout, Typography, Table, Upload, Spin, Image, message, Popconfirm, Flex, Divider } from 'antd';
const { Text, Title } = Typography;
const { Option } = Select;


const DashboardDetailArticle = () => {
  const location = useLocation();
  const [dataKomentarArticle, setDataKomentarArticle] = useState([]);
  const navigate = useNavigate();
  const authToken = localStorage.getItem('token');
  const [messageApi, contextHolder] = message.useMessage();
  const selectedRecord = location.state.record;

  const columns = [
    {
      title: 'Nama User',
      dataIndex: 'nama_user',
      key: 'nama_user',
    },
    {
      title: 'Komentar',
      dataIndex: 'komentar',
      key: 'komentar',
    },
    {
      title: 'Created Date',
      dataIndex: 'createdat',
      key: 'createdat',
      render: (text) => <Moment format="DD MMMM YYYY">{text}</Moment>,
    },
  ];

  useEffect(() => {
    getKomentarArtikel()
  }, []);

  const getKomentarArtikel = () => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const payload = 
    {
      "id_artikel": selectedRecord.id_artikel
    }
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_LISTS_KOMENTAR_ARTICLE_BY_ID;
    axios
    .post(URL, payload, { headers })
    .then((response) => {
      if (response.status === 200) {
        setDataKomentarArticle(response.data.Data)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        localStorage.removeItem('token');
        localStorage.removeItem('id_admin');
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      }
    });
  }

  const dataSourceListKomentar = dataKomentarArticle
  ? dataKomentarArticle.map((item, index) => {
    return {
      key: index,
      createdat: item.createdat,
      nama_user: item.nama_user,
      komentar: item.komentar,
    };
  })
  : null;

  return (
    <Layout>
      {contextHolder}
      <Breadcrumb
        items={[
          {
            href: '/dashboard/',
            title: (
              <>
                <HomeOutlined />
                <span>Dashboard</span>
              </>
            ),
          },
          {
            href: '/dashboard/list-article',
            title: (
              <>
                <span>List Article</span>
              </>
            ),
          },
          {
            title: "List Detail Article",
          },
        ]}
      />
      <Flex vertical>
        <Flex justify={"center"} align={"center"} style={{marginBottom:"20px"}}>
          <Title level={2} style={{margin:"0"}}>{selectedRecord.title}</Title>
        </Flex>
        <Flex vertical style={{marginBottom:"20px"}}>
          <Space>
            <Text>(Tags)</Text>
            <Text>{selectedRecord.nama_tags}</Text>
          </Space>
          <Space>
            <Text>(Kategori)</Text>
            <Text>{selectedRecord.nama_kategori_game}</Text>
          </Space>
          <Space>
            <Text>(Sub Kategori)</Text>
            <Text>{selectedRecord.nama_sub_kategori_game}</Text>
          </Space>
        </Flex>
        <Flex justify={"center"} align={"center"} vertical style={{marginBottom:"20px"}}>
          <Text>(Thumbnail)</Text>
          <Image
            width={300}
            src={selectedRecord.thumbnail}
          />
        </Flex>
        <Flex justify={"center"} align={"center"} vertical style={{marginBottom:"20px"}}>
          <Text>(Image Header)</Text>
          <Image
            width={300}
            src={selectedRecord.image_header}
          />
        </Flex>
        <Flex justify={"center"} align={"center"} style={{marginBottom:"20px"}}>
          <div style={{width:"80%"}} dangerouslySetInnerHTML={{ __html: selectedRecord.artikel }} />
        </Flex>
        <Divider />
        <Flex justify={"center"} align={"center"} >
          <Flex style={{width:"80%"}} vertical>
            <Title level={3} style={{margin:"0"}}>Komentar Artikel</Title>
            <Table
              style={{marginTop:"20px"}}
              dataSource={dataSourceListKomentar}
              columns={columns}
              rowKey="key"
              scroll={{
                x: 600,
              }}
              pagination={{ pageSize: 5 }}
            />
          </Flex>
          
        </Flex>
      </Flex>

      
    </Layout>
  )
};

export default DashboardDetailArticle;
