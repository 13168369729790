import React, { useState, useEffect } from 'react';
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Constant from "../../../config/constans";
import { PlusOutlined, UploadOutlined, HomeOutlined } from '@ant-design/icons';
import { Button, Col, Breadcrumb, Drawer, Form, Input, Row, Space, Layout, Typography, Table, Upload, Spin, Image, message, Popconfirm, Flex } from 'antd';
const { Text } = Typography;

const DashboardListNegara = () => {
  const [openAddItem, setOpenAddItem] = useState(false);
  const [openEditItem, setOpenEditItem] = useState(false);
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [loadingAddItem, setLoadingAddItem] = useState(false);
  const [loadingEditItem, setLoadingEditItem] = useState(false);
  const [loadingDeleteItem, setLoadingDeleteItem] = useState(false);
  const [base64ImageEdit, setBase64ImageEdit] = useState('');
  const [base64ImageAdd, setBase64ImageAdd] = useState('');
  const [fileNameEditUploadItem, setFileNameEditUploadItem] = useState('');
  const [fileNameAddUploadItem, setFileNameAddUploadItem] = useState('');
  const [editImage, setEditImage] = useState('');
  const [imageDimensionsAddNegara, setImageDimensionsAddNegara] = useState({ width: 0, height: 0 });
  const [imageDimensionsEditNegara, setImageDimensionsEditNegara] = useState({ width: 0, height: 0 });
  const [informationFile, setInformationFile] = useState(null)
  const [sortOrder, setSortOrder] = useState({
    columnKey: null,
    order: null,
  });
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const authToken = localStorage.getItem('token');
  const [formAdd] = Form.useForm();
  const [formEdit] = Form.useForm();
  const MAX_FILE_SIZE = 0.6;

  const columns = [
    {
      title: 'Nama Negara',
      dataIndex: 'nama_negara',
      key: 'nama_negara',
      width: "20%",
      sorter: (a, b) => a.nama_negara.localeCompare(b.nama_negara),
      sortOrder: sortOrder.columnKey === 'nama_negara' && sortOrder.order,
    },
    {
      title: 'Image',
      dataIndex: 'image_bendera',
      key: 'image_bendera',
      width: "18%",
    },
    {
      title: 'Created Date',
      dataIndex: 'createdat',
      key: 'createDat',
      width: "20%",
      sorter: (a, b) => moment(a.createDat) - moment(b.createDat),
      sortOrder: sortOrder.columnKey === 'createDat' && sortOrder.order,
    },
    {
      title: 'Updated Date',
      dataIndex: 'udpatedat',
      key: 'udpatedat',
      width: "20%",
      sorter: (a, b) => moment(a.udpatedat) - moment(b.udpatedat),
      sortOrder: sortOrder.columnKey === 'udpatedat' && sortOrder.order,
    },
    {
      title: 'Actions',
      key: 'actions',
      width: "20%",
      render: (_, record) => (
        <span style={{display:'flex'}}>
          <Button onClick={() => showDrawerEdit(record)}>Edit</Button>
          <Popconfirm
            title="Delete Neraga"
            description="Apakah yakin ingin menghapus Negara?"
            onConfirm={() => handleDelete(record)}
            okText= "Hapus"
            cancelText="Tidak"
          >
            <Button style={{ marginLeft: 8 }} danger>Delete</Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  useEffect(() => {
    getListNegara()
  }, [loadData]);

  const getListNegara = () => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_NEGARA;
    axios
    .get(URL, { headers })
    .then((response) => {
      if (response.status === 200) {
        setLoadData(false);
        setData(response.data.Data)
        setBase64ImageEdit('')
        setBase64ImageAdd('')
        setFileNameAddUploadItem('')
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        localStorage.removeItem('token');
        localStorage.removeItem('id_admin');
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      }
    });
  }
  const addListNegara = (payload) => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_ADD_LISTS_NEGARA;
    axios
    .post(URL, payload, { headers })
    .then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          setSearchText('')
          setLoadData(true)
          setLoadingAddItem(false);
          setOpenAddItem(false);
          messageApi.open({
            type: 'success',
            content: 'Success Add Negara',
            duration: 4,
          });
          formAdd.resetFields();
        },1000)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      } else {
        setTimeout(() => {
          setLoadData(true)
          messageApi.open({
            type: 'error',
            content: error.message,
            duration: 4,
          });
          setLoadingAddItem(false);
          setOpenAddItem(false);
          formAdd.resetFields();
        },1000)
      }
    });
  }
  const editListNegara = (payload) => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_EDIT_LISTS_NEGARA;
    axios
    .post(URL, payload, { headers })
    .then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          setSearchText('')
          setLoadData(true)
          setLoadingEditItem(false);
          setOpenEditItem(false);
          messageApi.open({
            type: 'success',
            content: 'Success Edit Negara',
            duration: 4,
          });
          formEdit.resetFields();
        },1000)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      } else {
        setTimeout(() => {
          setLoadData(true)
          messageApi.open({
            type: 'error',
            content: error.message,
            duration: 4,
          });
          setLoadingEditItem(false);
          setOpenEditItem(false);
          formEdit.resetFields();
        },1000)
      }
    });
  }
  const deleteListNegara = (payload) => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_DELETE_LISTS_NEGARA;
    axios
    .post(URL, payload, { headers })
    .then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          setSearchText('')
          setLoadData(true)
          setLoadingDeleteItem(false);
          messageApi.open({
            type: 'success',
            content: 'Success Delete Negara',
            duration: 4,
          });
        },1000)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      } else {
        setTimeout(() => {
          setLoadData(true)
          setLoadingDeleteItem(false);
          messageApi.open({
            type: 'error',
            content: error.message,
            duration: 4,
          });
        },1000)
      }
    });
  }

  const dataSourceListNegara = data
  ? data.map((item, index) => {
    const createdat = moment(item.createdat).format("DD MMMM YYYY")
    const updatedat = moment(item.udpatedat).format("DD MMMM YYYY")
    return {
      key: index,
      createdat: createdat,
      id_negara: item.id_negara,
      image_bendera: item.image_bendera,
      nama_negara: item.nama_negara,
      udpatedat: updatedat,
    };
  })
  : null;

  const handleDelete = (record) => {
    setLoadingDeleteItem(true)
    const valueId = {
      "id_negara": record.id_negara
    }
    deleteListNegara(valueId)
  };

  const showDrawerEdit = (record) => {
    setSelectedRowKey(record.key);
    setOpenEditItem(true);
    setEditImage(record.image_bendera)
    formEdit.setFieldsValue({
      nama_negara: record.nama_negara,
      image_bendera: record.image_bendera,
      updatedDat: record.updatedDat,
      createDat: record.createDat,
      id_negara: record.id_negara,
    });
  };

  const showDrawerAdd = () => {
    setOpenAddItem(true);
  };
  const onCloseAdd = () => {
    setInformationFile('')
    setBase64ImageAdd('')
    setFileNameAddUploadItem('')
    formAdd.resetFields();
    setOpenAddItem(false);
  };
  const onCloseEdit = () => {
    setInformationFile('')
    setBase64ImageEdit('')
    setFileNameEditUploadItem('')
    formEdit.resetFields();
    setOpenEditItem(false);
  };
  const onFinishAdd = () => {
    setLoadingAddItem(true)
    let values = formAdd.getFieldsValue();
    const regexUpload = /^data:image\/(\w+);base64,/;
    if (base64ImageAdd) {
      const matchUpload = base64ImageAdd.match(regexUpload);
      const imageWithoutPrefix = base64ImageAdd.substring(matchUpload[0].length);
      values.image_bendera = imageWithoutPrefix;
    }
    setInformationFile('')
    addListNegara(values)
  };
  const onFinishEdit = () => {
    setLoadingEditItem(true)
    const values = formEdit.getFieldsValue();
    const regexUpload = /^data:image\/(\w+);base64,/;
    if (base64ImageEdit) {
      const matchUpload = base64ImageEdit.match(regexUpload);
      const imageWithoutPrefix = base64ImageEdit.substring(matchUpload[0].length);
      values.image_bendera = imageWithoutPrefix;
    } else {
      values.image_bendera = '-'
    }
    values.id_negara = data[selectedRowKey].id_negara
    setInformationFile('')
    editListNegara(values)
  };
  const beforeAddUploadItem = (file) => {
    setInformationFile(file)
    const isLt600KB = ((file.size / 1024) / 1024) < MAX_FILE_SIZE;
    if (!isLt600KB) {
      message.error(`File harus lebih kecil dari 600KB!`);
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setBase64ImageAdd(reader.result);
      getImageDimensions(reader.result, setImageDimensionsAddNegara);
    };

    const fileName = file.name;
    setFileNameAddUploadItem(fileName);

    return false;
  };
  const beforeEditUploadItem = (file) => {
    setInformationFile(file)
    const isLt600KB = ((file.size / 1024) / 1024) < MAX_FILE_SIZE;
    if (!isLt600KB) {
      message.error(`File harus lebih kecil dari 600KB!`);
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setBase64ImageEdit(reader.result);
      getImageDimensions(reader.result, setImageDimensionsEditNegara);
    };

    const fileName = file.name;
    setFileNameEditUploadItem(fileName);

    return false;
  };
  const handleSearch = (value) => {
    setSearchText(value);
  
    const filteredData = dataSourceListNegara.filter((record) => {
      return Object.values(record).some(
        (fieldValue) =>
          fieldValue && fieldValue.toString().toLowerCase().includes(value.toLowerCase())
      );
    });
  
    setFilteredDataSource(filteredData);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    setSortOrder({
      columnKey: sorter.columnKey,
      order: sorter.order,
    });
  };
  const getImageDimensions = (url, setImageDimensions) => {
    const img = new window.Image();
    img.src = url;
    img.onload = () => {
      setImageDimensions({ width: img.width, height: img.height });
    };
  };
  const checkFileSize = (file) => {
    if (!file || !file.size) {
      return true;
    }
    const isLt600KB = ((file.size / 1024) / 1024) < MAX_FILE_SIZE;
    return isLt600KB;
  };
  const fileValidator = (_, value) => {
    const isFileValid = checkFileSize(informationFile);
    const valuesEdit = formEdit.getFieldsValue();
    if (!value || value.length === 0) {
      return Promise.reject(new Error('Please upload a file!'));
    }
    if (!isFileValid) {
      return Promise.reject(new Error(`File harus lebih kecil dari 600KB!`));
    }
    if (valuesEdit.image_bendera && !isFileValid) {
      return Promise.reject(new Error(`File harus lebih kecil dari 600KB!`));
    }
    if (valuesEdit.image_bendera && isFileValid) {
      return Promise.resolve();
    }
    return Promise.resolve();
  };
  return (
    <Layout>
      {contextHolder}
      <Breadcrumb
        items={[
          {
            href: '/dashboard/',
            title: (
              <>
                <HomeOutlined />
                <span>Dashboard</span>
              </>
            ),
          },
          {
            title: "List Negara",
          },
        ]}
      />
      <Spin spinning={loadingDeleteItem} tip="Loading...">
        <Flex vertical>
          <Space className='dashboard-add-item'>
            <Button className='dashboard-add-item-button' type="primary" onClick={showDrawerAdd} icon={<PlusOutlined />}>Tambah Negara</Button>  
            <Drawer
              className='dashboard-add-item-drawer'
              title="Tambah Negara"
              width={720}
              closable={false}
              visible={openAddItem}
            >
              <Spin spinning={loadingAddItem} tip="Loading...">
                <Form layout="vertical" form={formAdd} className='dashboard-add-item-drawer-form' onFinish={onFinishAdd}>
                  <Row gutter={16}>
                    <Col xs={24} sm={16} lg={10}>
                      <Form.Item
                        name="nama_negara"
                        label="Nama Negara"
                        rules={[
                          {
                            required: true,
                            message: 'Silahkan Masukkan Nama Negara',
                          },
                        ]}
                      >
                        <Input placeholder="Masukan Nama Negara" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={16} lg={10}>
                      <Form.Item
                        name="image_bendera"
                        label="Image"
                        rules={[
                          {
                            validator: fileValidator,
                          },
                        ]}
                      >
                        <Space className='upload-images'>
                          <Upload
                            customRequest={() => {}}
                            fileList={[]}
                            beforeUpload={beforeAddUploadItem}
                            onRemove={() => setBase64ImageAdd('')}
                          >
                            <Button icon={<UploadOutlined />}>Select Image</Button>
                          </Upload>
                          {fileNameAddUploadItem}
                          {base64ImageAdd && (
                            <Text>
                              Image Dimensions (Negara): {imageDimensionsAddNegara.width} x {imageDimensionsAddNegara.height}
                            </Text>
                          )}
                        </Space>
                      </Form.Item>
                    </Col>
                  </Row>
                  
                  <Row gutter={16}>
                    <Col span={24}>
                      <Space className='dashboard-add-item-drawer-form-button'>
                        <Button onClick={onCloseAdd}>Batal</Button>
                        <Button  type="primary" htmlType='submit'>Tambah</Button>
                      </Space>
                    </Col>
                  </Row>
                </Form>
              </Spin>
            </Drawer>
          </Space>
          <Input
            placeholder="Search"
            onChange={(e) => handleSearch(e.target.value)}
            value={searchText}
            style={{marginBottom: '20px', width: '50%'}}
          />
        </Flex>
        <Table
          bordered={true}
          dataSource={searchText ? filteredDataSource : dataSourceListNegara}
          columns={columns}
          rowKey="key"
          scroll={{
            x: 1000,
          }}
          pagination={{ pageSize: 20 }}
          onChange={handleTableChange}
        />
        <Drawer
          width={720}
          title="Edit Negara"
          closable={false}
          visible={openEditItem}
        >
          <Spin spinning={loadingEditItem} tip="Loading...">
            <Form layout="vertical" form={formEdit} onFinish={onFinishEdit} className='dashboard-edit-item-drawer-form'>
              <Row gutter={16}>
                <Col xs={24} sm={16} lg={10}>
                  <Form.Item
                    name="nama_negara"
                    label="Nama Negara"
                    rules={[
                      {
                        required: true,
                        message: 'Silahkan Masukkan Nama Negara',
                      },
                    ]}
                  >
                    <Input placeholder="Masukan Nama Negara" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={16} lg={10}>
                  <Form.Item
                    name="image_bendera"
                    label="Image"
                    rules={[
                      {
                        validator: fileValidator,
                      },
                    ]}
                  >
                    {
                      base64ImageEdit ? 
                      null : 
                        <Space className='preview-image'>
                          <Text>Preview Image</Text>
                          <Image
                            width={200}
                            src={editImage}
                          />
                        </Space>
                    }
                    <Space className='upload-images'>
                      <Upload
                        customRequest={() => {}}
                        fileList={[]}
                        beforeUpload={beforeEditUploadItem}
                        onRemove={() => setBase64ImageEdit('')}
                      >
                        <Button icon={<UploadOutlined />}>Select Image</Button>
                      </Upload>
                      {fileNameEditUploadItem}
                      {base64ImageEdit && (
                        <Text>
                          Image Dimensions (Negara): {imageDimensionsEditNegara.width} x {imageDimensionsEditNegara.height}
                        </Text>
                      )}
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Space className='dashboard-edit-item-drawer-form-button'>
                    <Button onClick={onCloseEdit}>Batal</Button>
                    <Button  type="primary" htmlType='submit'>Update</Button>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Drawer>
      </Spin>
    </Layout>
  )
};

export default DashboardListNegara;
