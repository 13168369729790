import React, { useState, useEffect } from 'react';
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Constant from "../../../config/constans";

import { PlusOutlined, HomeOutlined } from '@ant-design/icons';
import { Button, Col, Breadcrumb, Drawer, Form, Input, Row, Space, Layout, Table, Spin, message, Popconfirm, Flex } from 'antd';

const DashboardListTag = () => {
  const [openAddItem, setOpenAddItem] = useState(false);
  const [openEditItem, setOpenEditItem] = useState(false);
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [loadingAddItem, setLoadingAddItem] = useState(false);
  const [loadingEditItem, setLoadingEditItem] = useState(false);
  const [loadingDeleteItem, setLoadingDeleteItem] = useState(false);
  const [sortOrder, setSortOrder] = useState({
    columnKey: null,
    order: null,
  });
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const authToken = localStorage.getItem('token');
  const [formAdd] = Form.useForm();
  const [formEdit] = Form.useForm();

  const columns = [
    {
      title: 'Tag',
      dataIndex: 'tags',
      key: 'tags',
      width: "20%",
      sorter: (a, b) => a.tags.localeCompare(b.tags),
      sortOrder: sortOrder.columnKey === 'tags' && sortOrder.order,
    },
    {
      title: 'Created Date',
      dataIndex: 'createdat',
      key: 'createDat',
      width: "20%",
      sorter: (a, b) => moment(a.createdat) - moment(b.createdat),
      sortOrder: sortOrder.columnKey === 'createdat' && sortOrder.order,
    },
    {
      title: 'Updated Date',
      dataIndex: 'udpatedat',
      key: 'udpatedat',
      width: "20%",
      sorter: (a, b) => moment(a.udpatedat) - moment(b.udpatedat),
      sortOrder: sortOrder.columnKey === 'udpatedat' && sortOrder.order,
    },
    {
      title: 'Actions',
      key: 'actions',
      width: "20%",
      render: (_, record) => (
        <span style={{display:'flex'}}>
          <Button onClick={() => showDrawerEdit(record)}>Edit</Button>
          <Popconfirm
            title="Delete Tags"
            description="Apakah yakin ingin menghapus Tags?"
            onConfirm={() => handleDelete(record)}
            okText= "Hapus"
            cancelText="Tidak"
          >
            <Button style={{ marginLeft: 8 }} danger>Delete</Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  useEffect(() => {
    getListTag()
  }, [loadData]);

  const getListTag = () => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_TAGS;
    axios
    .get(URL, { headers })
    .then((response) => {
      if (response.status === 200) {
        setLoadData(false);
        setData(response.data.Data)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        localStorage.removeItem('token');
        localStorage.removeItem('id_admin');
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      }
    });
  }
  const addListTag = (payload) => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_ADD_LISTS_TAGS;
    axios
    .post(URL, payload, { headers })
    .then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          setSearchText('')
          setLoadData(true)
          setLoadingAddItem(false);
          setOpenAddItem(false);
          messageApi.open({
            type: 'success',
            content: 'Success Add Tags',
            duration: 4,
          });
          formAdd.resetFields();
        },1000)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      } else {
        setTimeout(() => {
          setLoadData(true)
          messageApi.open({
            type: 'error',
            content: error.message,
            duration: 4,
          });
          setLoadingAddItem(false);
          setOpenAddItem(false);
          formAdd.resetFields();
        },1000)
      }
    });
  }
  const editListTag = (payload) => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_EDIT_LISTS_TAGS;
    axios
    .post(URL, payload, { headers })
    .then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          setSearchText('')
          setLoadData(true)
          setLoadingEditItem(false);
          setOpenEditItem(false);
          messageApi.open({
            type: 'success',
            content: 'Success Edit Tags',
            duration: 4,
          });
          formEdit.resetFields();
        },1000)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      } else {
        setTimeout(() => {
          setLoadData(true)
          messageApi.open({
            type: 'error',
            content: error.message,
            duration: 4,
          });
          setLoadingEditItem(false);
          setOpenEditItem(false);
          formEdit.resetFields();
        },1000)
      }
    });
  }
  const deleteListTag = (payload) => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_DELETE_LISTS_TAGS;
    axios
    .post(URL, payload, { headers })
    .then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          setSearchText('')
          setLoadData(true)
          setLoadingDeleteItem(false);
          messageApi.open({
            type: 'success',
            content: 'Success Delete Tags',
            duration: 4,
          });
        },1000)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      } else {
        setTimeout(() => {
          setLoadData(true)
          setLoadingDeleteItem(false);
          messageApi.open({
            type: 'error',
            content: error.message,
            duration: 4,
          });
        },1000)
      }
    });
  }

  const dataSourceListTag = data
  ? data.map((item, index) => {
    const createdat = moment(item.createdat).format("DD MMMM YYYY")
    const updatedat = moment(item.udpatedat).format("DD MMMM YYYY")
    return {
      key: index,
      createdat: createdat,
      id_tags: item.id_tags,
      tags: item.tags,
      udpatedat: updatedat,
    };
  })
  : null;

  const handleDelete = (record) => {
    setLoadingDeleteItem(true)
    const valueId = {
      "id_tags": record.id_tags
    }
    deleteListTag(valueId)
  };

  const showDrawerEdit = (record) => {
    setSelectedRowKey(record.key);
    setOpenEditItem(true);
    formEdit.setFieldsValue({
      tags: record.tags,
      updatedDat: record.updatedDat,
      createDat: record.createDat,
      id_tags: record.id_tags,
    });
  };

  const showDrawerAdd = () => {
    setOpenAddItem(true);
  };
  const onCloseAdd = () => {
    setOpenAddItem(false);
    formAdd.resetFields();
  };
  const onCloseEdit = () => {
    setOpenEditItem(false);
    formEdit.resetFields();
  };
  const onFinishAdd = () => {
    setLoadingAddItem(true)
    const values = formAdd.getFieldsValue();

    addListTag(values)
  };
  const onFinishEdit = () => {
    setLoadingEditItem(true)
    const values = formEdit.getFieldsValue();
    values.id_tags = data[selectedRowKey].id_tags

    editListTag(values)
  };
  const handleSearch = (value) => {
    setSearchText(value);
  
    const filteredData = dataSourceListTag.filter((record) => {
      return Object.values(record).some(
        (fieldValue) =>
          fieldValue && fieldValue.toString().toLowerCase().includes(value.toLowerCase())
      );
    });
  
    setFilteredDataSource(filteredData);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    setSortOrder({
      columnKey: sorter.columnKey,
      order: sorter.order,
    });
  };
  return (
    <Layout>
      {contextHolder}
      <Breadcrumb
        items={[
          {
            href: '/dashboard/',
            title: (
              <>
                <HomeOutlined />
                <span>Dashboard</span>
              </>
            ),
          },
          {
            title: "List Tags",
          },
        ]}
      />
      <Spin spinning={loadingDeleteItem} tip="Loading...">
        <Flex vertical>
          <Space className='dashboard-add-item'>
            <Button className='dashboard-add-item-button' type="primary" onClick={showDrawerAdd} icon={<PlusOutlined />}>Tambah Tag</Button>  
            <Drawer
              className='dashboard-add-item-drawer'
              title="Tambah Tag"
              width={720}
              closable={false}
              visible={openAddItem}
            >
              <Spin spinning={loadingAddItem} tip="Loading...">
                <Form layout="vertical" form={formAdd} className='dashboard-add-item-drawer-form' onFinish={onFinishAdd}>
                  <Row gutter={16}>
                    <Col xs={24} sm={16} lg={10}>
                      <Form.Item
                        name="tags"
                        label="Tag"
                        rules={[
                          {
                            required: true,
                            message: 'Silahkan Masukkan Nama Tag',
                          },
                        ]}
                      >
                        <Input placeholder="Masukan Nama Tag" />
                      </Form.Item>
                    </Col>
                  </Row>
                  
                  <Row gutter={16}>
                    <Col span={24}>
                      <Space className='dashboard-add-item-drawer-form-button'>
                        <Button onClick={onCloseAdd}>Batal</Button>
                        <Button  type="primary" htmlType='submit'>Tambah</Button>
                      </Space>
                    </Col>
                  </Row>
                </Form>
              </Spin>
            </Drawer>
          </Space>
          <Input
            placeholder="Search"
            onChange={(e) => handleSearch(e.target.value)}
            value={searchText}
            style={{marginBottom: '20px', width: '50%'}}
          />
        </Flex>
        <Table
          bordered={true}
          dataSource={searchText ? filteredDataSource : dataSourceListTag}
          columns={columns}
          rowKey="key"
          scroll={{
            x: 1000,
          }}
          pagination={{ pageSize: 20 }}
          onChange={handleTableChange}
        />
        <Drawer
          width={720}
          title="Edit Tag"
          closable={false}
          visible={openEditItem}
        >
          <Spin spinning={loadingEditItem} tip="Loading...">
            <Form layout="vertical" form={formEdit} onFinish={onFinishEdit} className='dashboard-edit-item-drawer-form'>
              <Row gutter={16}>
                <Col xs={24} sm={16} lg={10}>
                  <Form.Item
                    name="tags"
                    label="Tag"
                    rules={[
                      {
                        required: true,
                        message: 'Silahkan Masukkan Nama Tag',
                      },
                    ]}
                  >
                    <Input placeholder="Masukan Nama Tag" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Space className='dashboard-edit-item-drawer-form-button'>
                    <Button onClick={onCloseEdit}>Batal</Button>
                    <Button  type="primary" htmlType='submit'>Update</Button>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Drawer>
      </Spin>
    </Layout>
  )
};

export default DashboardListTag;
