import React, { useState } from "react";
import { Table, Button, Drawer, Form, Input, Select } from "antd";
const { Option } = Select;

const TableDrawer = () => {
  const [data, setData] = useState([
    {
      key: "1",
      name: "test",
      age: "12",
    },
    {
      key: "2",
      name: "tesst",
      age: "12",
    },
  ]);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const [form] = Form.useForm(); // Create a form instance

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <span>
          <Button onClick={() => handleEdit(record)}>Edit</Button>
          <Button
            onClick={() => handleDelete(record)}
            style={{ marginLeft: 8 }}
            danger
          >
            Delete
          </Button>
        </span>
      ),
    },
  ];

  const handleDelete = (record) => {
    const updatedData = data.filter((item) => item.key !== record.key);
    setData(updatedData);
  };

  const handleEdit = (record) => {
    setSelectedRowKey(record.key);
    setIsDrawerVisible(true);

    // Set form field values based on the selected row
    form.setFieldsValue({
      name: record.name,
      age: record.age,
    });
  };

  const handleDrawerClose = () => {
    setSelectedRowKey(null);
    setIsDrawerVisible(false);
  };

  const handleSave = () => {
    // Get the form field values
    const values = form.getFieldsValue();
    console.log(values);
    // Update the data with the edited values
    // const updatedData = data.map((item) => {
    //   if (item.key === selectedRowKey) {
    //     return { ...item, ...values };
    //   }
    //   return item;
    // });

    // setData(updatedData);
    // handleDrawerClose();
  };

  return (
    <div>
      <Table dataSource={data} columns={columns} rowKey="key" />
      <Drawer
        title="Edit Record"
        width={400}
        onClose={handleDrawerClose}
        visible={isDrawerVisible}
      >
        <Form form={form} onFinish={handleSave}>
          <Form.Item label="Name" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Age" name="age">
            <Select placeholder="Select province">
              <Option value="3cf30762-0bf6-4257-a0d3-b78e1cd1da31">
                Zhejiang
              </Option>
              <Option value="833d3e92-f871-402b-9e96-2ab68aec418d">
                Jiangsu
              </Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

export default TableDrawer;
