import React from "react";
import { Result } from "antd";

const WelcomingDashboard = () => (
  <Result
    title="Welcome to the Quest Score Dashboard, Nice Day"
    icon={null}
  />
);
export default WelcomingDashboard;
