import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Constant from "../../../config/constans";
import { PlusOutlined, UploadOutlined, HomeOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Breadcrumb,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Layout,
  Typography,
  Table,
  Upload,
  Spin,
  Image,
  message,
  Popconfirm,
  Flex,
  DatePicker,
  Modal,
} from "antd";
import SunEditor from "suneditor-react";
import dayjs from "dayjs";
import { uniq } from "lodash";
const { Text } = Typography;
const { Option } = Select;

const DashboardReportScore = () => {
  const [openAddItem, setOpenAddItem] = useState(false);
  const [openEditItem, setOpenEditItem] = useState(false);
  const [toggleJumlahPoint, setToggleJumlahPoint] = useState(false);

  const [data, setData] = useState([]);
  const [dataListBracketChallonge, setDataListBracketChallonge] = useState([]);
  const [dataKategoriGame, setDataKategoriGame] = useState([]);
  const [dataSubKategoriGame, setDataSubKategoriGame] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [dataListTeam, setdataListTeam] = useState(false);
  const [dataListTeamA, setdataListTeamA] = useState(false);
  const [dataListTeamB, setdataListTeamB] = useState(false);
  const [dataListPlayer, setdataListPlayer] = useState(false);
  const [loadDataBracket, setLoadDataBracket] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedKategoriGame, setSelectedKategoriGame] = useState(false);
  const [loadingAddItem, setLoadingAddItem] = useState(false);
  const [loadingEditItem, setLoadingEditItem] = useState(false);
  const [loadingDeleteItem, setLoadingDeleteItem] = useState(false);
  const [base64ImageEdit, setBase64ImageEdit] = useState("");
  const [base64ImageAdd, setBase64ImageAdd] = useState("");
  const [fileNameEditUploadItem, setFileNameEditUploadItem] = useState("");
  const [fileNameAddUploadItem, setFileNameAddUploadItem] = useState("");
  const [isSearch, setIsSearch] = useState(false);

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const [dataListPlayerA, setDataListPlayerA] = useState([]);
  const [dataListPlayerB, setDataListPlayerB] = useState([]);
  const [dataListPlayerWinner, setDataListPlayerWinner] = useState([]);
  const [dataListPlayerAModal, setDataListPlayerAModal] = useState([]);
  const [dataListPlayerBModal, setDataListPlayerBModal] = useState([]);
  const [dataScorePlayerReqA, setDataScorePlayerAReq] = useState([]);
  const [dataScorePlayerReqB, setDataScorePlayerBReq] = useState([]);
  const [dataScorePlayerReqAModal, setDataScorePlayerReqAModal] = useState([]);
  const [dataScorePlayerReqBModal, setDataScorePlayerReqBModal] = useState([]);

  const [selectTeamA, setSelectTeamA] = useState({});
  const [selectTeamB, setSelectTeamB] = useState({});
  const [selectTeamWinner, setSelectTeamWinner] = useState({});
  const [selectParticipantTeamA, setSelectParticipantTeamA] = useState({});
  const [selectParticipantTeamB, setSelectParticipantTeamB] = useState({});
  const [selectCurrentMatch, setSelectCurrentMatch] = useState("");
  const [selectTournament, setSelectTournament] = useState({});
  const [selectBracketTournament, setSelectBracketTournament] = useState({});

  const [urlTournamentChallonge, seturlTournamentChallonge] = useState("");
  const [dataDetailTeam, setdataDetailTeam] = useState({});
  const handleOnChangeJumlahKill = (team, item, index, nama, value) => {
    console.log("handleOnChangeJumlahKill", selectTeamA, selectTeamB);
    if (team == "A") {
      dataScorePlayerReqA[index] = {
        ...dataScorePlayerReqA[index],
        id_user: item?.id_user,
        id_team: team == "A" ? selectTeamA?.value : selectTeamB?.value,
        [nama]: value,
      };
    } else {
      dataScorePlayerReqB[index] = {
        ...dataScorePlayerReqB[index],
        id_user: item?.id_user,
        id_team: team == "A" ? selectTeamA?.value : selectTeamB?.value,
        [nama]: value,
      };
    }
  };

  const handleOnChangeJumlahKillModal = (team, item, index, nama, value) => {
    console.log("itemmmm", item, showModal);
    if (team == "A") {
      dataScorePlayerReqAModal[index] = {
        ...item,
        ...dataScorePlayerReqAModal[index],
        id_score_kill: item?.id_score_kill,
        id_user: item?.id_user,
        id_team: item?.id_team,
        [nama]: value,
      };
    } else {
      dataScorePlayerReqBModal[index] = {
        ...item,
        ...dataScorePlayerReqBModal[index],
        id_score_kill: item?.id_score_kill,
        id_user: item?.id_user,
        id_team: item?.id_team,
        [nama]: value,
      };
    }
  };

  const handleVluee = () => {
    let tmp = [];
    dataListPlayerA?.map((item) => {
      tmp.push({
        [`player_${item?.id_user}`]: item?.nama,
      });
    });
    console.log("asdfasdfs", { ...tmp });
    formAdd.setFieldsValue({ ...tmp });
  };

  const showModal = (data) => {
    console.log("showModal", data);
    setOpen(true);
    setDataModal(data);
    setToggleJumlahPoint(false);
    getTeamPlayer(data);
  };

  const handleOk = () => {
    if (!dataModal?.is_approved !== "Approved") {
      setConfirmLoading(true);

      handleApprove(true);
    } else {
      setOpen(false);
    }
  };

  const handleCancel = (e) => {
    console.log("Clicked cancel button", e);
    handleApprove(false);
  };
  const [imageDimensionsAddTeam, setImageDimensionsAddTeam] = useState({
    width: 0,
    height: 0,
  });
  const [imageDimensionsEditTeam, setImageDimensionsEditTeam] = useState({
    width: 0,
    height: 0,
  });
  const [informationFile, setInformationFile] = useState(null);
  const [editImage, setEditImage] = useState("");
  const [sortOrder, setSortOrder] = useState({
    columnKey: null,
    order: null,
  });
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const authToken = localStorage.getItem("token");
  const [formAdd] = Form.useForm();
  const [formEdit] = Form.useForm();
  const MAX_FILE_SIZE = 0.6;

  const filterBracketFilter = (record) => {
    let data =
      loadDataBracket &&
      loadDataBracket?.filter((e) => {
        return record?.id_score == e?.id_score;
      });
    return <>{data[0]?.nama_bracket}</>;
  };
  const filterStatusFilter = (record) => {
    let data = statusTournaments?.filter((e) => {
      return record.status == e.value;
    });
    return <>{data[0]?.label}</>;
  };

  const statusTournaments = [
    {
      label: "Active Dan Daftar",
      value: "1",
    },
    {
      label: "Active Dan Full",
      value: "2",
    },
    {
      label: "Inactive",
      value: "3",
    },
  ];

  const columns = [
    {
      title: "Nama Tournament ",
      dataIndex: "name_tournament",
      key: "name_tournament",
      width: "20%",
      sorter: (a, b) => a.name_tournament.localeCompare(b.name_tournament), //tambah
      sortOrder: sortOrder.columnKey === "name_tournament" && sortOrder.order, //tambah
    },
    {
      title: "Nama MVP ",
      dataIndex: "name_mvp",
      key: "name_mvp",
      width: "20%",
      sorter: (a, b) => a.name_mvp.localeCompare(b.name_mvp), //tambah
      sortOrder: sortOrder.columnKey === "name_mvp" && sortOrder.order, //tambah
    },
    {
      title: "Nama Winner ",
      dataIndex: "name_winner",
      key: "name_winner",
      width: "20%",
      sorter: (a, b) => a.name_winner.localeCompare(b.name_winner), //tambah
      sortOrder: sortOrder.columnKey === "name_winner" && sortOrder.order, //tambah
    },
    {
      title: "Nama Team A",
      dataIndex: "name_team_a",
      key: "name_team_a",
      width: "20%",
      sorter: (a, b) => a.name_team_a.localeCompare(b.name_team_a), //tambah
      sortOrder: sortOrder.columnKey === "name_team_a" && sortOrder.order, //tambah
    },
    {
      title: "Score Team A",
      dataIndex: "score_team_a",
      key: "score_team_a",
      width: "20%",
      sorter: (a, b) => a.score_team_a.localeCompare(b.score_team_a), //tambah
      sortOrder: sortOrder.columnKey === "score_team_a" && sortOrder.order, //tambah
    },
    {
      title: "Nama Team B",
      dataIndex: "name_team_b",
      key: "name_team_b",
      width: "20%",
      sorter: (a, b) => a.name_team_b.localeCompare(b.name_team_b), //tambah
      sortOrder: sortOrder.columnKey === "name_team_b" && sortOrder.order, //tambah
    },
    {
      title: "Score Team B",
      dataIndex: "score_team_b",
      key: "score_team_b",
      width: "20%",
      sorter: (a, b) => a.score_team_b.localeCompare(b.score_team_b), //tambah
      sortOrder: sortOrder.columnKey === "score_team_b" && sortOrder.order, //tambah
    },

    {
      title: "Order Challonge",
      dataIndex: "order_challonge",
      key: "order_challonge",
      width: "20%",
      sorter: (a, b) => a.order_challonge.localeCompare(b.order_challonge), //tambah
      sortOrder: sortOrder.columnKey === "order_challonge" && sortOrder.order, //tambah
    },
    {
      title: "URL Bracket",
      dataIndex: "url_bracket_challonge",
      key: "url_bracket_challonge",
      width: "20%",
      sorter: (a, b) =>
        a.url_bracket_challonge.localeCompare(b.url_bracket_challonge), //tambah
      sortOrder:
        sortOrder.columnKey === "url_bracket_challonge" && sortOrder.order, //tambah
    },
    {
      title: "Created Date",
      dataIndex: "createdat",
      key: "createDat",
      width: "20%",
      sorter: (a, b) => moment(a.createdat) - moment(b.createdat), //tambah
      sortOrder: sortOrder.columnKey === "createdat" && sortOrder.order, //tambah
    },
    {
      title: "Updated Date",
      dataIndex: "updatedat",
      key: "updatedat",
      width: "20%",
      sorter: (a, b) => moment(a.updatedat) - moment(b.updatedat), //tambah
      sortOrder: sortOrder.columnKey === "updatedat" && sortOrder.order, //tambah
    },
    {
      title: "Actions",
      key: "actions",
      width: "20%",
      render: (_, record) => (
        <Flex gap={"small"}>
          <Button type="primary" onClick={() => showModal(record)}>
            Detail
          </Button>
          <Button onClick={() => showDrawerEdit(record)}>Edit</Button>
          {/* <Popconfirm
            title="Delete Member"
            description="Apakah yakin ingin menghapus Member?"
            onConfirm={() => handleDelete(record)}
            okText="Hapus"
            cancelText="Tidak"
          >
            <Button style={{ marginLeft: 8 }} danger>
              Delete
            </Button>
          </Popconfirm> */}
        </Flex>
      ),
    },
  ];

  const handleApprove = (is_approved) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_POST_APPROVED_TOURNAMENT;
    axios
      .post(
        URL,
        {
          id_room_tournament: dataModal?.id_room_tournament,
          is_approved: is_approved,
        },
        { headers },
      )
      .then((response) => {
        if (response.status === 200) {
          setConfirmLoading(false);
          setLoadData(!loadData);
          setTimeout(() => {
            messageApi.open({
              type: "success",
              content: is_approved ? "Approved !" : "Rejected !",
              duration: 4,
            });

            setOpen(false);

            // formAdd.resetFields();
          }, 1000);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
            setLoadingAddItem(false);
            setOpenAddItem(false);
          }, 1000);
        }
      });
  };
  const [dataListTournament, setdataListTournament] = useState([]);

  const getListTournament = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_MASTER_ROOM_TOURNAMENT;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          setdataListTournament(response.data.Data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          localStorage.removeItem("token");
          localStorage.removeItem("id_admin");
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };

  const getListMasterBracketTournament = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_GET_LIST_MASTER_BRACKET_CHALLONGE;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          setLoadData(false);
          setDataListBracketChallonge(response.data.Data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          localStorage.removeItem("token");
          localStorage.removeItem("id_admin");
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };

  useEffect(() => {
    getListTeam();
  }, [selectTournament]);

  // useEffect(() => {
  //   getListPlayer("A", value);
  //   getListPlayer("B", value);
  //   getListPlayer("C", value);

  //   handleVluee();
  // }, [selectTeamA, selectTeamB, selectTeamWinner]);

  useEffect(() => {
    getListKategoriGame();
    getListSubKategoriGame();
    getListReportScore();
    getListTournament();
    getListMasterBracketTournament();
  }, [loadData]);

  const getListReportScore = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_GET_LIST_MASTER_REPORT_SCORE;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          setLoadDataBracket(false);
          setData(response.data.Data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          localStorage.removeItem("token");
          localStorage.removeItem("id_admin");
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
        }
      });
  };

  const getListTeam = async (team) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_DETAIL_TOURNAMENT;
    await axios
      .post(
        URL,
        {
          id_tournament: selectTournament?.value,
        },
        { headers },
      )
      .then((response) => {
        if (response.status === 200) {
          console.log("ressss", response);
          seturlTournamentChallonge(
            response.data.Data[0]?.bracket_url_initial || "",
          );

          if (response.data.Data[0].id_tournament_challonge) {
            handleGetTournamentChallonge(
              response.data.Data[0]?.id_tournament_challonge,
            );
          }
          console.log("reasdfasdf]", response.data.Data[0]);
          setdataDetailTeam(response.data.Data[0]);
          let listPesertaApprove = response.data.Data[0]?.list_peserta.filter(
            (item) => item?.is_approved == "2",
          );
          console.log("dataListTeam", response.data.Data[0]?.list_peserta);
          setdataListTeam(listPesertaApprove);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          localStorage.removeItem("token");
          localStorage.removeItem("id_admin");
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };
  const getListPlayer = (teamTeam, value) => {
    console.log("selectTeamA", selectTeamA);
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_DETAIL_TEAM;
    axios
      .post(
        URL,
        {
          id_team: value,
          // teamTeam == "A"
          //   ? selectTeamA?.value
          //   : teamTeam == "B"
          //   ? selectTeamB?.value
          //   : selectTeamWinner,
        },
        { headers },
      )
      .then((response) => {
        if (response.status === 200) {
          console.log("response.status", response.data.Data[0]?.teams);
          if (teamTeam == "A") {
            setDataListPlayerA(response.data.Data[0]?.teams);
          } else if (teamTeam == "B") {
            setDataListPlayerB(response.data.Data[0]?.teams);
          } else {
            setDataListPlayerWinner(response.data.Data[0]?.teams);
          }
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          localStorage.removeItem("token");
          localStorage.removeItem("id_admin");
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };

  const getListKategoriGame = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_KATEGORI_GAME;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          setDataKategoriGame(response.data.Data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };

  const getTeamPlayer = (dataModal) => {
    let playerA = dataModal?.list_score_kill?.filter(
      (item) => item?.id_team == dataModal?.id_team_a,
    );
    let playerB = dataModal?.list_score_kill?.filter(
      (item) => item?.id_team == dataModal?.id_team_b,
    );

    console.log("playerA", playerA, dataModal, dataModal?.id_team_a);
    console.log("playerB", playerB, dataModal, dataModal?.id_team_b);

    setDataListPlayerAModal(playerA);
    setDataListPlayerBModal(playerB);
    // setDataListPlayerBModal(playerA);
    // setDataListPlayerBModal(playerB);
  };
  const getListSubKategoriGame = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_SUB_KATEGORI_GAME;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          setDataSubKategoriGame(response.data.Data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };
  const addListReportScore = (payload) => {
    setLoadingAddItem(true);
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_POST_LIST_ADD_REPORT_SCORE;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        setLoadingAddItem(false);

        if (response.status === 200) {
          setTimeout(() => {
            setSearchText(""); //tambah
            setLoadData(true);
            setLoadingAddItem(false);
            setOpenAddItem(false);
            setDataScorePlayerAReq([]);
            setDataScorePlayerBReq([]);

            setDataListPlayerA([]);
            setDataListPlayerB([]);
            setDataScorePlayerReqAModal([]);
            setDataScorePlayerReqBModal([]);
            messageApi.open({
              type: "success",
              content: "Success Add Report Team",
              duration: 4,
            });
            formAdd.resetFields();
          }, 1000);
        }
      })
      .catch((error) => {
        setLoadingAddItem(false);

        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
            setLoadingAddItem(false);
            setOpenAddItem(false);
          }, 1000);
        }
      });
  };
  const editListReportScore = (payload) => {
    setLoadingEditItem(true);
    setOpenEditItem(false);
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_POST_LIST_UPDATE_REPORT_SCORE;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setSearchText(""); //tambah
            setLoadData(true);
            setLoadingEditItem(false);

            setDataScorePlayerAReq([]);
            setDataScorePlayerBReq([]);

            setDataListPlayerA([]);
            setDataListPlayerB([]);
            setDataScorePlayerReqAModal([]);
            setDataScorePlayerReqBModal([]);
            messageApi.open({
              type: "success",
              content: "Success Edit Report Score",
              duration: 4,
            });
            formAdd.resetFields();
          }, 1000);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
            setLoadingEditItem(false);
            setOpenEditItem(false);
            formAdd.resetFields();
          }, 1000);
        }
      });
  };

  const deleteListTeam = (payload) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH +
      Constant.URL_POST_DELETE_LISTS_MASTER_ROOM_TOURNAMENT;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setSearchText(""); //tambah
            setLoadData(true);
            setLoadingDeleteItem(false);
            messageApi.open({
              type: "success",
              content: "Success Delete Tournament",
              duration: 4,
            });
          }, 1000);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            setLoadingDeleteItem(false);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };
  const getListSubKategoriGameByKategori = (payloadId) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const payload = {
      id_kategori_game: payloadId,
    };
    const URL =
      Constant.URL_MASTER_PATH +
      Constant.URL_POST_LISTS_SUB_KATEGORI_GAME_BY_KATEGORI;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          setDataSubKategoriGame(response.data.Data);
          setSelectedKategoriGame(true);
          setLoadingAddItem(false);
          setLoadingEditItem(false);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };

  const handleUpdateScoreKill = async (payload) => {
    console.log("handleUpdateScoreKill", payload);
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const URL =
      Constant.URL_MASTER_PATH +
      Constant.URL_POST_LIST_UPDATE_KILL_REPORT_SCORE;
    axios
      .post(URL, { ...payload }, { headers })
      .then((response) => {
        if (response.status === 200) {
          setLoadingDeleteItem(false);
          messageApi.open({
            type: "success",
            content: "Success Update",
            duration: 4,
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };

  const dataSourceListTeam = data
    ? data.map((item, index) => {
        const createdat = moment(item.createdat).format("DD MMMM YYYY");
        const updatedat = moment(item.udpatedat).format("DD MMMM YYYY");
        const startDate = moment(item.start_date).format("DD MMMM YYYY");
        const endDate = moment(item.end_date).format("DD MMMM YYYY");
        return {
          key: index,
          id_score: item?.id_score,
          id_tournament: item?.id_tournament,
          id_bracket_tournament: item?.id_bracket_tournament,
          id_team_a: item?.id_team_a,
          score_team_a: item?.score_team_a,
          id_team_b: item?.id_team_b,
          score_team_b: item?.score_team_b,
          mvp: item?.mvp,
          winner: item?.winner,
          order_challonge: item?.order_challonge,
          raw_json_challonge: item?.raw_json_challonge,
          score_player_req: item?.score_player_req,
          id_bracket: item?.id_bracket,
          list_score_kill: item?.list_score_kill,
          name_tournament: item?.name_tournament,
          name_team_a: item?.name_team_a,
          name_team_b: item?.name_team_b,
          name_mvp: item?.name_mvp,
          name_winner: item?.name_winner,
          url_bracket_challonge: item?.url_bracket_challonge,
          createdat: createdat,
          updatedat: updatedat,
        };
      })
    : [];
  const handleDelete = (record) => {
    setLoadingDeleteItem(true);
    const valueId = {
      id_score: record.id_score,
    };
    deleteListTeam(valueId);
  };
  const showDrawerEdit = (record) => {
    // getListSubKategoriGameByKategori(record.id_kategori_game);
    setSelectedRowKey(record.key);
    setOpenEditItem(true);
    setEditImage(record.banner);
    setSelectTournament({ value: record?.id_tournament });
    getListPlayer("A", record?.id_team_a);
    getListPlayer("B", record?.id_team_b);
    getListPlayer("C", record?.winner);
    formAdd.setFieldsValue({
      id_score: record?.id_score,
      id_tournament: record?.id_tournament,
      id_bracket_tournament: record?.id_bracket_tournament,
      id_team_a: record?.id_team_a,
      score_team_a: record?.score_team_a,
      id_team_b: record?.id_team_b,
      score_team_b: record?.score_team_b,
      mvp: record?.mvp,
      winner: record?.winner,
      order_challonge: record?.order_challonge,
      raw_json_challonge: record?.raw_json_challonge,
      score_player_req: record?.score_player_req,
      id_bracket: record?.id_bracket,
      list_score_kill: record?.list_score_kill,
      name_tournament: record?.name_tournament,
      name_team_a: record?.name_team_a,
      name_team_b: record?.name_team_b,
      name_mvp: record?.name_mvp,
      name_winner: record?.name_winner,
      url_bracket_challonge: record?.url_bracket_challonge,
      createdat: record.createdat,
      udpatedat: record.updatedat,
    });
  };
  const showDrawerAdd = () => {
    setOpenAddItem(true);
  };
  const onCloseAdd = () => {
    setInformationFile("");
    setBase64ImageAdd("");
    setFileNameAddUploadItem("");
    formAdd.resetFields();
    setOpenAddItem(false);
    setSelectedKategoriGame(false);
    setLoadData(true);
  };
  const onCloseEdit = () => {
    setInformationFile("");
    setBase64ImageEdit("");
    setFileNameEditUploadItem("");
    formAdd.resetFields();
    setOpenEditItem(false);
    setSelectedKategoriGame(false);
    setLoadData(true);
  };

  const handleChangeScoreChallonge = async () => {
    setLoadingAddItem(true);
    const headers = {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/vnd.api+json",
      Accept: "application/json",
      "Authorization-Type": "v1",
      Authorization: Constant.API_KEY_CHALLONGE,
      "Access-Control-Allow-Origin": "https://api.challonge.com",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    };

    let values = formAdd.getFieldsValue();

    let payload = {
      data: {
        type: "Match",
        attributes: {
          match: [
            {
              participant_id: selectParticipantTeamA?.id,
              score_set: values?.score_team_a,
              rank: 1,
              advancing:
                parseInt(values?.score_team_a) > parseInt(values?.score_team_b),
            },
            {
              participant_id: selectParticipantTeamB?.id,
              score_set: values?.score_team_b,
              rank: 1,
              advancing:
                parseInt(values?.score_team_b) > parseInt(values?.score_team_a),
            },
          ],
          tie: true,
        },
      },
    };
    const URL =
      Constant.URL_API_CHALLONGE +
      Constant.URL_PUT_MATCH_SCORE_CHALLONGE(
        dataDetailTeam?.id_tournament_challonge,
        selectCurrentMatch?.id,
      );

    let payloadTest = {
      host: URL,
      payload: payload,
      auth: Constant.API_KEY_CHALLONGE,
      method: "PUT",
    };

    await axios
      .post(
        Constant.URL_MASTER_PATH + Constant.URL_POST_INTERNAL_CHALLONGE,
        payloadTest,
      )
      .then((response) => {
        if (response?.status == 422) {
          setTimeout(() => {
            setLoadingAddItem(false);
            setLoadingDeleteItem(false);
            messageApi.open({
              type: "error",
              content: "Tournament telah selesai, tidak dapat diubah",
              duration: 4,
            });
          }, 1000);
          return;
        }
        if (response.data) {
          messageApi.open({
            type: "success",
            content: "Success add score tournament challonge",
            duration: 4,
          });
          setLoadingAddItem(false);
          const values = formAdd.getFieldsValue();
          let detailTournament = dataListTournament.find(
            (item) => item?.id_tournament == selectTournament?.value,
          );
          let selectBracketChallonge = dataListBracketChallonge.find(
            (item) => item?.id_tournament == selectTournament?.value,
          );
          values.id_bracket = detailTournament?.id_bracket;
          values.order_challonge = `${values?.order_challonge}`;

          values.id_bracket_tournament =
            selectBracketChallonge?.id_bracket_tournament;
          values.score_player_req = [
            ...dataScorePlayerReqA,
            ...dataScorePlayerReqB,
          ];
          values.raw_json_challonge = [response.data];
          console.log("values", values);
          if (openEditItem) {
            values.id_score = data[selectedRowKey].id_score;

            editListReportScore(values);
          } else {
            addListReportScore(values);
          }
          console.log("handleChangeScoreChallonge", response?.data);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          setLoadingAddItem(false);
          setLoadingDeleteItem(false);
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
        }, 1000);
      });
  };

  const handleGetTournamentChallonge = async (id_tournament_challonge) => {
    const headers = {
      "Content-Type": "application/vnd.api+json",
      Accept: "application/json",
      "Authorization-Type": "v1",
      Authorization: Constant.API_KEY_CHALLONGE,
      "Access-Control-Allow-Origin": "https://api.challonge.com",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    };

    const URL =
      Constant.URL_API_CHALLONGE +
      Constant.URL_GET_DETAIL_TOURNAMENT_CHALLONGE(id_tournament_challonge);

    // const response = await fetch(URL, {
    //   method: "GET",
    //   headers: headers,
    // }).then((item) => item.json());

    let payloadTest = {
      host: URL,
      payload: "",
      auth: Constant.API_KEY_CHALLONGE,
      method: "GET",
    };

    await axios.post(
      Constant.URL_MASTER_PATH + Constant.URL_POST_INTERNAL_CHALLONGE,
      payloadTest,
    );

    // console.log("responseresponse", await response);
  };

  const handleEditReportScore = () => {
    console.log("dataModal", dataModal);
    if (openEditItem && selectTournament) {
      let values = formAdd?.getFieldsValue();
      handleGetParticipantChallonge(values?.id_team_a, "A");
      handleGetParticipantChallonge(values?.id_team_b, "B");
    }
  };

  useEffect(() => {
    handleEditReportScore();
  }, [selectTournament, openEditItem, dataDetailTeam, dataModal]);

  const handleGetParticipantChallonge = async (id_team, typeTeam) => {
    const headers = {
      "Content-Type": "application/vnd.api+json",
      Accept: "application/json",
      "Authorization-Type": "v1",
      Authorization: Constant.API_KEY_CHALLONGE,
      "Access-Control-Allow-Origin": "https://api.challonge.com",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    };

    const URL =
      Constant.URL_API_CHALLONGE +
      Constant.URL_GET_PARTICIPANT_CHALLONGE(
        dataDetailTeam?.id_tournament_challonge,
      );

    // const response = await fetch(URL, {
    //   method: "GET",
    //   headers: headers,
    // }).then((item) => item.json());

    let payloadTest = {
      host: URL,
      payload: "",
      auth: Constant.API_KEY_CHALLONGE,
      method: "GET",
    };

    const response = await axios.post(
      Constant.URL_MASTER_PATH + Constant.URL_POST_INTERNAL_CHALLONGE,
      payloadTest,
    );

    let data = await response?.data?.data?.data;

    console.log("handleGetParticipantChallonge123", id_team, typeTeam, data);

    if (typeTeam == "A") {
      setSelectParticipantTeamA(
        data?.find((item) => item?.attributes?.misc == id_team),
      );
      // setSelectTeamA(dataListTeam?.find((item) => item?.id_team == id_team));
    } else {
      setSelectParticipantTeamB(
        data?.find((item) => item?.attributes?.misc == id_team),
      );
      // setSelectTeamB(dataListTeam?.find((item) => item?.id_team == id_team));
    }
    handleGetMatchChallonge();
  };

  const handleGetMatchChallonge = async () => {
    const headers = {
      "Content-Type": "application/vnd.api+json",
      Accept: "application/json",
      "Authorization-Type": "v1",
      Authorization: Constant.API_KEY_CHALLONGE,
      "Access-Control-Allow-Origin": "https://api.challonge.com",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    };

    console.log("dataDetailTeam12", dataDetailTeam);
    const URL =
      Constant.URL_API_CHALLONGE +
      Constant.URL_GET_MATCH_CHALLONGE(dataDetailTeam?.id_tournament_challonge);

    // const response = await fetch(URL, {
    //   method: "GET",
    //   headers: headers,
    // }).then((item) => item.json());

    let payloadTest = {
      host: URL,
      payload: "",
      auth: Constant.API_KEY_CHALLONGE,
      method: "GET",
    };

    const response = await axios.post(
      Constant.URL_MASTER_PATH + Constant.URL_POST_INTERNAL_CHALLONGE,
      payloadTest,
    );

    let data = await response?.data?.data?.data;
    console.log("handleGetMatchChallonge", response);

    let values = formAdd.getFieldsValue();

    if (data) {
      let dataMatch = data?.find(
        (item) =>
          item?.attributes?.suggested_play_order == values?.order_challonge,
      );
      console.log("datamatch", dataMatch);

      setSelectCurrentMatch(dataMatch);
    }
  };

  const onFinishAdd = () => {
    setLoadingAddItem(true);
    setSelectedKategoriGame(false);

    handleChangeScoreChallonge();
  };
  const onFinishEdit = () => {
    setLoadingEditItem(true);
    setSelectedKategoriGame(false);

    handleChangeScoreChallonge();
  };
  const beforeAddUploadItem = (file) => {
    setInformationFile(file);
    const isLt600KB = file.size / 1024 / 1024 < MAX_FILE_SIZE;
    if (!isLt600KB) {
      message.error(`File harus lebih kecil dari 600KB!`);
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setBase64ImageAdd(reader.result);
      getImageDimensions(reader.result, setImageDimensionsAddTeam);
    };

    const fileName = file.name;
    setFileNameAddUploadItem(fileName);

    return false;
  };
  const beforeEditUploadItem = (file) => {
    setInformationFile(file);
    const isLt600KB = file.size / 1024 / 1024 < MAX_FILE_SIZE;
    if (!isLt600KB) {
      message.error(`File harus lebih kecil dari 600KB!`);
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setBase64ImageEdit(reader.result);
      getImageDimensions(reader.result, setImageDimensionsEditTeam);
    };

    const fileName = file.name;
    setFileNameEditUploadItem(fileName);

    return false;
  };
  const handleAddKategoriGameChange = (value) => {
    setLoadingAddItem(true);
    getListSubKategoriGameByKategori(value);
  };
  const handleEditKategoriGameChange = (value) => {
    setLoadingEditItem(true);
    getListSubKategoriGameByKategori(value);
    formAdd.resetFields(["id_sub_kategori_game"]);
  };
  const handleSearch = (value) => {
    setSearchText(value);
    setIsSearch(false);

    const filteredData = dataSourceListTeam.filter((record) => {
      return Object.values(record).some(
        (fieldValue) =>
          fieldValue &&
          fieldValue.toString().toLowerCase().includes(value.toLowerCase()),
      );
    });

    setFilteredDataSource(filteredData);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    //tambah
    setSortOrder({
      columnKey: sorter.columnKey,
      order: sorter.order,
    });
  };
  const getImageDimensions = (url, setImageDimensions) => {
    const img = new window.Image();
    img.src = url;
    img.onload = () => {
      setImageDimensions({ width: img.width, height: img.height });
    };
  };
  const checkFileSize = (file) => {
    if (!file || !file.size) {
      return true;
    }
    const isLt600KB = file.size / 1024 / 1024 < MAX_FILE_SIZE;
    return isLt600KB;
  };
  const fileValidator = (_, value) => {
    const isFileValid = checkFileSize(informationFile);
    const valuesEdit = formAdd.getFieldsValue();
    if (!value || value.length === 0) {
      return Promise.reject(new Error("Please upload a file!"));
    }
    if (!isFileValid) {
      return Promise.reject(new Error(`File harus lebih kecil dari 600KB!`));
    }
    if (valuesEdit.banner && !isFileValid) {
      return Promise.reject(new Error(`File harus lebih kecil dari 600KB!`));
    }
    if (valuesEdit.banner && isFileValid) {
      return Promise.resolve();
    }
    return Promise.resolve();
  };
  return (
    <Layout>
      {contextHolder}
      <Breadcrumb
        items={[
          {
            href: "/dashboard/",
            title: (
              <>
                <HomeOutlined />
                <span>Dashboard</span>
              </>
            ),
          },
          {
            title: "Report Score ",
          },
        ]}
      />
      <Spin
        spinning={openEditItem ? loadingEditItem : loadingAddItem}
        tip="Loading..."
      >
        <Form
          layout="vertical"
          form={formAdd}
          className="dashboard-add-item-drawer-form"
          onFinish={openEditItem ? onFinishEdit : onFinishAdd}
        >
          <h3>Report Score</h3>
          <Row gutter={16}>
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="id_tournament"
                label="Nama Tournament"
                rules={[
                  {
                    required: true,
                    message: "Silahkan Masukkan Nama Tournament",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                  placeholder="Pilih Nama Tournament"
                  onChange={(value, select) => {
                    setToggleJumlahPoint(true);
                    setSelectTournament(select);
                  }}
                >
                  {dataListBracketChallonge
                    ? dataListBracketChallonge?.map((item) => (
                        <Option
                          key={item.id_tournament}
                          value={item.id_tournament}
                        >
                          {item.name_tournament}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="nama_bracket_tournament"
                label="Nama Bracket Tournament"
                rules={[
                  {
                    required: false,
                    message: "Silahkan Masukkan Nama Bracket Tournament",
                  },
                ]}
              >
                <Input placeholder="Masukan Nama Bracket Tournament" />
              </Form.Item>
            </Col> */}
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="order_challonge"
                label="ID order Challonge"
                rules={[
                  {
                    required: true,
                    message: "Silahkan Masukkan Nomor Bracket",
                  },
                ]}
              >
                {/* <Input
                  placeholder="Masukkan nomor bracket"
                  type="number"
                  min={0}
                /> */}
                <Select
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                  placeholder="Pilih Nama Bracket Tournament"
                  onChange={(value, select) => {
                    setSelectBracketTournament(select);
                  }}
                >
                  {dataListTeam
                    ? dataListTeam.map((item, index) => (
                        <Option key={index + 1} value={index + 1}>
                          {index + 1}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <h3>Detail Team Score</h3>
          <Row gutter={16}>
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="id_team_a"
                label="Nama Team A"
                rules={[
                  {
                    required: true,
                    message: "Silahkan Masukkan Nama Team",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                  placeholder="Pilih Nama Team A"
                  onChange={(value, select) => {
                    console.log("ASDFA", select, value);
                    setSelectTeamA(select);
                    getListPlayer("A", value);
                    handleGetParticipantChallonge(value, "A");
                  }}
                >
                  {console.log("dataListTeam", dataListTeam)}
                  {dataListTeam
                    ? dataListTeam.map((item) => (
                        <Option key={item.id_team} value={item.id_team}>
                          {item.nama_team}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="id_team_b"
                label="Nama Team B"
                rules={[
                  {
                    required: true,
                    message: "Silahkan Masukkan Status Tournament",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Pilih Status Tournament"
                  onChange={(value, select) => {
                    console.log("ASDFA", select, value);
                    setSelectTeamB(select);
                    getListPlayer("B", value);
                    handleGetParticipantChallonge(value, "B");
                  }}
                >
                  {dataListTeam
                    ? dataListTeam.map((item) => (
                        <Option key={item.id_team} value={item.id_team}>
                          {item.nama_team}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {/* ROW 2 */}
          <Row gutter={16}>
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="score_team_a"
                label="Score Team A"
                rules={[
                  {
                    required: true,
                    message: "Silahkan Masukkan Score Team A",
                  },
                ]}
              >
                <Input placeholder="Masukan Score Team A" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="score_team_b"
                label="Score Team B"
                rules={[
                  {
                    required: true,
                    message: "Silahkan Masukkan Score Team B",
                  },
                ]}
              >
                <Input placeholder="Masukan Score Team B" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="winner"
                label="Win Game Team"
                rules={[
                  {
                    required: true,
                    message: "Silahkan Masukkan Win Game Team",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value, select) => {
                    console.log("ASDFA", select, value);
                    setSelectTeamWinner(select);
                    getListPlayer("C", value);
                  }}
                  placeholder="Pilih Status Tournament"
                >
                  {dataListTeam
                    ? dataListTeam.map((item) => (
                        <Option key={item.id_team} value={item.id_team}>
                          {item.nama_team}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
            {/* ID ORDER CHALLONGE */}
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="mvp"
                label="MVP"
                rules={[
                  {
                    required: true,
                    message: "Silahkan Masukkan MVP",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Pilih MVP"
                >
                  {dataListPlayerWinner?.map((item) => (
                    <Option key={item.id_user} value={item.id_user}>
                      {item.nama}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {!openEditItem && toggleJumlahPoint && (
            <>
              <h3>Jumlah kill</h3>
              {/* LIST PLAYER */}

              <Row gutter={24}>
                {/* TEAM AAAA */}

                <Col xs={24} sm={16} lg={8}>
                  <div
                    onClick={() => {
                      console.log(dataScorePlayerReqA, "dataScorePlayerReqA");
                    }}
                    style={{ marginBottom: 10 }}
                  >
                    Player dari Team A
                  </div>
                  {dataListPlayerA &&
                    dataListPlayerA?.map((item, index) => {
                      return (
                        <Space style={{ marginBottom: 20 }}>
                          <Input
                            style={{
                              marginRight: 10,
                              width: "100%",
                              backgroundColor: "#fff",
                              color: "#000",
                            }}
                            placeholder="name"
                            value={item?.nama}
                            disabled
                          />
                          <Input
                            style={{ width: 70 }}
                            placeholder="kill"
                            type="number"
                            min={0}
                            onChange={(event) => {
                              handleOnChangeJumlahKill(
                                "A",
                                item,
                                index,
                                "jml_kill",
                                event?.target?.value,
                              );
                            }}
                          />

                          <Input
                            style={{ width: 70 }}
                            placeholder="assist"
                            type="number"
                            min={0}
                            onChange={(event) => {
                              handleOnChangeJumlahKill(
                                "A",
                                item,
                                index,
                                "jml_assist",
                                event?.target?.value,
                              );
                            }}
                          />

                          <Input
                            style={{ width: 70 }}
                            placeholder="dead"
                            type="number"
                            min={0}
                            onChange={(event) => {
                              handleOnChangeJumlahKill(
                                "A",
                                item,
                                index,
                                "jml_death",
                                event?.target?.value,
                              );
                            }}
                          />
                        </Space>
                      );
                    })}
                </Col>

                {/* TEAM BBBB */}
                <Col xs={24} sm={16} lg={8}>
                  <div
                    onClick={() => {
                      console.log(dataScorePlayerReqA, "dataScorePlayerReqA");
                    }}
                    style={{ marginBottom: 10 }}
                  >
                    Player dari Team B
                  </div>
                  {dataListPlayerB &&
                    dataListPlayerB?.map((item, index) => {
                      return (
                        <Space style={{ marginBottom: 20 }}>
                          <Input
                            style={{
                              marginRight: 10,
                              width: "100%",
                              backgroundColor: "#fff",
                              color: "#000",
                            }}
                            placeholder="kill"
                            value={item?.nama}
                            disabled
                          />
                          <Input
                            style={{ width: 70 }}
                            placeholder="kill"
                            type="number"
                            min={0}
                            onChange={(event) => {
                              handleOnChangeJumlahKill(
                                "B",
                                item,
                                index,
                                "jml_kill",
                                event?.target?.value,
                              );
                            }}
                          />

                          <Input
                            style={{ width: 70 }}
                            placeholder="assist"
                            type="number"
                            min={0}
                            onChange={(event) => {
                              handleOnChangeJumlahKill(
                                "B",
                                item,
                                index,
                                "jml_assist",
                                event?.target?.value,
                              );
                            }}
                          />

                          <Input
                            style={{ width: 70 }}
                            placeholder="dead"
                            type="number"
                            min={0}
                            onChange={(event) => {
                              handleOnChangeJumlahKill(
                                "B",
                                item,
                                index,
                                "jml_death",
                                event?.target?.value,
                              );
                            }}
                          />
                        </Space>
                      );
                    })}
                </Col>
              </Row>
            </>
          )}
          <Row gutter={16}>
            <Col span={24}>
              <Space className="dashboard-add-item-drawer-form-button">
                <Button onClick={openEditItem ? onCloseEdit : onCloseAdd}>
                  Batal
                </Button>
                <Button type="primary" htmlType="submit">
                  {openEditItem ? "Edit" : "Simpan"}
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Spin>

      <Spin spinning={loadingDeleteItem} tip="Loading...">
        <Flex style={{ marginTop: 20 }}>
          <Col span={24}>
            <iframe
              src={`https://challonge.com/${urlTournamentChallonge}/module`}
              width="100%"
              height="500"
              frameborder="0"
              scrolling="auto"
              allowtransparency="true"
            ></iframe>
          </Col>
        </Flex>
      </Spin>
      <div style={{ marginTop: 50 }} />
      <Spin
        // spinning={openEditItem ? loadingEditItem : loadingAddItem}
        spinning={false}
        tip="Loading..."
      >
        <Flex vertical>
          <Space />

          <Input
            placeholder="Search"
            onChange={(e) => handleSearch(e.target.value)}
            value={searchText}
            style={{ marginBottom: "20px", width: "50%", marginTop: 30 }}
          />
        </Flex>
        {console.log("objdataSourceListTeamect", dataSourceListTeam)}
        <Table
          bordered={true}
          dataSource={searchText ? filteredDataSource : dataSourceListTeam}
          columns={columns}
          rowKey="key"
          scroll={{
            x: 1600,
          }}
          pagination={{ pageSize: 20 }} //tambah
          onChange={handleTableChange} //tambah
        />
      </Spin>
      <Modal
        title="Jumlah Kill"
        open={open}
        onOk={handleOk}
        okText={dataModal?.is_approved == "Approved" ? "Ok" : "Approve"}
        confirmLoading={confirmLoading}
        onCancel={() => setOpen(false)}
        cancelText={"Rejected"}
        width={"80%"}
        footer={null}
        cancelButtonProps={{
          onClick: (e) => handleCancel(),
        }}
      >
        <Row gutter={24}>
          {/* TEAM AAAA */}

          <Col>
            <div
              onClick={() => {
                console.log(dataScorePlayerReqA, "dataScorePlayerReqA");
              }}
              style={{ marginBottom: 10 }}
            >
              Player dari Team A
            </div>
            {dataListPlayerAModal &&
              dataListPlayerAModal.map((item, index) => {
                return (
                  <Space style={{ marginBottom: 20 }}>
                    <Input
                      style={{
                        marginRight: 10,
                        width: "100%",
                        backgroundColor: "#fff",
                        color: "#000",
                      }}
                      placeholder="Nama"
                      value={item?.name}
                      disabled
                    />
                    <Input
                      style={{ width: 70 }}
                      placeholder="Kill"
                      type="number"
                      min={0}
                      defaultValue={item?.jml_kill}
                      onChange={(event) => {
                        handleOnChangeJumlahKillModal(
                          "A",
                          item,
                          index,
                          "jml_kill",
                          event?.target?.value,
                        );
                      }}
                    />

                    <Input
                      style={{ width: 70 }}
                      placeholder="kill"
                      type="number"
                      min={0}
                      defaultValue={item?.jml_assist}
                      onChange={(event) => {
                        handleOnChangeJumlahKillModal(
                          "A",
                          item,
                          index,
                          "jml_assist",
                          event?.target?.value,
                        );
                      }}
                    />

                    <Input
                      style={{ width: 70 }}
                      placeholder="kill"
                      type="number"
                      min={0}
                      defaultValue={item?.jml_death}
                      onChange={(event) => {
                        handleOnChangeJumlahKillModal(
                          "A",
                          item,
                          index,
                          "jml_death",
                          event?.target?.value,
                        );
                      }}
                    />
                    <Button
                      type="primary"
                      onClick={() =>
                        handleUpdateScoreKill(dataScorePlayerReqAModal[index])
                      }
                    >
                      Save
                    </Button>
                  </Space>
                );
              })}
          </Col>

          {/* TEAM BBBB */}
          <Col>
            <div
              onClick={() => {
                console.log(dataScorePlayerReqA, "dataScorePlayerReqA");
              }}
              style={{ marginBottom: 10 }}
            >
              Player dari Team B
            </div>
            {dataListPlayerBModal &&
              dataListPlayerBModal.map((item, index) => {
                return (
                  <Space style={{ marginBottom: 20 }}>
                    <Input
                      style={{
                        marginRight: 10,
                        width: "100%",
                        backgroundColor: "#fff",
                        color: "#000",
                      }}
                      placeholder="Nama"
                      value={item?.name}
                      disabled
                    />
                    <Input
                      style={{ width: 70 }}
                      placeholder="Kill"
                      type="number"
                      min={0}
                      defaultValue={item?.jml_kill}
                      onChange={(event) => {
                        handleOnChangeJumlahKillModal(
                          "B",
                          item,
                          index,
                          "jml_kill",
                          event?.target?.value,
                        );
                      }}
                    />

                    <Input
                      style={{ width: 70 }}
                      placeholder="kill"
                      type="number"
                      min={0}
                      defaultValue={item?.jml_assist}
                      onChange={(event) => {
                        handleOnChangeJumlahKillModal(
                          "B",
                          item,
                          index,
                          "jml_assist",
                          event?.target?.value,
                        );
                      }}
                    />

                    <Input
                      style={{ width: 70 }}
                      placeholder="kill"
                      type="number"
                      min={0}
                      defaultValue={item?.jml_death}
                      onChange={(event) => {
                        handleOnChangeJumlahKillModal(
                          "B",
                          item,
                          index,
                          "jml_death",
                          event?.target?.value,
                        );
                      }}
                    />
                    <Button
                      type="primary"
                      onClick={() =>
                        handleUpdateScoreKill(dataScorePlayerReqBModal[index])
                      }
                    >
                      Save
                    </Button>
                  </Space>
                );
              })}
          </Col>
        </Row>
      </Modal>
    </Layout>
  );
};

export default DashboardReportScore;
