const Constant = {
  // URL_MASTER_PATH: 'http://104.248.150.29/',
  // URL_MASTER_PATH: 'http://backend.scorequest.cloud/',
  URL_MASTER_PATH: "http://152.42.174.2/",
  URL_API_CHALLONGE: "https://api.challonge.com/",

  // API_KEY_CHALLONGE: "O8keFBimLCZEsehXGDLNYEwvfCjxeyUg0wIuKVK6",
  API_KEY_CHALLONGE: "Mv3FvlEtxjgdFvfVxkXuNqUB6SWF6LrcyqQDRIGB",

  // User
  URL_GET_LISTS_USER: "api/v1/user/list",

  // Admin
  URL_GET_LISTS_ADMIN: "api/v1/admin/list",
  URL_POST_ADD_LISTS_ADMIN: "admin/register",
  URL_POST_EDIT_LISTS_ADMIN: "api/v1/admin/updatePassword",
  URL_POST_DELETE_LISTS_ADMIN: "api/v1/admin/delete",

  // Negara
  URL_GET_LISTS_NEGARA: "api/v1/admin/listNegara",
  URL_POST_ADD_LISTS_NEGARA: "api/v1/admin/addNegara",
  URL_POST_EDIT_LISTS_NEGARA: "api/v1/admin/updateNegara",
  URL_POST_DELETE_LISTS_NEGARA: "api/v1/admin/deleteNegara",

  // Tags
  URL_GET_LISTS_TAGS: "api/v1/admin/listTags",
  URL_POST_ADD_LISTS_TAGS: "api/v1/admin/addTags",
  URL_POST_EDIT_LISTS_TAGS: "api/v1/admin/updateTags",
  URL_POST_DELETE_LISTS_TAGS: "api/v1/admin/deleteTags",

  // Kategori Game
  URL_GET_LISTS_KATEGORI_GAME: "api/v1/admin/listKategoriGame",
  URL_POST_ADD_LISTS_KATEGORI_GAME: "api/v1/admin/addKategoriGame",
  URL_POST_EDIT_LISTS_KATEGORI_GAME: "api/v1/admin/updateKategoriGame",
  URL_POST_DELETE_LISTS_KATEGORI_GAME: "api/v1/admin/deleteKategoriGame",

  // Sub Kategori Game
  URL_GET_LISTS_SUB_KATEGORI_GAME: "api/v1/admin/listSubKategoriGame",
  URL_POST_ADD_LISTS_SUB_KATEGORI_GAME: "api/v1/admin/addSubKategoriGame",
  URL_POST_EDIT_LISTS_SUB_KATEGORI_GAME: "api/v1/admin/updateSubKategoriGame",
  URL_POST_DELETE_LISTS_SUB_KATEGORI_GAME: "api/v1/admin/deleteSubKategoriGame",
  URL_POST_LISTS_SUB_KATEGORI_GAME_BY_KATEGORI:
    "api/v1/admin/listSubKategoriGameByKategori",

  // Team
  URL_GET_LISTS_TEAM: "api/v1/admin/listTeam",
  URL_POST_ADD_LISTS_TEAM: "api/v1/admin/addTeam",
  URL_POST_EDIT_LISTS_TEAM: "api/v1/admin/updateTeam",
  URL_POST_DETAIL_TEAM: "api/v1/team/detailTeam",
  URL_POST_DELETE_LISTS_TEAM: "api/v1/admin/deleteTeam",
  URL_POST_LISTS_TEAM_BY_KATEGORI_AND_SUBKATEGORI:
    "api/v1/admin/listTeamByIDandSub",
  URL_POST_LISTS_TEAM_BY_MASTER_SEASON: "api/v1/admin/listTeamBySeason",

  // Season
  URL_GET_LISTS_SEASON: "api/v1/admin/listSeason",
  URL_POST_ADD_LISTS_SEASON: "api/v1/admin/addSeason",
  URL_POST_EDIT_LISTS_SEASON: "api/v1/admin/updateSeason",
  URL_POST_DELETE_LISTS_SEASON: "api/v1/admin/deleteSeason",

  // Video
  URL_GET_LISTS_VIDEO: "api/v1/admin/listVideo",
  URL_POST_ADD_LISTS_VIDEO: "api/v1/admin/addVideo",
  URL_POST_EDIT_LISTS_VIDEO: "api/v1/admin/updateVideo",
  URL_POST_DELETE_LISTS_VIDEO: "api/v1/admin/deleteVideo",

  // Line Up
  URL_GET_LISTS_LINE_UP: "api/v1/admin/listLineUp",
  URL_POST_ADD_LISTS_LINE_UP: "api/v1/admin/addLineUp",
  URL_POST_EDIT_LISTS_LINE_UP: "api/v1/admin/updateLineUp",
  URL_POST_DELETE_LISTS_LINE_UP: "api/v1/admin/deleteLineUp",

  // Article
  URL_GET_LISTS_ARTICLE: "artikel/list",
  URL_POST_ADD_LISTS_ARTICLE: "api/v1/artikel/add",
  URL_POST_EDIT_LISTS_ARTICLE: "api/v1/artikel/update",
  URL_POST_DELETE_LISTS_ARTICLE: "api/v1/artikel/delete",

  // Komentar
  URL_POST_LISTS_KOMENTAR_ARTICLE_BY_ID: "api/v1/komentar/listbyartikel",

  // Skema Game
  URL_GET_LISTS_SKEMA_GAME: "api/v1/skemagame/list",
  URL_POST_ADD_LISTS_SKEMA_GAME: "api/v1/skemagame/add",
  URL_POST_EDIT_LISTS_SKEMA_GAME: "api/v1/skemagame/update",
  URL_POST_DELETE_LISTS_SKEMA_GAME: "api/v1/skemagame/delete",

  // Skema
  URL_GET_LISTS_SKEMA: "api/v1/skema/list",
  URL_POST_ADD_LISTS_SKEMA: "api/v1/skema/add",
  URL_POST_DELETE_LISTS_SKEMA: "api/v1/skema/delete",

  // Jadwal Pertandingan
  URL_GET_LISTS_JADWAL_PERTANDINGAN: "api/v1/jadwal/list",
  URL_POST_ADD_LISTS_JADWAL_PERTANDINGAN: "api/v1/jadwal/add",
  URL_POST_EDIT_LISTS_JADWAL_PERTANDINGAN: "api/v1/jadwal/update",
  URL_POST_DELETE_LISTS_JADWAL_PERTANDINGAN: "api/v1/jadwal/delete",

  // Master Season
  URL_GET_LISTS_MASTER_SEASON: "api/v1/admin/master/season/list",
  URL_POST_ADD_LISTS_MASTER_SEASON: "api/v1/admin/master/season/add",
  URL_POST_DELETE_LISTS_MASTER_SEASON: "api/v1/admin/master/season/delete",

  // Master Role
  URL_GET_LISTS_MASTER_ROLE: "api/v1/admin/listRole",
  URL_POST_ADD_LISTS_MASTER_ROLE: "api/v1/admin/addRole",
  URL_POST_EDIT_LISTS_MASTER_ROLE: "api/v1/admin/updateRole",
  URL_POST_DELETE_LISTS_MASTER_ROLE: "api/v1/admin/deleteRole",

  // Master Bracket
  URL_GET_LISTS_MASTER_BRACKET: "api/v1/admin/listBracket",
  URL_POST_ADD_LISTS_MASTER_BRACKET: "api/v1/admin/addBracket",
  URL_POST_EDIT_LISTS_MASTER_BRACKET: "api/v1/admin/updateBracket",
  URL_POST_DELETE_LISTS_MASTER_BRACKET: "api/v1/admin/deleteBracket",

  // Master Max Member
  URL_GET_LISTS_MASTER_MAXMEMBER: "api/v1/admin/listMaxMember",
  URL_POST_ADD_LISTS_MASTER_MAXMEMBER: "api/v1/admin/addMaxMember",
  URL_POST_EDIT_LISTS_MASTER_MAXMEMBER: "api/v1/admin/updateMaxMember",
  URL_POST_DELETE_LISTS_MASTER_MAXMEMBER: "api/v1/admin/deleteMaxMember",

  // Master Room Tournament
  URL_GET_LISTS_MASTER_ROOM_TOURNAMENT: "api/v1/tournament/allroom",
  URL_POST_ADD_LISTS_MASTER_ROOM_TOURNAMENT: "api/v1/tournament/add",
  URL_POST_UPDATE_LISTS_CHAMPIONS_ROOM_TOURNAMENT:
    "api/v1/tournament/updatechamp",
  URL_POST_EDIT_LISTS_MASTER_ROOM_TOURNAMENT: "api/v1/tournament/update",
  URL_POST_DELETE_LISTS_MASTER_ROOM_TOURNAMENT: "api/v1/tournament/delete",
  URL_POST_LISTS_MASTER_MEMBER_TOURNAMENT: "api/v1/tournament/listPeserta",
  URL_POST_APPROVED_TOURNAMENT: "api/v1/admin/approveTeamPeserta",
  URL_DELETE_PESERTA_TOURNAMENT: "api/v1/admin/deleteTeamPeserta",
  URL_POST_DETAIL_TOURNAMENT: "api/v1/tournament/detail",

  // Master Map
  URL_GET_LIST_MASTER_MAP: "api/v1/admin/listMapsGame",
  URL_POST_LIST_DELETE_MASTER_MAP: "api/v1/admin/deleteMapsGame",
  URL_POST_LIST_ADD_MASTER_MAP: "api/v1/admin/addMapsGame",
  URL_POST_LIST_UPDATE_MASTER_MAP: "api/v1/admin/updateMapsGame",

  // Master Bracket Tournament
  URL_GET_LIST_MASTER_BRACKET_CHALLONGE: "api/v1/admin/listBracketChallonge",
  URL_POST_LIST_DELETE_MASTER_BRACKET_CHALLONGE:
    "api/v1/admin/deleteBracketChallonge",
  URL_POST_LIST_ADD_MASTER_BRACKET_CHALLONGE:
    "api/v1/admin/addBracketChallonge",
  URL_POST_LIST_UPDATE_MASTER_BRACKET_CHALLONGE:
    "api/v1/admin/updateBracketChallonge",

  // Leaderboard and Scoreboard
  URL_GET_LIST_MASTER_LEADERBOARD_TEAM: "user/listLeaderBoardTeam",
  URL_GET_LIST_MASTER_LEADERBOARD_PLAYER: "user/listLeaderBoardPlayer",
  URL_GET_LIST_MASTER_SCOREBOARD: "score/listScoreBoard",
  URL_GET_LIST_MASTER_LEADERBOARD_BRACKET: "user/listLeaderBoardBracket",
  URL_GET_LIST_MASTER_LEADERBOARD_NON_BRACKET: "user/listLeaderBoardNonBracket",

  // Report Score
  URL_GET_LIST_MASTER_REPORT_SCORE: "api/v1/list/score",
  URL_POST_LIST_ADD_REPORT_SCORE: "api/v1/report/score",
  URL_POST_LIST_UPDATE_REPORT_SCORE: "api/v1/update/score",
  URL_POST_LIST_UPDATE_KILL_REPORT_SCORE: "api/v1/update/scoreKill",

  // Report Score Non Bracket
  URL_GET_LIST_MASTER_REPORT_SCORE_NON_BRACKET: "api/v1/list/nonBracket",
  URL_POST_LIST_ADD_REPORT_SCORE_NON_BRACKET: "api/v1/report/nonBracket",
  URL_POST_LIST_UPDATE_REPORT_SCORE_NON_BRACKET: "api/v1/update/nonBracket",
  URL_POST_LIST_UPDATE_KILL_REPORT_SCORE_NON_BRACKET:
    "api/v1/update/nonBracketKill",

  // CHALLONGE TOURNAMENT
  URL_POST_TOURNAMENT_CHALLONGE: "v2.1/tournaments.json",
  URL_GET_TOURNAMENT_CHALLONGE: "v2.1/tournaments.json?page=1&per_page=300",
  URL_GET_DETAIL_TOURNAMENT_CHALLONGE: (tournament_id) =>
    `v2.1/tournaments/${tournament_id}.json`,
  URL_DELETE_LIST_TOURNAMENT_CHALLONGE: (tournament_id) =>
    `v2.1/tournaments/${tournament_id}.json`,
  URL_PUT_TOURNAMENT_STATE_CHALLONGE: (tournament_id) =>
    `v2.1/tournaments/${tournament_id}/change_state.json`,

  // CHALLONGE PARTICIPANTS
  URL_POST_PARTICIPANT_CHALLONGE: (tournament_id) =>
    `v2.1/tournaments/${tournament_id}/participants/bulk_add.json`,
  URL_GET_PARTICIPANT_CHALLONGE: (tournament_id) =>
    `v2.1/tournaments/${tournament_id}/participants.json?page=1&per_page=300`,
  URL_GET_DETAIL_PARTICIPANT_CHALLONGE: (tournament_id, participant_id) =>
    `v2.1/tournaments/${tournament_id}/participants/${participant_id}.json`,

  // CHALLONGE MATCH
  URL_GET_MATCH_CHALLONGE: (tournament_id) =>
    `v2.1/tournaments/${tournament_id}/matches.json?page=1&per_page=300`,
  URL_PUT_MATCH_SCORE_CHALLONGE: (tournament_id, match_id) =>
    `v2.1/tournaments/${tournament_id}/matches/${match_id}.json`,
  URL_PUT_MATCH_STATE_CHALLONGE: (tournament_id, match_id) =>
    `v2.1/tournaments/${tournament_id}/matches/${match_id}/change_state.json`,

  URL_POST_INTERNAL_CHALLONGE: "check/challonge",
};

export default Constant;
