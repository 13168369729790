import React, { useState, useEffect } from 'react';
import axios from "axios";
import moment from "moment";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { useNavigate } from "react-router-dom";
import Constant from "../../../config/constans";
import { Link } from 'react-router-dom';
import { PlusOutlined, UploadOutlined, HomeOutlined } from '@ant-design/icons';
import { Button, Col, Breadcrumb, Drawer, Form, Input, Row, Select, Space, Layout, Typography, Table, Upload, Spin, Image, message, Popconfirm, Flex } from 'antd';
const { Text } = Typography;
const { Option } = Select;

const DashboardListArticle = () => {
  const [openAddItem, setOpenAddItem] = useState(false);
  const [openEditItem, setOpenEditItem] = useState(false);
  const [data, setData] = useState([]);
  const [dataKategoriGame, setDataKategoriGame] = useState([]);
  const [dataSubKategoriGame, setDataSubKategoriGame] = useState([]);
  const [dataTags, setDataTags] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedKategoriGame, setSelectedKategoriGame] = useState(false);
  const [loadingAddItem, setLoadingAddItem] = useState(false);
  const [loadingEditItem, setLoadingEditItem] = useState(false);
  const [loadingDeleteItem, setLoadingDeleteItem] = useState(false);
  const [base64ImageAddThumbnail, setBase64ImageAddThumbnail] = useState('');
  const [base64ImageAddImageHeader, setBase64ImageAddImageHeader] = useState('');
  const [base64ImageEditThumbnail, setBase64ImageEditThumbnail] = useState('');
  const [base64ImageEditImageHeader, setBase64ImageEditImageHeader] = useState('');
  const [fileNameAddUploadItemThumbnail, setFileNameAddUploadItemThumbnail] = useState('');
  const [fileNameEditUploadItemThumbnail, setFileNameEditUploadItemThumbnail] = useState('');
  const [fileNameAddUploadItemImageHeader, setFileNameAddUploadItemImageHeader] = useState('');
  const [fileNameEditUploadItemImageHeader, setFileNameEditUploadItemImageHeader] = useState('');
  const [editImageThumbnail, setEditImageThumbnail] = useState('');
  const [editImageHeader, setEditImageHeader] = useState('');
  const [previousContent, setPreviousContent] = useState('');
  const [imageDimensionsAddThumbnail, setImageDimensionsAddThumbnail] = useState({ width: 0, height: 0 });
  const [imageDimensionsEditThumbnail, setImageDimensionsEditThumbnail] = useState({ width: 0, height: 0 });
  const [imageDimensionsAddHeader, setImageDimensionsAddHeader] = useState({ width: 0, height: 0 });
  const [imageDimensionsEditHeader, setImageDimensionsEditHeader] = useState({ width: 0, height: 0 });
  const [informationFileHeader, setInformationFileHeader] = useState(null)
  const [informationFileThumbnail, setInformationFileThumbnail] = useState(null)
  const [sortOrder, setSortOrder] = useState({
    columnKey: null,
    order: null,
  });
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const authToken = localStorage.getItem('token');
  const [formAdd] = Form.useForm();
  const [formEdit] = Form.useForm();
  const MAX_FILE_SIZE = 0.6;

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: "300px",
      sorter: (a, b) => a.title.localeCompare(b.title),
      sortOrder: sortOrder.columnKey === 'title' && sortOrder.order,
    },
    // {
    //   title: 'Thumbnail',
    //   dataIndex: 'thumbnail',
    //   key: 'thumbnail',
    //   width: "100px",
    // },
    // {
    //   title: 'Image Header',
    //   dataIndex: 'image_header',
    //   key: 'image_header',
    //   width: "100px",
    // },
    {
      title: 'Nama Tags',
      dataIndex: 'nama_tags',
      key: 'nama_tags',
      width: "200px",
      sorter: (a, b) => a.nama_tags.localeCompare(b.nama_tags),
      sortOrder: sortOrder.columnKey === 'nama_tags' && sortOrder.order,
    },
    {
      title: 'Nama Kategori Game',
      dataIndex: 'nama_kategori_game',
      key: 'nama_kategori_game',
      width: "200px",
      sorter: (a, b) => a.nama_kategori_game.localeCompare(b.nama_kategori_game),
      sortOrder: sortOrder.columnKey === 'nama_kategori_game' && sortOrder.order,
    },
    // {
    //   title: 'Nama Sub Kategori Game',
    //   dataIndex: 'nama_sub_kategori_game',
    //   key: 'nama_sub_kategori_game',
    //   width: "200px",
    //   sorter: (a, b) => a.nama_sub_kategori_game.localeCompare(b.nama_sub_kategori_game),
    //   sortOrder: sortOrder.columnKey === 'nama_sub_kategori_game' && sortOrder.order,
    // },
    {
      title: 'Created Date',
      dataIndex: 'createdat',
      key: 'createDat',
      width: "200px",
      sorter: (a, b) => moment(a.createdat) - moment(b.createdat),
      sortOrder: sortOrder.columnKey === 'createdat' && sortOrder.order,
    },
    {
      title: 'Updated Date',
      dataIndex: 'updatedat',
      key: 'updatedat',
      width: "200px",
      sorter: (a, b) => moment(a.updatedat) - moment(b.updatedat),
      sortOrder: sortOrder.columnKey === 'updatedat' && sortOrder.order,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <span style={{display:'flex'}}>
          <Link to="/dashboard/list-article/detail-article" state={{ record }}>
            <Button>View Article</Button>
          </Link>
          <Button style={{ marginLeft: 8 }} onClick={() => showDrawerEdit(record)}>Edit</Button>
          <Popconfirm
            title="Delete Article"
            description="Apakah yakin ingin menghapus Article?"
            onConfirm={() => handleDelete(record)}
            okText= "Hapus"
            cancelText="Tidak"
          >
            <Button style={{ marginLeft: 8 }} danger>Delete</Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  useEffect(() => {
    getListArticle()
    getListTags()
    getListKategoriGame()
    getListSubKategoriGame()
  }, [loadData]);


  const getListArticle = () => {
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_ARTICLE;
    axios
    .get(URL)
    .then((response) => {
      if (response.status === 200) {
        setLoadData(false);
        setData(response.data.Data)
        setBase64ImageEditThumbnail('')
        setBase64ImageEditImageHeader('')
        setBase64ImageAddThumbnail('')
        setBase64ImageAddImageHeader('')
        setFileNameAddUploadItemThumbnail('')
        setFileNameAddUploadItemImageHeader('')
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        localStorage.removeItem('token');
        localStorage.removeItem('id_admin');
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      }
    });
  }
  const getListTags = () => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_TAGS;
    axios
    .get(URL, { headers })
    .then((response) => {
      if (response.status === 200) {
        setDataTags(response.data.Data)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      }
    });
  }
  const getListKategoriGame = () => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_KATEGORI_GAME;
    axios
    .get(URL, { headers })
    .then((response) => {
      if (response.status === 200) {
        setDataKategoriGame(response.data.Data)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      }
    });
  }
  const getListSubKategoriGame = () => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_SUB_KATEGORI_GAME;
    axios
    .get(URL, { headers })
    .then((response) => {
      if (response.status === 200) {
        setDataSubKategoriGame(response.data.Data)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      }
    });
  }
  
  const addListArticle = (payload) => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_ADD_LISTS_ARTICLE;
    axios
    .post(URL, payload, { headers })
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        setTimeout(() => {
          setSearchText('')
          setLoadData(true)
          setLoadingAddItem(false);
          setOpenAddItem(false);
          messageApi.open({
            type: 'success',
            content: 'Success Add Article',
            duration: 4,
          });
          formAdd.resetFields();
        },1000)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      } else {
        setTimeout(() => {
          setLoadData(true)
          messageApi.open({
            type: 'error',
            content: error.message,
            duration: 4,
          });
          setLoadingAddItem(false);
          setOpenAddItem(false);
          formAdd.resetFields();
        },1000)
      }
    });
  }
  const editListArticle = (payload) => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_EDIT_LISTS_ARTICLE;
    axios
    .post(URL, payload, { headers })
    .then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          setSearchText('')
          setLoadData(true)
          setLoadingEditItem(false);
          setOpenEditItem(false);
          messageApi.open({
            type: 'success',
            content: 'Success Edit Article',
            duration: 4,
          });
          formEdit.resetFields();
        },1000)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      } else {
        setTimeout(() => {
          setLoadData(true)
          messageApi.open({
            type: 'error',
            content: error.message,
            duration: 4,
          });
          setLoadingEditItem(false);
          setOpenEditItem(false);
          formEdit.resetFields();
        },1000)
      }
    });
  }
  const deleteListArticle = (payload) => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_DELETE_LISTS_ARTICLE;
    axios
    .post(URL, payload, { headers })
    .then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          setSearchText('')
          setLoadData(true)
          setLoadingDeleteItem(false);
          messageApi.open({
            type: 'success',
            content: 'Success Delete Article',
            duration: 4,
          });
        },1000)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      } else {
        setTimeout(() => {
          setLoadData(true)
          setLoadingDeleteItem(false);
          messageApi.open({
            type: 'error',
            content: error.message,
            duration: 4,
          });
        },1000)
      }
    });
  }
  const getListSubKategoriGameByKategori = (payloadId) => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const payload = 
    {
      "id_kategori_game": payloadId
    }
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_LISTS_SUB_KATEGORI_GAME_BY_KATEGORI;
    axios
    .post(URL, payload, { headers })
    .then((response) => {
      if (response.status === 200) {
        setDataSubKategoriGame(response.data.Data)
        setSelectedKategoriGame(true);
        setLoadingAddItem(false)
        setLoadingEditItem(false)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      }
    });
  }

  const dataSourceListArticle = data
  ? data.map((item, index) => {
    const createdat = moment(item.createdat).format("DD MMMM YYYY")
    const updatedat = moment(item.updatedat).format("DD MMMM YYYY")
    return {
      key: index,
      createdat: createdat,
      updatedat: updatedat,
      id_artikel: item.id_artikel,
      artikel: item.artikel,
      title: item.title,
      nama_tags: item.nama_tags,
      thumbnail: item.thumbnail,
      image_header: item.image_header,
      nama_kategori_game: item.nama_kategori_game,
      nama_sub_kategori_game: item.nama_sub_kategori_game,
      id_kategori_game: item.id_kategori_game,
      id_sub_kategori_game: item.id_sub_kategori_game,
      id_tags: item.id_tags
    };
  })
  : null;

  const handleDelete = (record) => {
    setLoadingDeleteItem(true)
    const valueId = {
      "id_artikel": record.id_artikel
    }
    deleteListArticle(valueId)
  };

  const showDrawerEdit = (record) => {
    getListSubKategoriGameByKategori(record.id_kategori_game)
    setSelectedRowKey(record.key);
    setOpenEditItem(true);
    setEditImageThumbnail(record.thumbnail)
    setEditImageHeader(record.image_header)
    formEdit.setFieldsValue({
      id_artikel: record.id_artikel,
      artikel: record.artikel,
      title: record.title,
      id_tags: record.id_tags,
      updated_artikel_by: record.updated_artikel_by,
      thumbnail: record.thumbnail,
      image_header: record.image_header,
      id_kategori_game: record.id_kategori_game,
      id_sub_kategori_game: record.id_sub_kategori_game,
    });
    setPreviousContent(record.artikel);
  };

  const showDrawerAdd = () => {
    setOpenAddItem(true);
  };
  const onCloseAdd = () => {
    setInformationFileHeader('')
    setInformationFileThumbnail('')
    setBase64ImageAddThumbnail('')
    setBase64ImageAddImageHeader('')
    setFileNameAddUploadItemThumbnail('')
    setFileNameAddUploadItemImageHeader('')
    formAdd.resetFields();
    setOpenAddItem(false);
    setSelectedKategoriGame(false)
    setLoadData(true)
  };
  const onCloseEdit = () => {
    setInformationFileHeader('')
    setInformationFileThumbnail('')
    setBase64ImageEditThumbnail('')
    setBase64ImageEditImageHeader('')
    setFileNameEditUploadItemThumbnail('')
    setFileNameEditUploadItemImageHeader('')
    formEdit.resetFields();
    setOpenEditItem(false);
    setSelectedKategoriGame(false)
    setLoadData(true)
  };
  const onFinishAdd = () => {
    setLoadingAddItem(true)
    setSelectedKategoriGame(false)
    const values = formAdd.getFieldsValue();
    const regexUpload = /^data:image\/(\w+);base64,/;
    if (base64ImageAddThumbnail) {
      const matchUpload = base64ImageAddThumbnail.match(regexUpload);
      const imageWithoutPrefix = base64ImageAddThumbnail.substring(matchUpload[0].length);
      values.thumbnail = imageWithoutPrefix;
    }
    if (base64ImageAddImageHeader) {
      const matchUpload = base64ImageAddImageHeader.match(regexUpload);
      const imageWithoutPrefix = base64ImageAddImageHeader.substring(matchUpload[0].length);
      values.image_header = imageWithoutPrefix;
    }
    values.created_artikel_by = "954f086c-1444-40cd-8033-362cbb6c88e4"
    setInformationFileHeader('')
    setInformationFileThumbnail('')
    addListArticle(values)
  };
  const onFinishEdit = () => {
    setLoadingEditItem(true)
    setSelectedKategoriGame(false)
    const values = formEdit.getFieldsValue();
    const regexUpload = /^data:image\/(\w+);base64,/;

    if (base64ImageEditThumbnail) {
      const matchUpload = base64ImageEditThumbnail.match(regexUpload);
      const imageWithoutPrefix = base64ImageEditThumbnail.substring(matchUpload[0].length);
      values.thumbnail = imageWithoutPrefix;
    } else {
      values.thumbnail = '-'
    }
    if (base64ImageEditImageHeader) {
      const matchUpload = base64ImageEditImageHeader.match(regexUpload);
      const imageWithoutPrefix = base64ImageEditImageHeader.substring(matchUpload[0].length);
      values.image_header = imageWithoutPrefix;
    } else {
      values.image_header = '-'
    }
    values.id_artikel = data[selectedRowKey].id_artikel
    values.updated_artikel_by = "954f086c-1444-40cd-8033-362cbb6c88e4"
    setInformationFileHeader('')
    setInformationFileThumbnail('')
    editListArticle(values)
  };

  const beforeAddUploadItemThumbnail = (file) => {
    setInformationFileThumbnail(file)
    const isLt600KB = ((file.size / 1024) / 1024) < MAX_FILE_SIZE;
    if (!isLt600KB) {
      message.error(`File harus lebih kecil dari 600KB!`);
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setBase64ImageAddThumbnail(reader.result);
      getImageDimensions(reader.result, setImageDimensionsAddThumbnail);
    };

    const fileName = file.name;
    setFileNameAddUploadItemThumbnail(fileName);

    return false;
  };
  const beforeAddUploadItemImageHeader = (file) => {
    setInformationFileHeader(file)
    const isLt600KB = ((file.size / 1024) / 1024) < MAX_FILE_SIZE;
    if (!isLt600KB) {
      message.error(`File harus lebih kecil dari 600KB!`);
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setBase64ImageAddImageHeader(reader.result);
      getImageDimensions(reader.result, setImageDimensionsAddHeader);
    };

    const fileName = file.name;
    setFileNameAddUploadItemImageHeader(fileName);

    return false;
  };
  const beforeEditUploadItemThumbnail = (file) => {
    setInformationFileThumbnail(file)
    const isLt600KB = ((file.size / 1024) / 1024) < MAX_FILE_SIZE;
    if (!isLt600KB) {
      message.error(`File harus lebih kecil dari 600KB!`);
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setBase64ImageEditThumbnail(reader.result);
      getImageDimensions(reader.result, setImageDimensionsEditThumbnail);
    };

    const fileName = file.name;
    setFileNameEditUploadItemThumbnail(fileName);

    return false;
  };
  const beforeEditUploadItemImageHeader = (file) => {
    setInformationFileHeader(file)
    const isLt600KB = ((file.size / 1024) / 1024) < MAX_FILE_SIZE;
    if (!isLt600KB) {
      message.error(`File harus lebih kecil dari 600KB!`);
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setBase64ImageEditImageHeader(reader.result);
      getImageDimensions(reader.result, setImageDimensionsEditHeader);
    };

    const fileName = file.name;
    setFileNameEditUploadItemImageHeader(fileName);

    return false;
  };
  const handleAddKategoriGameChange = (value) => {
    setLoadingAddItem(true)
    getListSubKategoriGameByKategori(value)
  };
  const handleEditKategoriGameChange = (value) => {
    setLoadingEditItem(true)
    getListSubKategoriGameByKategori(value)
    formEdit.resetFields(['id_sub_kategori_game']);
  };
  const handleSearch = (value) => {
    setSearchText(value);
  
    const filteredData = dataSourceListArticle.filter((record) => {
      return Object.values(record).some(
        (fieldValue) =>
          fieldValue && fieldValue.toString().toLowerCase().includes(value.toLowerCase())
      );
    });
  
    setFilteredDataSource(filteredData);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    setSortOrder({
      columnKey: sorter.columnKey,
      order: sorter.order,
    });
  };
  const getImageDimensions = (url, setImageDimensions) => {
    const img = new window.Image();
    img.src = url;
    img.onload = () => {
      setImageDimensions({ width: img.width, height: img.height });
    };
  };
  const checkFileSize = (file) => {
    if (!file || !file.size) {
      return true;
    }
    const isLt600KB = ((file.size / 1024) / 1024) < MAX_FILE_SIZE;
    return isLt600KB;
  };
  const fileValidatorHeader = (_, value) => {
    const isFileValid = checkFileSize(informationFileHeader);
    const valuesEdit = formEdit.getFieldsValue();
    if (!value || value.length === 0) {
      return Promise.reject(new Error('Please upload a file!'));
    }
    if (!isFileValid) {
      return Promise.reject(new Error(`File harus lebih kecil dari 600KB!`));
    }
    if (valuesEdit.image_header && !isFileValid) {
      return Promise.reject(new Error(`File harus lebih kecil dari 600KB!`));
    }
    if (valuesEdit.image_header && isFileValid) {
      return Promise.resolve();
    }
    return Promise.resolve();
  };
  const fileValidatorThumbnail = (_, value) => {
    const isFileValid = checkFileSize(informationFileThumbnail);
    const valuesEdit = formEdit.getFieldsValue();
    if (!value || value.length === 0) {
      return Promise.reject(new Error('Please upload a file!'));
    }
    if (!isFileValid) {
      return Promise.reject(new Error(`File harus lebih kecil dari 600KB!`));
    }
    if (valuesEdit.thumbnail && !isFileValid) {
      return Promise.reject(new Error(`File harus lebih kecil dari 600KB!`));
    }
    if (valuesEdit.thumbnail && isFileValid) {
      return Promise.resolve();
    }
    return Promise.resolve();
  };
  return (
    <Layout>
      {contextHolder}
      <Breadcrumb
        items={[
          {
            href: '/dashboard/',
            title: (
              <>
                <HomeOutlined />
                <span>Dashboard</span>
              </>
            ),
          },
          {
            title: "List Article",
          },
        ]}
      />
      <Spin spinning={loadingDeleteItem} tip="Loading...">
        <Flex vertical>
          <Space className='dashboard-add-item'>
            <Button className='dashboard-add-item-button' type="primary" onClick={showDrawerAdd} icon={<PlusOutlined />}>Tambah Article</Button>  
            <Drawer
              className='dashboard-add-item-drawer'
              title="Tambah Article"
              width={1000}
              closable={false}
              visible={openAddItem}
            >
              <Spin spinning={loadingAddItem} tip="Loading...">
                <Form layout="vertical" form={formAdd} className='dashboard-add-item-drawer-form' onFinish={onFinishAdd}>
                  <Row gutter={16}>
                    <Col xs={24} sm={16} lg={10}>
                      <Form.Item
                        name="title"
                        label="Title"
                        rules={[
                          {
                            required: true,
                            message: 'Silahkan Masukkan Title',
                          },
                        ]}
                      >
                        <Input placeholder="Masukan Title" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={16} lg={10}>
                      <Form.Item
                        name="thumbnail"
                        label="Thumbnail"
                        rules={[
                          {
                            validator: fileValidatorThumbnail,
                          },
                        ]}
                      >
                        <Space className='upload-images'>
                          <Upload
                            customRequest={() => {}}
                            fileList={[]}
                            beforeUpload={beforeAddUploadItemThumbnail}
                            onRemove={() => setBase64ImageAddThumbnail('')}
                          >
                            <Button icon={<UploadOutlined />}>Select Image</Button>
                          </Upload>
                          {fileNameAddUploadItemThumbnail}
                          {base64ImageAddThumbnail && (
                            <Text>
                              Image Dimensions (Thumbnail): {imageDimensionsAddThumbnail.width} x {imageDimensionsAddThumbnail.height}
                            </Text>
                          )}
                        </Space>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={16} lg={10}>
                      <Form.Item
                        name="image_header"
                        label="Image Header"
                        rules={[
                          {
                            validator: fileValidatorHeader,
                          },
                        ]}
                      >
                        <Space className='upload-images'>
                          <Upload
                            customRequest={() => {}}
                            fileList={[]}
                            beforeUpload={beforeAddUploadItemImageHeader}
                            onRemove={() => setBase64ImageAddImageHeader('')}
                          >
                            <Button icon={<UploadOutlined />}>Select Image</Button>
                          </Upload>
                          {fileNameAddUploadItemImageHeader}
                          {base64ImageAddImageHeader && (
                            <Text>
                              Image Dimensions (Header): {imageDimensionsAddHeader.width} x {imageDimensionsAddHeader.height}
                            </Text>
                          )}
                        </Space>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24}>
                      <Form.Item 
                        name="artikel" 
                        label="Artikel" 
                        rules={[
                          { 
                            required: true, 
                            message: 'Silahkan Masukkan Artikel' 
                          }
                        ]}
                      >
                        <SunEditor
                          height='250px'
                          setOptions={{
                            buttonList: [
                              ['undo', 'redo'],
                              ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                              ['font', 'fontSize', 'formatBlock'],
                              ['fontColor', 'hiliteColor', 'textStyle'],
                              ['paragraphStyle', 'removeFormat'],
                              ['outdent', 'indent'],
                              ['align', 'horizontalRule', 'list', 'lineHeight', 'blockquote'],
                              ['table', 'image', 'video'],
                              ['link'],
                              ['codeView', 'fullScreen', 'showBlocks'],
                            ],
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={16} lg={10}>
                      <Form.Item
                        name="id_kategori_game"
                        label="Nama Kategori Game"
                        rules={[
                          {
                            required: true,
                            message: 'Silahkan Masukkan Nama Kategori Game',
                          },
                        ]}
                      >
                        <Select
                          showSearch 
                          optionFilterProp="children" 
                          filterOption={
                            (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder="Pilih Kategori Game" 
                          onChange={handleAddKategoriGameChange}
                        >
                          {dataKategoriGame ? dataKategoriGame.map((item) => (
                            <Option key={item.id_kategori_game} value={item.id_kategori_game}>
                              {item.nama_kategori_game}
                            </Option>
                          )) : null}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  {
                    selectedKategoriGame ? 
                      <Row gutter={16}>
                        <Col xs={24} sm={16} lg={10}>
                          <Form.Item
                            name="id_sub_kategori_game"
                            label="Nama Sub Kategori Game"
                            rules={[
                              {
                                required: true,
                                message: 'Silahkan Masukkan Nama Sub Kategori Game',
                              },
                            ]}
                          >
                            <Select
                              showSearch 
                              optionFilterProp="children" 
                              filterOption={
                                (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              placeholder="Pilih Sub Kategori Game"
                            >
                              {dataSubKategoriGame ? dataSubKategoriGame.map((item) => (
                                <Option key={item.id_sub_kategori_game} value={item.id_sub_kategori_game}>
                                  {item.nama_sub_kategori_game}
                                </Option>
                              )) : null}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row> 
                    : (null)
                  }
                  <Row gutter={16}>
                    <Col xs={24} sm={16} lg={10}>
                      <Form.Item
                        name="id_tags"
                        label="Nama Tags"
                        rules={[
                          {
                            required: true,
                            message: 'Silahkan Masukkan Nama Tags',
                          },
                        ]}
                      >
                        <Select
                          showSearch 
                          optionFilterProp="children" 
                          filterOption={
                            (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder="Pilih Team"
                        >
                          {dataTags ? dataTags.map((item) => (
                            <Option key={item.id_tags} value={item.id_tags}>
                              {item.tags}
                            </Option>
                          )) : null}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Space className='dashboard-add-item-drawer-form-button'>
                        <Button onClick={onCloseAdd}>Batal</Button>
                        <Button  type="primary" htmlType='submit'>Tambah</Button>
                      </Space>
                    </Col>
                  </Row>
                </Form>
              </Spin>
            </Drawer>
          </Space>
          <Input
            placeholder="Search"
            onChange={(e) => handleSearch(e.target.value)}
            value={searchText}
            style={{marginBottom: '20px', width: '50%'}}
          />
        </Flex>
        <Table
          bordered={true}
          dataSource={searchText ? filteredDataSource : dataSourceListArticle}
          columns={columns}
          rowKey="key"
          scroll={{
            x: 1600,
          }}
          pagination={{ pageSize: 20 }}
          onChange={handleTableChange}
        />
        <Drawer
          width={1000}
          title="Edit Team"
          closable={false}
          visible={openEditItem}
        >
          <Spin spinning={loadingEditItem} tip="Loading...">
            <Form layout="vertical" form={formEdit} onFinish={onFinishEdit} className='dashboard-edit-item-drawer-form'>
            <Row gutter={16}>
              <Col xs={24} sm={16} lg={10}>
                <Form.Item
                  name="title"
                  label="Title"
                  rules={[
                    {
                      required: true,
                      message: 'Silahkan Masukkan Title',
                    },
                  ]}
                >
                  <Input placeholder="Masukan Title" />
                </Form.Item>
              </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={16} lg={10}>
                  <Form.Item
                    name="thumbnail"
                    label="Thumbnail"
                    rules={[
                      {
                        validator: fileValidatorThumbnail,
                      },
                    ]}
                  >
                    {
                      base64ImageEditThumbnail ? 
                      null : 
                        <Space className='preview-image'>
                          <Text>Preview Image</Text>
                          <Image
                            width={200}
                            src={editImageThumbnail}
                          />
                        </Space>
                    }
                    <Space className='upload-images'>
                      <Upload
                        customRequest={() => {}}
                        fileList={[]}
                        beforeUpload={beforeEditUploadItemThumbnail}
                        onRemove={() => setBase64ImageEditThumbnail('')}
                      >
                        <Button icon={<UploadOutlined />}>Select Image</Button>
                      </Upload>
                      {fileNameEditUploadItemThumbnail}
                      {base64ImageEditThumbnail && (
                            <Text>
                              Image Dimensions (Thumbnail): {imageDimensionsEditThumbnail.width} x {imageDimensionsEditThumbnail.height}
                            </Text>
                          )}
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={16} lg={10}>
                  <Form.Item
                    name="image_header"
                    label="Image Header"
                    rules={[
                      {
                        validator: fileValidatorHeader,
                      },
                    ]}
                  >
                    {
                      base64ImageEditImageHeader ? 
                      null : 
                        <Space className='preview-image'>
                          <Text>Preview Image</Text>
                          <Image
                            width={200}
                            src={editImageHeader}
                          />
                        </Space>
                    }
                    <Space className='upload-images'>
                      <Upload
                        customRequest={() => {}}
                        fileList={[]}
                        beforeUpload={beforeEditUploadItemImageHeader}
                        onRemove={() => setBase64ImageEditImageHeader('')}
                      >
                        <Button icon={<UploadOutlined />}>Select Image</Button>
                      </Upload>
                      {fileNameEditUploadItemImageHeader}
                      {base64ImageEditImageHeader && (
                            <Text>
                              Image Dimensions (Header): {imageDimensionsEditHeader.width} x {imageDimensionsEditHeader.height}
                            </Text>
                          )}
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24}>
                  <Form.Item 
                    name="artikel" 
                    label="Artikel" 
                    rules={[
                      { 
                        required: true, 
                        message: 'Silahkan Masukkan Artikel' 
                      }
                    ]}
                  >
                    <SunEditor
                      height='250px'
                      setOptions={{
                        buttonList: [
                          ['undo', 'redo'],
                          ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                          ['font', 'fontSize', 'formatBlock'],
                          ['fontColor', 'hiliteColor', 'textStyle'],
                          ['paragraphStyle', 'removeFormat'],
                          ['outdent', 'indent'],
                          ['align', 'horizontalRule', 'list', 'lineHeight', 'blockquote'],
                          ['table', 'image', 'video'],
                          ['link'],
                          ['codeView', 'fullScreen', 'showBlocks'],
                        ],
                      }}
                      setContents={previousContent}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={16} lg={10}>
                  <Form.Item
                    name="id_kategori_game"
                    label="Nama Kategori Game"
                    rules={[
                      {
                        required: true,
                        message: 'Silahkan Masukkan Nama Kategori Game',
                      },
                    ]}
                  >
                    <Select
                      showSearch 
                      optionFilterProp="children" 
                      filterOption={
                        (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="Pilih Kategori Game" 
                      onChange={handleEditKategoriGameChange}
                    >
                      {dataKategoriGame ? dataKategoriGame.map((item) => (
                        <Option key={item.id_kategori_game} value={item.id_kategori_game}>
                          {item.nama_kategori_game}
                        </Option>
                      )) : null}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={16} lg={10}>
                  <Form.Item
                    name="id_sub_kategori_game"
                    label="Nama Sub Kategori Game"
                    rules={[
                      {
                        required: true,
                        message: 'Silahkan Masukkan Nama Sub Kategori Game',
                      },
                    ]}
                  >
                    <Select
                      showSearch 
                      optionFilterProp="children" 
                      filterOption={
                        (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="Pilih Sub Kategori Game"
                    >
                      {dataSubKategoriGame ? dataSubKategoriGame.map((item) => (
                        <Option key={item.id_sub_kategori_game} value={item.id_sub_kategori_game}>
                          {item.nama_sub_kategori_game}
                        </Option>
                      )) : null}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={16} lg={10}>
                  <Form.Item
                    name="id_tags"
                    label="Nama Tags"
                    rules={[
                      {
                        required: true,
                        message: 'Silahkan Masukkan Nama Tags',
                      },
                    ]}
                  >
                    <Select
                      showSearch 
                      optionFilterProp="children" 
                      filterOption={
                        (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="Pilih Team"
                    >
                      {dataTags ? dataTags.map((item) => (
                        <Option key={item.id_tags} value={item.id_tags}>
                          {item.tags}
                        </Option>
                      )) : null}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Space className='dashboard-edit-item-drawer-form-button'>
                    <Button onClick={onCloseEdit}>Batal</Button>
                    <Button  type="primary" htmlType='submit'>Update</Button>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Drawer>
      </Spin>
    </Layout>
  )
};

export default DashboardListArticle;
