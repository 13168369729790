import React from "react";

import "./index.scss";
import { logoScoreQuest } from "../../../Assets/images";

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
  FileSearchOutlined,
  SettingOutlined,
  UserOutlined,
  DownOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { Route, Routes, useNavigate, Link } from "react-router-dom";

import {
  WelcomingDashboard,
  DashboardListUser,
  DashboardListAdmin,
  DashboardListNegara,
  DashboardListTag,
  DashboardListKategoriGame,
  DashboardListSubKategoriGame,
  DashboardListTeam,
  DashboardListMasterSeason,
  DashboardListSeason,
  DashboardListVideo,
  DashboardListLineUp,
  DashboardListArticle,
  DashboardDetailArticle,
  DashboardListSkemaGame,
  DashboardListJadwalPertandingan,
  DashboardListSkema,
  DashboardListMasterBracket,
  DashboardListMasterRole,
  DashboardListMasterMember,
  DashboardListMemberTournament,
  DashboardListBracketTournament,
  DashboardReportScore,
  DashboardLeaderboardPlayer,
  DashboardLeaderboardTeam,
  DashboardScoreboard,
  DashboardMasterMap,
  DashboardReportScoreNonBracket,
} from "../../pages";
import * as listRoutes from "../../../config/routes/listRoutes";

import {
  Flex,
  Layout,
  Menu,
  Space,
  Avatar,
  Divider,
  Dropdown,
  message,
  Image,
  Typography,
  Button,
} from "antd";
import DashboardListTournament from "../DashboardListTournament";
const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;

const Dashboard = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const items2 = [
    {
      key: "Live score",
      label: "Live score",
      icon: <FileSearchOutlined />,
      children: [
        getItem(
          "List User",
          "1",
          <Link to={listRoutes.DashboardListUser}>
            <FileSearchOutlined />
          </Link>,
          null,
          null,
        ),
        getItem(
          "List Admin",
          "2",
          <Link to={listRoutes.DashboardListAdmin}>
            <FileSearchOutlined />
          </Link>,
          null,
          null,
        ),
        getItem(
          "List Negara",
          "3",
          <Link to={listRoutes.DashboardListNegara}>
            <FileSearchOutlined />
          </Link>,
          null,
          null,
        ),
        getItem(
          "List Tag",
          "4",
          <Link to={listRoutes.DashboardListTag}>
            <FileSearchOutlined />
          </Link>,
          null,
          null,
        ),
        getItem(
          "List Kategori Game",
          "5",
          <Link to={listRoutes.DashboardListKategoriGame}>
            <FileSearchOutlined />
          </Link>,
          null,
          null,
        ),
        getItem(
          "List Sub Kategori Game",
          "6",
          <Link to={listRoutes.DashboardListSubKategoriGame}>
            <FileSearchOutlined />
          </Link>,
          null,
          null,
        ),
        getItem(
          "List Team",
          "7",
          <Link to={listRoutes.DashboardListTeam}>
            <FileSearchOutlined />
          </Link>,
          null,
          null,
        ),
        getItem(
          "List Master Season",
          "8",
          <Link to={listRoutes.DashboardListMasterSeason}>
            <FileSearchOutlined />
          </Link>,
          null,
          null,
        ),
        getItem(
          "List Team Season",
          "9",
          <Link to={listRoutes.DashboardListSeason}>
            <FileSearchOutlined />
          </Link>,
          null,
          null,
        ),
        getItem(
          "List Video",
          "10",
          <Link to={listRoutes.DashboardListVideo}>
            <FileSearchOutlined />
          </Link>,
          null,
          null,
        ),
        getItem(
          "List Line Up",
          "11",
          <Link to={listRoutes.DashboardListLineUp}>
            <FileSearchOutlined />
          </Link>,
          null,
          null,
        ),
        getItem(
          "List Article",
          "12",
          <Link to={listRoutes.DashboardListArticle}>
            <FileSearchOutlined />
          </Link>,
          null,
          null,
        ),
        getItem(
          "List Skema",
          "13",
          <Link to={listRoutes.DashboardListSkema}>
            <FileSearchOutlined />
          </Link>,
          null,
          null,
        ),
        getItem(
          "List Skema Game",
          "14",
          <Link to={listRoutes.DashboardListSkemaGame}>
            <FileSearchOutlined />
          </Link>,
          null,
          null,
        ),
        getItem(
          "List Jadwal Pertandingan",
          "15",
          <Link to={listRoutes.DashboardListJadwalPertandingan}>
            <FileSearchOutlined />
          </Link>,
          null,
          null,
        ),
      ],
    },
    {
      type: "divider",
    },
    {
      key: "Tournament",
      label: "Tournament",
      icon: <FileSearchOutlined />,
      children: [
        getItem(
          "List Tournament",
          "16",
          <Link to={listRoutes.DashboardListTournament}>
            <FileSearchOutlined />
          </Link>,
          null,
          null,
        ),

        getItem(
          "List Peserta Tournament",
          "20",
          <Link to={listRoutes.DashboardListMemberTournament}>
            <FileSearchOutlined />
          </Link>,
          null,
          null,
        ),
        getItem(
          "List Bracket Tournament",
          "21",
          <Link to={listRoutes.DashboardListBracketTournament}>
            <FileSearchOutlined />
          </Link>,
          null,
          null,
        ),
        getItem(
          "Report Score",
          "22",
          <Link to={listRoutes.DashboardReportScore}>
            <FileSearchOutlined />
          </Link>,
          null,
          null,
        ),
        getItem(
          "Report Score Non Bracket",
          "26",
          <Link to={listRoutes.DashboardReportScoreNonBracket}>
            <FileSearchOutlined />
          </Link>,
          null,
          null,
        ),
        getItem(
          "Scoreboard",
          "23",
          <Link to={listRoutes.DashboardScoreboard}>
            <FileSearchOutlined />
          </Link>,
          null,
          null,
        ),
        getItem(
          "Leaderboard Player",
          "24",
          <Link to={listRoutes.DashboardLeaderboardPlayer}>
            <FileSearchOutlined />
          </Link>,
          null,
          null,
        ),
        getItem(
          "Leaderboard Team",
          "241",
          <Link to={listRoutes.DashboardLeaderboardTeam}>
            <FileSearchOutlined />
          </Link>,
          null,
          null,
        ),
        getItem(
          "Master Map",
          "25",
          <Link to={listRoutes.DashboardMasterMap}>
            <FileSearchOutlined />
          </Link>,
          null,
          null,
        ),
        getItem(
          "List Master Bracket",
          "17",
          <Link to={listRoutes.DashboardListMasterBracket}>
            <FileSearchOutlined />
          </Link>,
          null,
          null,
        ),
        getItem(
          "List Master Role",
          "18",
          <Link to={listRoutes.DashboardListMasterRole}>
            <FileSearchOutlined />
          </Link>,
          null,
          null,
        ),
        getItem(
          "List Master Max Member",
          "19",
          <Link to={listRoutes.DashboardListMasterMember}>
            <FileSearchOutlined />
          </Link>,
          null,
          null,
        ),
      ],
    },
  ];
  const items = [
    getItem(
      "List User",
      "1",
      <Link to={listRoutes.DashboardListUser}>
        <FileSearchOutlined />
      </Link>,
      null,
      null,
    ),
    getItem(
      "List Admin",
      "2",
      <Link to={listRoutes.DashboardListAdmin}>
        <FileSearchOutlined />
      </Link>,
      null,
      null,
    ),
    getItem(
      "List Negara",
      "3",
      <Link to={listRoutes.DashboardListNegara}>
        <FileSearchOutlined />
      </Link>,
      null,
      null,
    ),
    getItem(
      "List Tag",
      "4",
      <Link to={listRoutes.DashboardListTag}>
        <FileSearchOutlined />
      </Link>,
      null,
      null,
    ),
    getItem(
      "List Kategori Game",
      "5",
      <Link to={listRoutes.DashboardListKategoriGame}>
        <FileSearchOutlined />
      </Link>,
      null,
      null,
    ),
    getItem(
      "List Sub Kategori Game",
      "6",
      <Link to={listRoutes.DashboardListSubKategoriGame}>
        <FileSearchOutlined />
      </Link>,
      null,
      null,
    ),
    getItem(
      "List Team",
      "7",
      <Link to={listRoutes.DashboardListTeam}>
        <FileSearchOutlined />
      </Link>,
      null,
      null,
    ),
    getItem(
      "List Master Season",
      "8",
      <Link to={listRoutes.DashboardListMasterSeason}>
        <FileSearchOutlined />
      </Link>,
      null,
      null,
    ),
    getItem(
      "List Team Season",
      "9",
      <Link to={listRoutes.DashboardListSeason}>
        <FileSearchOutlined />
      </Link>,
      null,
      null,
    ),
    getItem(
      "List Video",
      "10",
      <Link to={listRoutes.DashboardListVideo}>
        <FileSearchOutlined />
      </Link>,
      null,
      null,
    ),
    getItem(
      "List Line Up",
      "11",
      <Link to={listRoutes.DashboardListLineUp}>
        <FileSearchOutlined />
      </Link>,
      null,
      null,
    ),
    getItem(
      "List Article",
      "12",
      <Link to={listRoutes.DashboardListArticle}>
        <FileSearchOutlined />
      </Link>,
      null,
      null,
    ),
    getItem(
      "List Skema",
      "13",
      <Link to={listRoutes.DashboardListSkema}>
        <FileSearchOutlined />
      </Link>,
      null,
      null,
    ),
    getItem(
      "List Skema Game",
      "14",
      <Link to={listRoutes.DashboardListSkemaGame}>
        <FileSearchOutlined />
      </Link>,
      null,
      null,
    ),
    getItem(
      "List Jadwal Pertandingan",
      "15",
      <Link to={listRoutes.DashboardListJadwalPertandingan}>
        <FileSearchOutlined />
      </Link>,
      null,
      null,
    ),
    getItem(
      "List Tournament",
      "16",
      <Link to={listRoutes.DashboardListTournament}>
        <FileSearchOutlined />
      </Link>,
      null,
      null,
    ),

    getItem(
      "List Peserta Tournament",
      "20",
      <Link to={listRoutes.DashboardListMemberTournament}>
        <FileSearchOutlined />
      </Link>,
      null,
      null,
    ),
    getItem(
      "List Bracket Tournament",
      "21",
      <Link to={listRoutes.DashboardListBracketTournament}>
        <FileSearchOutlined />
      </Link>,
      null,
      null,
    ),
    getItem(
      "Report Score",
      "22",
      <Link to={listRoutes.DashboardReportScore}>
        <FileSearchOutlined />
      </Link>,
      null,
      null,
    ),
    getItem(
      "Report Score Non Bracket",
      "26",
      <Link to={listRoutes.DashboardReportScoreNonBracket}>
        <FileSearchOutlined />
      </Link>,
      null,
      null,
    ),
    getItem(
      "Scoreboard",
      "23",
      <Link to={listRoutes.DashboardScoreboard}>
        <FileSearchOutlined />
      </Link>,
      null,
      null,
    ),
    getItem(
      "Leaderboard Player",
      "24",
      <Link to={listRoutes.DashboardLeaderboardPlayer}>
        <FileSearchOutlined />
      </Link>,
      null,
      null,
    ),
    getItem(
      "Leaderboard Team",
      "241",
      <Link to={listRoutes.DashboardLeaderboardTeam}>
        <FileSearchOutlined />
      </Link>,
      null,
      null,
    ),
    getItem(
      "Master Map",
      "25",
      <Link to={listRoutes.DashboardMasterMap}>
        <FileSearchOutlined />
      </Link>,
      null,
      null,
    ),
    getItem(
      "List Master Bracket",
      "17",
      <Link to={listRoutes.DashboardListMasterBracket}>
        <FileSearchOutlined />
      </Link>,
      null,
      null,
    ),
    getItem(
      "List Master Role",
      "18",
      <Link to={listRoutes.DashboardListMasterRole}>
        <FileSearchOutlined />
      </Link>,
      null,
      null,
    ),
    getItem(
      "List Master Max Member",
      "19",
      <Link to={listRoutes.DashboardListMasterMember}>
        <FileSearchOutlined />
      </Link>,
      null,
      null,
    ),
  ];

  const userItems = [
    // {
    //   label: <Link to="/profile">Profile</Link>,
    //   key: '1',
    // },
    // {
    //   label: <Link to="/setting">Setting</Link>,
    //   key: '2',
    // },
    {
      label: <Button onClick={() => logout()}>Logout</Button>,
      key: "1",
    },
  ];

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("id_admin");
    setTimeout(() => {
      messageApi.open({
        type: "success",
        content: "Success Logout",
        duration: 4,
      });
      navigate("/");
    }, 1000);
  };

  return (
    <Layout className="dashboard-score-quest-wrapper">
      {contextHolder}
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        width={280}
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
        className="dashbaord-sider"
      >
        <Flex align="center" gap={10} className="sider-logo">
          <Image width={90} src={logoScoreQuest} />
          <Title level={3}>Score Quest</Title>
        </Flex>

        <Menu
          mode="inline"
          items={items2}
          defaultSelectedKeys={[""]}
          className="dashboard-sider-menu"
        />
      </Sider>
      <Layout className="dashboard-layout">
        <Header className="dashboard-header">
          <Space align="center" split={<Divider type="vertical" />}>
            <Dropdown
              overlay={
                <Menu>
                  {userItems.map((item) => (
                    <Menu.Item
                      key={item.key}
                      icon={item.icon}
                      disabled={item.disabled}
                      danger={item.danger}
                    >
                      {item.label}
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              <a onClick={(e) => (e.preventDefault(), console.log(e))}>
                <Space>
                  <Avatar icon={<UserOutlined />} />
                </Space>
              </a>
            </Dropdown>
          </Space>
        </Header>
        <Content className="dashboard-content">
          <Routes>
            <Route exact path="/" element={<WelcomingDashboard />} />
            <Route path="/list-admin" element={<DashboardListAdmin />} />
            <Route path="/list-user" element={<DashboardListUser />} />
            <Route path="/list-negara" element={<DashboardListNegara />} />
            <Route path="/list-tag" element={<DashboardListTag />} />
            <Route
              path="/list-kategori-game"
              element={<DashboardListKategoriGame />}
            />
            <Route
              path="/list-sub-kategori-game"
              element={<DashboardListSubKategoriGame />}
            />
            <Route path="/list-team" element={<DashboardListTeam />} />
            <Route
              path="/list-master-season"
              element={<DashboardListMasterSeason />}
            />
            <Route path="/list-season" element={<DashboardListSeason />} />
            <Route path="/list-video" element={<DashboardListVideo />} />
            <Route path="/list-lineup" element={<DashboardListLineUp />} />
            <Route path="/list-article" element={<DashboardListArticle />} />
            <Route
              path="/list-article/detail-article"
              element={<DashboardDetailArticle />}
            />
            <Route path="/list-skema" element={<DashboardListSkema />} />
            <Route
              path="/list-skema-game"
              element={<DashboardListSkemaGame />}
            />
            <Route
              path="/list-jadwal-pertandingan"
              element={<DashboardListJadwalPertandingan />}
            />
            <Route
              path="/list-tournament"
              element={<DashboardListTournament />}
            />

            <Route
              path="/list-member-tournament"
              element={<DashboardListMemberTournament />}
            />
            <Route
              path="/list-bracket-tournament"
              element={<DashboardListBracketTournament />}
            />
            <Route path="/report-score" element={<DashboardReportScore />} />
            <Route path="/scoreboard" element={<DashboardScoreboard />} />
            <Route
              path="/leaderboard-team"
              element={<DashboardLeaderboardTeam />}
            />
            <Route
              path="/leaderboard-player"
              element={<DashboardLeaderboardPlayer />}
            />
            <Route path="/list-master-map" element={<DashboardMasterMap />} />
            <Route
              path="/list-master-bracket"
              element={<DashboardListMasterBracket />}
            />
            <Route
              path="/list-master-role"
              element={<DashboardListMasterRole />}
            />
            <Route
              path="/list-master-member"
              element={<DashboardListMasterMember />}
            />
            <Route
              path="/report-score-non-bracket"
              element={<DashboardReportScoreNonBracket />}
            />
          </Routes>
        </Content>
        <Footer className="dashboard-footer">Score Quest ©2023</Footer>
      </Layout>
    </Layout>
  );
};
export default Dashboard;
