export const Default = "/";
export const Profile = "/profile";
export const Setting = "/setting";
export const Logout = "/logout";
export const Dashboard = "/dashboard";
export const DashboardListUser = "list-user";
export const DashboardListAdmin = "list-admin";
export const DashboardListNegara = "list-negara";
export const DashboardListTag = "list-tag";
export const DashboardListKategoriGame = "list-kategori-game";
export const DashboardListSubKategoriGame = "list-sub-kategori-game";
export const DashboardListTeam = "list-team";
export const DashboardListMasterSeason = "list-master-season";
export const DashboardListSeason = "list-season";
export const DashboardListVideo = "list-video";
export const DashboardListLineUp = "list-lineup";
export const DashboardListArticle = "list-article";
export const DashboardDetailArticle = "list-article/detail-article";
export const DashboardListJadwalPertandingan = "list-jadwal-pertandingan";
export const DashboardListSkemaGame = "list-skema-game";
export const DashboardListSkema = "list-skema";

export const DashboardProfileUser = "profile-user";
export const DashboardFavoriteTeam = "favorite-team";
export const DashboardLiveScore = "live-score";
export const DashboardMatchSchedule = "match-schedule";
export const DashboardLineUp = "line-up";
export const DashboardVideoHighlightAndThumbnail =
  "video-highlight-and-thumbnail";

export const DashboardListTournament = "list-tournament";
export const DashboardListMasterBracket = "list-master-bracket";
export const DashboardListMasterRole = "list-master-role";
export const DashboardListMasterMember = "list-master-member";
export const DashboardListMemberTournament = "list-member-tournament";
export const DashboardListBracketTournament = "list-bracket-tournament";
export const DashboardReportScore = "report-score";
export const DashboardScoreboard = "scoreboard";
export const DashboardLeaderboardTeam = "leaderboard-team";
export const DashboardLeaderboardPlayer = "leaderboard-player";
export const DashboardMasterMap = "list-master-map";
export const DashboardReportScoreNonBracket = "report-score-non-bracket";
