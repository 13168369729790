import React, { useState, useEffect } from 'react';
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Constant from "../../../config/constans";
import { PlusOutlined, UploadOutlined, HomeOutlined } from '@ant-design/icons';
import { Button, Col, Breadcrumb, Drawer, Form, Input, Row, Select, Space, Layout, Typography, Table, Upload, Spin, Image, message, Popconfirm, Flex } from 'antd';
const { Text } = Typography;
const { Option } = Select;


const DashboardListLineUp = () => {
  const [openAddItem, setOpenAddItem] = useState(false);
  const [openEditItem, setOpenEditItem] = useState(false);
  const [data, setData] = useState([]);
  const [dataSeason, setDataSeason] = useState([]);
  const [dataTeam, setDataTeam] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [selectedMasterSeason, setSelectedMasterSeason] = useState(false);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [loadingAddItem, setLoadingAddItem] = useState(false);
  const [loadingEditItem, setLoadingEditItem] = useState(false);
  const [loadingDeleteItem, setLoadingDeleteItem] = useState(false);
  const [base64ImageEditRole, setBase64ImageEditRole] = useState('');
  const [base64ImageAddRole, setBase64ImageAddRole] = useState('');
  const [fileNameEditUploadItemRole, setFileNameEditUploadItemRole] = useState('');
  const [fileNameAddUploadItemRole, setFileNameAddUploadItemRole] = useState('');
  const [base64ImageEditPlayer, setBase64ImageEditPlayer] = useState('');
  const [base64ImageAddPlayer, setBase64ImageAddPlayer] = useState('');
  const [fileNameEditUploadItemPlayer, setFileNameEditUploadItemPlayer] = useState('');
  const [fileNameAddUploadItemPlayer, setFileNameAddUploadItemPlayer] = useState('');
  const [editImageRole, setEditImageRole] = useState('');
  const [editImagePlayer, setEditImagePlayer] = useState('');
  const [imageDimensionsAddRole, setImageDimensionsAddRole] = useState({ width: 0, height: 0 });
  const [imageDimensionsAddPlayer, setImageDimensionsAddPlayer] = useState({ width: 0, height: 0 });
  const [imageDimensionsEditRole, setImageDimensionsEditRole] = useState({ width: 0, height: 0 });
  const [imageDimensionsEditPlayer, setImageDimensionsEditPlayer] = useState({ width: 0, height: 0 });
  const [informationFilePlayer, setInformationFilePlayer] = useState(null)
  const [informationFileRole, setInformationFileRole] = useState(null)
  const [sortOrder, setSortOrder] = useState({
    columnKey: null,
    order: null,
  });
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const authToken = localStorage.getItem('token');
  const [formAdd] = Form.useForm();
  const [formEdit] = Form.useForm();
  const MAX_FILE_SIZE = 0.6;

  const columns = [
    {
      title: 'Nama Season',
      dataIndex: 'nama_season',
      key: 'nama_season',
      width: "20%",
      sorter: (a, b) => a.nama_season.localeCompare(b.nama_season),
      sortOrder: sortOrder.columnKey === 'nama_season' && sortOrder.order,
    },
    {
      title: 'Nama Team',
      dataIndex: 'nama_team',
      key: 'nama_team',
      width: "20%",
      sorter: (a, b) => a.nama_team.localeCompare(b.nama_team),
      sortOrder: sortOrder.columnKey === 'nama_team' && sortOrder.order,
    },
    {
      title: 'Nama Player',
      dataIndex: 'nama_player',
      key: 'nama_player',
      width: "20%",
      sorter: (a, b) => a.nama_player.localeCompare(b.nama_player),
      sortOrder: sortOrder.columnKey === 'nama_player' && sortOrder.order,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      width: "20%",
      sorter: (a, b) => a.role.localeCompare(b.role),
      sortOrder: sortOrder.columnKey === 'role' && sortOrder.order,
    },
    {
      title: 'Created Date',
      dataIndex: 'createdat',
      key: 'createDat',
      width: "20%",
      sorter: (a, b) => moment(a.createdat) - moment(b.createdat),
      sortOrder: sortOrder.columnKey === 'createdat' && sortOrder.order,
    },
    {
      title: 'Updated Date',
      dataIndex: 'udpatedat',
      key: 'udpatedat',
      width: "20%",
      sorter: (a, b) => moment(a.udpatedat) - moment(b.udpatedat),
      sortOrder: sortOrder.columnKey === 'udpatedat' && sortOrder.order,
    },
    {
      title: 'Actions',
      key: 'actions',
      width: "20%",
      render: (_, record) => (
        <span style={{display:'flex'}}>
          <Button onClick={() => showDrawerEdit(record)}>Edit</Button>
          <Popconfirm
            title="Delete Line Up"
            description="Apakah yakin ingin menghapus Line Up?"
            onConfirm={() => handleDelete(record)}
            okText= "Hapus"
            cancelText="Tidak"
          >
            <Button style={{ marginLeft: 8 }} danger>Delete</Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  useEffect(() => {
    getListLineUp()
    getListSeason()
  }, [loadData]);

  const getListLineUp = () => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_LINE_UP;
    axios
    .get(URL, { headers })
    .then((response) => {
      if (response.status === 200) {
        setLoadData(false);
        setData(response.data.Data)
        setBase64ImageEditRole('')
        setBase64ImageAddRole('')
        setFileNameAddUploadItemRole('')
        setBase64ImageEditPlayer('')
        setBase64ImageAddPlayer('')
        setFileNameAddUploadItemPlayer('')
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        localStorage.removeItem('token');
        localStorage.removeItem('id_admin');
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      }
    });
  }
  const getListSeason = () => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_MASTER_SEASON;
    axios
    .get(URL, { headers })
    .then((response) => {
      if (response.status === 200) {
        setDataSeason(response.data.Data)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      }
    });
  }

  const addListLineUp = (payload) => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_ADD_LISTS_LINE_UP;
    axios
    .post(URL, payload, { headers })
    .then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          setSearchText('')
          setLoadData(true)
          setLoadingAddItem(false);
          setOpenAddItem(false);
          messageApi.open({
            type: 'success',
            content: 'Success Add Line Up',
            duration: 4,
          });
          formAdd.resetFields();
        },1000)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      } else {
        setTimeout(() => {
          setLoadData(true)
          messageApi.open({
            type: 'error',
            content: error.message,
            duration: 4,
          });
          setLoadingAddItem(false);
          setOpenAddItem(false);
          formAdd.resetFields();
        },1000)
      }
    });
  }
  const editListLineUp = (payload) => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_EDIT_LISTS_LINE_UP;
    axios
    .post(URL, payload, { headers })
    .then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          setSearchText('')
          setLoadData(true)
          setLoadingEditItem(false);
          setOpenEditItem(false);
          messageApi.open({
            type: 'success',
            content: 'Success Edit Line Up',
            duration: 4,
          });
          formEdit.resetFields();
        },1000)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      } else {
        setTimeout(() => {
          setLoadData(true)
          messageApi.open({
            type: 'error',
            content: error.message,
            duration: 4,
          });
          setLoadingEditItem(false);
          setOpenEditItem(false);
          formEdit.resetFields();
        },1000)
      }
    });
  }
  const deleteListLineUp = (payload) => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_DELETE_LISTS_LINE_UP;
    axios
    .post(URL, payload, { headers })
    .then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          setSearchText('')
          setLoadData(true)
          setLoadingDeleteItem(false);
          messageApi.open({
            type: 'success',
            content: 'Success Delete Line Up',
            duration: 4,
          });
        },1000)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      } else {
        setTimeout(() => {
          setLoadData(true)
          setLoadingDeleteItem(false);
          messageApi.open({
            type: 'error',
            content: error.message,
            duration: 4,
          });
        },1000)
      }
    });
  }
  const getListTeamByMasterSeason = (payloadId) => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const payload = 
    {
      "id_master_season": payloadId
    }
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_LISTS_TEAM_BY_MASTER_SEASON;
    axios
    .post(URL, payload, { headers })
    .then((response) => {
      if (response.status === 200) {
        setDataTeam(response.data.Data)
        setSelectedMasterSeason(true);
        setLoadingAddItem(false)
        setLoadingEditItem(false)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      }
    });
  }

  const dataSourceListLineUp = data
  ? data.map((item, index) => {
    const createdat = moment(item.createdat).format("DD MMMM YYYY")
    const updatedat = moment(item.udpatedat).format("DD MMMM YYYY")
    return {
      key: index,
      createdat: createdat,
      udpatedat: updatedat,
      id_lineup: item.id_lineup,
      id_team: item.id_team,
      id_master_season: item.id_master_season,
      nama_team: item.nama_team,
      image_team: item.image_team,
      nama_season: item.nama_season,
      nama_player: item.nama_player,
      role: item.role,
      image_role: item.image_role,
      image_player: item.image_player,
      url1: item.url1,
      url2: item.url2,
      isCaptain: item.isCaptain
    };
  })
  : null;

  const handleAddMasterSeason = (value) => {
    setLoadingAddItem(true)
    getListTeamByMasterSeason(value)
    formAdd.resetFields(['id_team']);
  };

  const handleEditMasterSeason = (value) => {
    setLoadingEditItem(true)
    getListTeamByMasterSeason(value)
    formEdit.resetFields(['id_team']);
  };

  const handleDelete = (record) => {
    setLoadingDeleteItem(true)
    const valueId = {
      "id_lineup": record.id_lineup
    }
    deleteListLineUp(valueId)
  };

  const showDrawerEdit = (record) => {
    setSelectedRowKey(record.key);
    setOpenEditItem(true);
    setEditImageRole(record.image_role)
    setEditImagePlayer(record.image_player)
    getListTeamByMasterSeason(record.id_master_season)
    formEdit.setFieldsValue({
      createdat: record.createdat,
      udpatedat: record.udpatedat,
      id_lineup: record.id_lineup,
      id_team: record.id_team,
      id_master_season: record.id_master_season,
      nama_team: record.nama_team,
      image_team: record.image_team,
      nama_season: record.nama_season,
      nama_player: record.nama_player,
      role: record.role,
      image_role: record.image_role,
      image_player: record.image_player,
      url1: record.url1,
      url2: record.url2,
      isCaptain: record.isCaptain.toString()
    });
  };

  const showDrawerAdd = () => {
    setOpenAddItem(true);
  };
  const onCloseAdd = () => {
    setInformationFilePlayer('')
    setInformationFileRole('')
    setBase64ImageAddRole('')
    setFileNameAddUploadItemRole('')
    setBase64ImageAddPlayer('')
    setFileNameAddUploadItemPlayer('')
    formAdd.resetFields();
    setOpenAddItem(false);
  };
  const onCloseEdit = () => {
    setInformationFilePlayer('')
    setInformationFileRole('')
    setBase64ImageEditRole('')
    setFileNameEditUploadItemRole('')
    setBase64ImageEditPlayer('')
    setFileNameEditUploadItemPlayer('')
    formEdit.resetFields();
    setOpenEditItem(false);
  };
  const onFinishAdd = () => {
    setLoadingAddItem(true)
    const values = formAdd.getFieldsValue();
    const regexUpload = /^data:image\/(\w+);base64,/;
    if (base64ImageAddRole) {
      const matchUploadRole = base64ImageAddRole.match(regexUpload);
      const imageWithoutPrefixRole = base64ImageAddRole.substring(matchUploadRole[0].length);
      values.image_role = imageWithoutPrefixRole;
    }
    if (base64ImageAddPlayer) {
      const matchUploadPlayer = base64ImageAddPlayer.match(regexUpload);
      const imageWithoutPrefixPlayer = base64ImageAddPlayer.substring(matchUploadPlayer[0].length);
      values.image_player = imageWithoutPrefixPlayer;
    }
    setInformationFilePlayer('')
    setInformationFileRole('')
    addListLineUp(values)
  };
  const onFinishEdit = () => {
    setLoadingEditItem(true)
    const values = formEdit.getFieldsValue();
    const regexUpload = /^data:image\/(\w+);base64,/;
    if (base64ImageEditRole) {
      const matchUploadRole = base64ImageEditRole.match(regexUpload);
      const imageWithoutPrefixRole = base64ImageEditRole.substring(matchUploadRole[0].length);
      values.image_role = imageWithoutPrefixRole;
    } else {
      values.image_role = '-'
    }

    if (base64ImageEditPlayer) {
      const matchUploadPlayer = base64ImageEditPlayer.match(regexUpload);
      const imageWithoutPrefixPlayer = base64ImageEditPlayer.substring(matchUploadPlayer[0].length);
      values.image_player = imageWithoutPrefixPlayer;
    } else {
      values.image_player = '-'
    }

    values.id_lineup = data[selectedRowKey].id_lineup
    setInformationFilePlayer('')
    setInformationFileRole('')
    values.isCaptain = values.isCaptain.toLowerCase() === "true";
    editListLineUp(values)
  };
  const beforeAddUploadItemRole = (file) => {
    setInformationFileRole(file)
    const isLt600KB = ((file.size / 1024) / 1024) < MAX_FILE_SIZE;
    if (!isLt600KB) {
      message.error(`File harus lebih kecil dari 600KB!`);
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setBase64ImageAddRole(reader.result);
      getImageDimensions(reader.result, setImageDimensionsAddRole);
    };

    const fileName = file.name;
    setFileNameAddUploadItemRole(fileName);

    return false;
  };
  const beforeAddUploadItemPlayer = (file) => {
    setInformationFilePlayer(file)
    const isLt600KB = ((file.size / 1024) / 1024) < MAX_FILE_SIZE;
    if (!isLt600KB) {
      message.error(`File harus lebih kecil dari 600KB!`);
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setBase64ImageAddPlayer(reader.result);
      getImageDimensions(reader.result, setImageDimensionsAddPlayer);
    };

    const fileName = file.name;
    setFileNameAddUploadItemPlayer(fileName);

    return false;
  };
  const beforeEditUploadItemRole = (file) => {
    setInformationFileRole(file)
    const isLt600KB = ((file.size / 1024) / 1024) < MAX_FILE_SIZE;
    if (!isLt600KB) {
      message.error(`File harus lebih kecil dari 600KB!`);
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setBase64ImageEditRole(reader.result);
      getImageDimensions(reader.result, setImageDimensionsEditRole);
    };
    const fileName = file.name;
    setFileNameEditUploadItemRole(fileName);

    return false;
  };
  const beforeEditUploadItemPlayer = (file) => {
    setInformationFilePlayer(file)
    const isLt600KB = ((file.size / 1024) / 1024) < MAX_FILE_SIZE;
    if (!isLt600KB) {
      message.error(`File harus lebih kecil dari 600KB!`);
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setBase64ImageEditPlayer(reader.result);
      getImageDimensions(reader.result, setImageDimensionsEditPlayer);
    };
    const fileName = file.name;
    setFileNameEditUploadItemPlayer(fileName);
    return false;
  };
  const handleSearch = (value) => {
    setSearchText(value);
  
    const filteredData = dataSourceListLineUp.filter((record) => {
      return Object.values(record).some(
        (fieldValue) =>
          fieldValue && fieldValue.toString().toLowerCase().includes(value.toLowerCase())
      );
    });
  
    setFilteredDataSource(filteredData);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    setSortOrder({
      columnKey: sorter.columnKey,
      order: sorter.order,
    });
  };
  const getImageDimensions = (url, setImageDimensions) => {
    const img = new window.Image();
    img.src = url;
    img.onload = () => {
      setImageDimensions({ width: img.width, height: img.height });
    };
  };
  const checkFileSize = (file) => {
    if (!file || !file.size) {
      return true;
    }
    const isLt600KB = ((file.size / 1024) / 1024) < MAX_FILE_SIZE;
    return isLt600KB;
  };
  const fileValidatorImagePlayer = (_, value) => {
    const isFileValid = checkFileSize(informationFilePlayer);
    const valuesEdit = formEdit.getFieldsValue();
    if (!value || value.length === 0) {
      return Promise.reject(new Error('Please upload a file!'));
    }
    if (!isFileValid) {
      return Promise.reject(new Error(`File harus lebih kecil dari 600KB!`));
    }
    if (valuesEdit.image_player && !isFileValid) {
      return Promise.reject(new Error(`File harus lebih kecil dari 600KB!`));
    }
    if (valuesEdit.image_player && isFileValid) {
      return Promise.resolve();
    }
    return Promise.resolve();
  };
  const fileValidatorRole = (_, value) => {
    const isFileValid = checkFileSize(informationFileRole);
    const valuesEdit = formEdit.getFieldsValue();
    if (!value || value.length === 0) {
      return Promise.reject(new Error('Please upload a file!'));
    }
    if (!isFileValid) {
      return Promise.reject(new Error(`File harus lebih kecil dari 600KB!`));
    }
    if (valuesEdit.image_role && !isFileValid) {
      return Promise.reject(new Error(`File harus lebih kecil dari 600KB!`));
    }
    if (valuesEdit.image_role && isFileValid) {
      return Promise.resolve();
    }
    return Promise.resolve();
  };
  return (
    <Layout>
      {contextHolder}
      <Breadcrumb
        items={[
          {
            href: '/dashboard/',
            title: (
              <>
                <HomeOutlined />
                <span>Dashboard</span>
              </>
            ),
          },
          {
            title: "List Line Up",
          },
        ]}
      />
      <Spin spinning={loadingDeleteItem} tip="Loading...">
        <Flex vertical>
          <Space className='dashboard-add-item'>
            <Button className='dashboard-add-item-button' type="primary" onClick={showDrawerAdd} icon={<PlusOutlined />}>Tambah Line Up</Button>  
            <Drawer
              className='dashboard-add-item-drawer'
              title="Tambah Line Up"
              width={720}
              closable={false}
              visible={openAddItem}
            >
              <Spin spinning={loadingAddItem} tip="Loading...">
                <Form layout="vertical" form={formAdd} className='dashboard-add-item-drawer-form' onFinish={onFinishAdd}>
                  <Row gutter={16}>
                    <Col xs={24} sm={16} lg={10}>
                      <Form.Item
                        name="nama_player"
                        label="Nama Player"
                        rules={[
                          {
                            required: true,
                            message: 'Silahkan Masukkan Nama Player',
                          },
                        ]}
                      >
                        <Input placeholder="Masukan Nama Player" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={16} lg={10}>
                      <Form.Item
                        name="image_player"
                        label="Image Player"
                        rules={[
                          {
                            validator: fileValidatorImagePlayer,
                          },
                        ]}
                      >
                        <Space className='upload-images'>
                          <Upload
                            customRequest={() => {}}
                            fileList={[]}
                            beforeUpload={beforeAddUploadItemPlayer}
                            onRemove={() => setBase64ImageAddPlayer('')}
                          >
                            <Button icon={<UploadOutlined />}>Select Image</Button>
                          </Upload>
                          {fileNameAddUploadItemPlayer}
                          {base64ImageAddPlayer && (
                            <Text>
                              Image Dimensions (Player): {imageDimensionsAddPlayer.width} x {imageDimensionsAddPlayer.height}
                            </Text>
                          )}
                        </Space>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={16} lg={10}>
                      <Form.Item
                        name="id_master_season"
                        label="Nama Season"
                        rules={[
                          {
                            required: true,
                            message: 'Silahkan Masukkan Nama Season',
                          },
                        ]}
                      >
                        <Select
                          showSearch 
                          optionFilterProp="children" 
                          filterOption={
                            (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder="Pilih Nama Season"
                          onChange={handleAddMasterSeason}
                        >
                          {dataSeason ? dataSeason.map((item) => (
                            <Option key={item.id_master_season} value={item.id_master_season}>
                              {item.nama_master_season}
                            </Option>
                          )) : null}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  {
                    selectedMasterSeason ? 
                      <Row gutter={16}>
                        <Col xs={24} sm={16} lg={10}>
                          <Form.Item
                            name="id_team"
                            label="Nama Team"
                            rules={[
                              {
                                required: true,
                                message: 'Silahkan Masukkan Nama Team',
                              },
                            ]}
                          >
                            <Select
                              showSearch 
                              optionFilterProp="children" 
                              filterOption={
                                (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              placeholder="Pilih Team"
                            >
                              {dataTeam ? dataTeam.map((item) => (
                                <Option key={item.id_team} value={item.id_team}>
                                  {item.nama_team}
                                </Option>
                              )) : null}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    : null
                  }
                  <Row gutter={16}>
                    <Col xs={24} sm={16} lg={10}>
                      <Form.Item
                        name="role"
                        label="Role"
                        rules={[
                          {
                            required: true,
                            message: 'Silahkan Masukkan Role',
                          },
                        ]}
                      >
                        <Input placeholder="Masukan Role" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={16} lg={10}>
                      <Form.Item
                        name="image_role"
                        label="Image Role"
                        rules={[
                          {
                            validator: fileValidatorRole,
                          },
                        ]}
                      >
                        <Space className='upload-images'>
                          <Upload
                            customRequest={() => {}}
                            fileList={[]}
                            beforeUpload={beforeAddUploadItemRole}
                            onRemove={() => setBase64ImageAddRole('')}
                          >
                            <Button icon={<UploadOutlined />}>Select Image</Button>
                          </Upload>
                          {fileNameAddUploadItemRole}
                          {base64ImageAddRole && (
                            <Text>
                              Image Dimensions (Role): {imageDimensionsAddRole.width} x {imageDimensionsAddRole.height}
                            </Text>
                          )}
                        </Space>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={16} lg={10}>
                      <Form.Item
                        name="url1"
                        label="Url Tiktok"
                        rules={[
                          {
                            required: true,
                            message: 'Silahkan Masukkan Url Tiktok',
                          },
                        ]}
                      >
                        <Input placeholder="Masukan Url Tiktok" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={16} lg={10}>
                      <Form.Item
                        name="url2"
                        label="Url Profile"
                        rules={[
                          {
                            required: true,
                            message: 'Silahkan Masukkan Url Profile',
                          },
                        ]}
                      >
                        <Input placeholder="Masukan Url Profile" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={16} lg={10}>
                      <Form.Item
                        name="isCaptain"
                        label="Apakah Kapten ?"
                        rules={[
                          {
                            required: true,
                            message: 'Silahkan Pilih Kapten',
                          },
                        ]}
                      >
                        <Select 
                          showSearch 
                          optionFilterProp="children" 
                          filterOption={
                            (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder="Pilih Kapten"
                        >
                          <Option key="0" value="true">
                            Ya
                          </Option>
                          <Option key="1" value="false">
                            Tidak
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Space className='dashboard-add-item-drawer-form-button'>
                        <Button onClick={onCloseAdd}>Batal</Button>
                        <Button  type="primary" htmlType='submit'>Tambah</Button>
                      </Space>
                    </Col>
                  </Row>
                </Form>
              </Spin>
            </Drawer>
          </Space>
          <Input
            placeholder="Search"
            onChange={(e) => handleSearch(e.target.value)}
            value={searchText}
            style={{marginBottom: '20px', width: '50%'}}
          />
        </Flex>
        <Table
          bordered={true}
          dataSource={searchText ? filteredDataSource : dataSourceListLineUp}
          columns={columns}
          rowKey="key"
          scroll={{
            x: 1000,
          }}
          pagination={{ pageSize: 20 }}
          onChange={handleTableChange}
        />
        <Drawer
          width={720}
          title="Edit Team"
          closable={false}
          visible={openEditItem}
        >
          <Spin spinning={loadingEditItem} tip="Loading...">
            <Form layout="vertical" form={formEdit} onFinish={onFinishEdit} className='dashboard-edit-item-drawer-form'>
              <Row gutter={16}>
                <Col xs={24} sm={16} lg={10}>
                  <Form.Item
                    name="nama_player"
                    label="Nama Player"
                    rules={[
                      {
                        required: true,
                        message: 'Silahkan Masukkan Nama Player',
                      },
                    ]}
                  >
                    <Input placeholder="Masukan Nama Player" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={16} lg={10}>
                  <Form.Item
                    name="image_player"
                    label="Image Player"
                    rules={[
                      {
                        validator: fileValidatorImagePlayer,
                      },
                    ]}
                  >
                    {
                      base64ImageEditPlayer ? 
                      null : 
                        <Space className='preview-image'>
                          <Text>Preview Image</Text>
                          <Image
                            width={200}
                            src={editImagePlayer}
                          />
                        </Space>
                    }
                    <Space className='upload-images'>
                      <Upload
                        customRequest={() => {}}
                        fileList={[]}
                        beforeUpload={beforeEditUploadItemPlayer}
                        onRemove={() => setBase64ImageEditPlayer('')}
                      >
                        <Button icon={<UploadOutlined />}>Select Image</Button>
                      </Upload>
                      {fileNameEditUploadItemPlayer}
                      {base64ImageEditPlayer && (
                        <Text>
                          Image Dimensions (Player): {imageDimensionsEditPlayer.width} x {imageDimensionsEditPlayer.height}
                        </Text>
                      )}
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={16} lg={10}>
                  <Form.Item
                    name="id_master_season"
                    label="Nama Season"
                    rules={[
                      {
                        required: true,
                        message: 'Silahkan Masukkan Nama Season',
                      },
                    ]}
                  >
                    <Select
                      showSearch 
                      optionFilterProp="children" 
                      filterOption={
                        (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="Pilih Nama Season"
                      onChange={handleEditMasterSeason}
                      
                    >
                      {dataSeason ? dataSeason.map((item) => (
                        <Option key={item.id_master_season} value={item.id_master_season}>
                          {item.nama_master_season}
                        </Option>
                      )) : null}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={16} lg={10}>
                  <Form.Item
                    name="id_team"
                    label="Nama Team"
                    rules={[
                      {
                        required: true,
                        message: 'Silahkan Masukkan Nama Team',
                      },
                    ]}
                  >
                    <Select
                      showSearch 
                      optionFilterProp="children" 
                      filterOption={
                        (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="Pilih Team"
                    >
                      {dataTeam ? dataTeam.map((item) => (
                        <Option key={item.id_team} value={item.id_team}>
                          {item.nama_team}
                        </Option>
                      )) : null}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={16} lg={10}>
                  <Form.Item
                    name="role"
                    label="Role"
                    rules={[
                      {
                        required: true,
                        message: 'Silahkan Masukkan Role',
                      },
                    ]}
                  >
                    <Input placeholder="Masukan Role" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={16} lg={10}>
                  <Form.Item
                    name="image_role"
                    label="Image Role"
                    rules={[
                      {
                        validator: fileValidatorRole,
                      },
                    ]}
                  >
                    {
                      base64ImageEditRole ? 
                      null : 
                        <Space className='preview-image'>
                          <Text>Preview Image</Text>
                          <Image
                            width={200}
                            src={editImageRole}
                          />
                        </Space>
                    }
                    <Space className='upload-images'>
                      <Upload
                        customRequest={() => {}}
                        fileList={[]}
                        beforeUpload={beforeEditUploadItemRole}
                        onRemove={() => setBase64ImageEditRole('')}
                      >
                        <Button icon={<UploadOutlined />}>Select Image</Button>
                      </Upload>
                      {fileNameEditUploadItemRole}
                      {base64ImageEditRole && (
                        <Text>
                          Image Dimensions (Role): {imageDimensionsEditRole.width} x {imageDimensionsEditRole.height}
                        </Text>
                      )}
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                    <Col xs={24} sm={16} lg={10}>
                      <Form.Item
                        name="url1"
                        label="Url Tiktok"
                        rules={[
                          {
                            required: true,
                            message: 'Silahkan Masukkan Url Tiktok',
                          },
                        ]}
                      >
                        <Input placeholder="Masukan Url Tiktok" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={16} lg={10}>
                      <Form.Item
                        name="url2"
                        label="Url Profile"
                        rules={[
                          {
                            required: true,
                            message: 'Silahkan Masukkan Url Profile',
                          },
                        ]}
                      >
                        <Input placeholder="Masukan Url Profile" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={16} lg={10}>
                      <Form.Item
                        name="isCaptain"
                        label="Apakah Kapten ?"
                        rules={[
                          {
                            required: true,
                            message: 'Silahkan Pilih',
                          },
                        ]}
                      >
                        <Select 
                          showSearch 
                          optionFilterProp="children" 
                          filterOption={
                            (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder="Pilih Flag Live"
                        >
                          <Option key="0" value="true">
                            Ya
                          </Option>
                          <Option key="1" value="false">
                            Tidak
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Space className='dashboard-edit-item-drawer-form-button'>
                    <Button onClick={onCloseEdit}>Batal</Button>
                    <Button  type="primary" htmlType='submit'>Update</Button>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Drawer>
      </Spin>
    </Layout>
  )
};

export default DashboardListLineUp;
