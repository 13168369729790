import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Constant from "../../../config/constans";
import { PlusOutlined, UploadOutlined, HomeOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Breadcrumb,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Layout,
  Typography,
  Table,
  Upload,
  Spin,
  Image,
  message,
  Popconfirm,
  Flex,
  DatePicker,
  Modal,
} from "antd";
import SunEditor from "suneditor-react";
import dayjs from "dayjs";
import { values } from "lodash";
const { Text } = Typography;
const { Option } = Select;

const DashboardListTournament = () => {
  const [openAddItem, setOpenAddItem] = useState(false);
  const [openEditItem, setOpenEditItem] = useState(false);
  const [data, setData] = useState([]);
  const [dataKategoriGame, setDataKategoriGame] = useState([]);
  const [dataSubKategoriGame, setDataSubKategoriGame] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [loadDataBracket, setLoadDataBracket] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedKategoriGame, setSelectedKategoriGame] = useState(false);
  const [loadingAddItem, setLoadingAddItem] = useState(false);
  const [loadingEditItem, setLoadingEditItem] = useState(false);
  const [loadingDeleteItem, setLoadingDeleteItem] = useState(false);
  const [base64ImageEdit, setBase64ImageEdit] = useState("");
  const [base64ImageAdd, setBase64ImageAdd] = useState("");
  const [fileNameEditUploadItem, setFileNameEditUploadItem] = useState("");
  const [fileNameAddUploadItem, setFileNameAddUploadItem] = useState("");
  const [urlBracketInit, setUrlBracketInit] = useState("");
  const [description, setDescription] = useState("");
  const [imageDimensionsAddTeam, setImageDimensionsAddTeam] = useState({
    width: 0,
    height: 0,
  });
  const [imageDimensionsEditTeam, setImageDimensionsEditTeam] = useState({
    width: 0,
    height: 0,
  });
  const [informationFile, setInformationFile] = useState(null);
  const [editImage, setEditImage] = useState("");
  const [sortOrder, setSortOrder] = useState({
    columnKey: null,
    order: null,
  });
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const authToken = localStorage.getItem("token");
  const [formAdd] = Form.useForm();
  const [formEdit] = Form.useForm();
  const MAX_FILE_SIZE = 0.6;

  const filterBracketFilter = (record) => {
    let data =
      loadDataBracket &&
      loadDataBracket?.filter((e) => {
        return record?.id_bracket == e?.id_bracket;
      });
    return <>{data[0]?.nama_bracket}</>;
  };
  const filterStatusFilter = (record) => {
    let data = statusTournaments?.filter((e) => {
      return record.status == e.value;
    });
    return <>{data[0]?.label}</>;
  };

  const filterKategoriGameFilter = (record) => {
    let data = dataKategoriGame.filter((e) => {
      return record.id_kategori_game == e.id_kategori_game;
    });
    return <>{data[0]?.nama_kategori_game}</>;
  };

  const statusTournaments = [
    {
      label: "Active Dan Daftar",
      value: "1",
    },
    {
      label: "Active Dan Full",
      value: "2",
    },
    {
      label: "Inactive",
      value: "3",
    },
  ];

  const columns = [
    {
      title: "Nama Tournament",
      dataIndex: "nama_tournament",
      key: "nama_tournament",
      width: "20%",
      sorter: (a, b) => a.nama_tournament.localeCompare(b.nama_tournament), //tambah
      sortOrder: sortOrder.columnKey === "nama_tournament" && sortOrder.order, //tambah
    },

    {
      title: "Kategori Game",
      dataIndex: "id_kategori_game",
      key: "id_kategori_game",
      width: "20%",
      sorter: (a, b) => a.id_kategori_game.localeCompare(b.id_kategori_game), //tambah
      sortOrder: sortOrder.columnKey === "id_kategori_game" && sortOrder.order, //tambah
      render: (_, record) => <>{filterKategoriGameFilter(record)}</>,
    },
    {
      title: "Bracket Game",
      dataIndex: "id_bracket",
      key: "id_bracket",
      width: "20%",
      sorter: (a, b) => a.id_bracket.localeCompare(b.id_bracket), //tambah
      sortOrder: sortOrder.columnKey === "id_bracket" && sortOrder.order, //tambah
      render: (_, record) => <>{filterBracketFilter(record)}</>,
    },
    {
      title: "Bracket URL",
      dataIndex: "bracket_url_initial",
      key: "bracket_url_initial",
      width: "20%",
      sorter: (a, b) =>
        a.bracket_url_initial.localeCompare(b.bracket_url_initial), //tambah
      sortOrder:
        sortOrder.columnKey === "bracket_url_initial" && sortOrder.order, //tambah
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "20%",
      sorter: (a, b) => a.description.localeCompare(b.description), //tambah
      sortOrder: sortOrder.columnKey === "description" && sortOrder.order, //tambah
    },
    {
      title: "Registration fee",
      dataIndex: "registration_fee",
      key: "registration_fee",
      width: "20%",
      sorter: (a, b) => a.registration_fee.localeCompare(b.registration_fee), //tambah
      sortOrder: sortOrder.columnKey === "registration_fee" && sortOrder.order, //tambah
    },
    {
      title: "status",
      dataIndex: "status",
      key: "status",
      width: "20%",
      sorter: (a, b) => a.status.localeCompare(b.status), //tambah
      sortOrder: sortOrder.columnKey === "status" && sortOrder.order, //tambah
      render: (_, record) => <>{filterStatusFilter(record)}</>,
    },
    {
      title: "Juara 1",
      dataIndex: "champions_nama",
      key: "champions_nama",
      width: "20%",
      sorter: (a, b) => a.champions_nama.localeCompare(b.champions_nama), //tambah
      sortOrder: sortOrder.columnKey === "champions_nama" && sortOrder.order, //tambah
    },
    {
      title: "Juara 2",
      dataIndex: "champions_nama_i",
      key: "champions_nama_i",
      width: "20%",
      sorter: (a, b) => a.champions_nama_i.localeCompare(b.champions_nama_i), //tambah
      sortOrder: sortOrder.columnKey === "champions_nama_i" && sortOrder.order, //tambah
    },
    {
      title: "Juara 3",
      dataIndex: "champions_nama_ii",
      key: "champions_nama_ii",
      width: "20%",
      sorter: (a, b) => a.champions_nama_ii.localeCompare(b.champions_nama_ii), //tambah
      sortOrder: sortOrder.columnKey === "champions_nama_ii" && sortOrder.order, //tambah
    },
    {
      title: "Total Price",
      dataIndex: "total_price",
      key: "total_price",
      width: "20%",
      sorter: (a, b) => a.total_price.localeCompare(b.total_price), //tambah
      sortOrder: sortOrder.columnKey === "total_price" && sortOrder.order, //tambah
    },
    {
      title: "Banner",
      dataIndex: "banner",
      key: "banner",
      // width: "18%",
      render: (_, record) => (
        <Image
          src={record?.banner}
          style={{ width: 60, height: 60, borderRadius: 100 }}
        />
      ),
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      width: "20%",
      sorter: (a, b) => moment(a.start_date) - moment(b.start_date), //tambah
      sortOrder: sortOrder.columnKey === "start_date" && sortOrder.order, //tambah
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      width: "20%",
      sorter: (a, b) => moment(a.end_date) - moment(b.end_date), //tambah
      sortOrder: sortOrder.columnKey === "end_date" && sortOrder.order, //tambah
    },
    {
      title: "Type Bracket",
      dataIndex: "isbracket",
      key: "isbracket",
      width: "20%",
      sorter: (a, b) => moment(a.isbracket) - moment(b.isbracket), //tambah
      sortOrder: sortOrder.columnKey === "isbracket" && sortOrder.order, //tambah
      render: (_, record) => (
        <>{record?.isbracket ? "Bracket" : "Non Bracket"}</>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdat",
      key: "createDat",
      width: "20%",
      sorter: (a, b) => moment(a.createdat) - moment(b.createdat), //tambah
      sortOrder: sortOrder.columnKey === "createdat" && sortOrder.order, //tambah
    },
    {
      title: "Updated Date",
      dataIndex: "udpatedat",
      key: "udpatedat",
      width: "20%",
      sorter: (a, b) => moment(a.udpatedat) - moment(b.udpatedat), //tambah
      sortOrder: sortOrder.columnKey === "udpatedat" && sortOrder.order, //tambah
    },
    {
      title: "Actions",
      key: "actions",
      width: "20%",
      render: (_, record) => (
        <Flex gap={10}>
          <Button type="primary" onClick={() => showModal(record)}>
            Detail
          </Button>
          <Button onClick={() => showDrawerEdit(record)}>Edit</Button>
          <Popconfirm
            title="Delete Tournament"
            description="Apakah yakin ingin menghapus Tournament?"
            onConfirm={() => handleDelete(record)}
            okText="Hapus"
            cancelText="Tidak"
          >
            <Button danger>Delete</Button>
          </Popconfirm>
        </Flex>
      ),
    },
  ];

  const showModal = (data) => {
    setOpen(true);
    setDataModal(data);
    setChampions(data?.champions);
    setChampionsI(data?.championsI);
    setChampionsII(data?.championsII);
  };

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const [dataListTeam, setdataListTeam] = useState(false);
  const [champions, setChampions] = useState("");
  const [championsI, setChampionsI] = useState("");
  const [championsII, setChampionsII] = useState("");

  const getListTeam = async () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_DETAIL_TOURNAMENT;
    await axios
      .post(
        URL,
        {
          id_tournament: dataModal?.id_tournament,
        },
        { headers },
      )
      .then((response) => {
        if (response.status === 200) {
          console.log("ressss", response);

          setdataListTeam(response.data.Data[0]?.list_peserta);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          localStorage.removeItem("token");
          localStorage.removeItem("id_admin");
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };

  useEffect(() => {
    if (dataModal) {
      getListTeam();
    }
  }, [dataModal]);

  useEffect(() => {
    getListTournament();
    getListKategoriGame();
    getListSubKategoriGame();
    getListBracket();
  }, [loadData]);

  const getListBracket = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_MASTER_BRACKET;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          setLoadDataBracket(false);
          setLoadDataBracket(response.data.Data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          localStorage.removeItem("token");
          localStorage.removeItem("id_admin");
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
        }
      });
  };

  const getListTournament = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_MASTER_ROOM_TOURNAMENT;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          setLoadData(false);
          setData(response.data.Data);
          setBase64ImageEdit("");
          setBase64ImageAdd("");
          setFileNameAddUploadItem("");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          localStorage.removeItem("token");
          localStorage.removeItem("id_admin");
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };
  const getListKategoriGame = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_KATEGORI_GAME;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          setDataKategoriGame(response.data.Data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };
  const getListSubKategoriGame = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_SUB_KATEGORI_GAME;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          setDataSubKategoriGame(response.data.Data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };
  const addListTournament = (payload) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH +
      Constant.URL_POST_ADD_LISTS_MASTER_ROOM_TOURNAMENT;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setSearchText(""); //tambah
            setLoadData(true);
            setLoadingAddItem(false);
            setOpenAddItem(false);
            setDescription("");
            setUrlBracketInit("");
            messageApi.open({
              type: "success",
              content: "Success Add Tournament",
              duration: 4,
            });
            formAdd.resetFields();
          }, 1000);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
            setLoadingAddItem(false);
            setOpenAddItem(false);
            // formAdd.resetFields();
          }, 1000);
        }
      });
  };

  const addChampions = async () => {
    setConfirmLoading(true);
    let payload = {
      id_tournament: dataModal?.id_tournament,
      champions: champions,
      championsI: championsI,
      championsII: championsII,
    };
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH +
      Constant.URL_POST_UPDATE_LISTS_CHAMPIONS_ROOM_TOURNAMENT;
    await axios
      .post(URL, payload, { headers })
      .then((response) => {
        setConfirmLoading(false);
        setDataModal({});
        setOpen(false);
        setChampions("");
        setChampionsI("");
        setChampionsII("");
        if (response.status === 200) {
          setTimeout(() => {
            messageApi.open({
              type: "success",
              content: "Success Add Juara",
              duration: 4,
            });
          }, 1000);
          window.location.reload();
        }
      })
      .catch((error) => {
        setConfirmLoading(false);

        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
            setLoadingAddItem(false);
            setOpenAddItem(false);
            // formAdd.resetFields();
          }, 1000);
        }
      });
  };
  const editListTournament = (payload) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH +
      Constant.URL_POST_EDIT_LISTS_MASTER_ROOM_TOURNAMENT;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setSearchText(""); //tambah
            setLoadData(true);
            setLoadingEditItem(false);
            setOpenEditItem(false);
            setDescription("");
            setUrlBracketInit();

            messageApi.open({
              type: "success",
              content: "Success Edit Tournament",
              duration: 4,
            });
            formAdd.resetFields();
          }, 1000);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
            setLoadingEditItem(false);
            setOpenEditItem(false);
            formAdd.resetFields();
          }, 1000);
        }
      });
  };
  const deleteListTeam = (payload) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH +
      Constant.URL_POST_DELETE_LISTS_MASTER_ROOM_TOURNAMENT;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setSearchText(""); //tambah
            setLoadData(true);
            setLoadingDeleteItem(false);
            messageApi.open({
              type: "success",
              content: "Success Delete Tournament",
              duration: 4,
            });
          }, 1000);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            setLoadingDeleteItem(false);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };
  const getListSubKategoriGameByKategori = (payloadId) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const payload = {
      id_kategori_game: payloadId,
    };
    const URL =
      Constant.URL_MASTER_PATH +
      Constant.URL_POST_LISTS_SUB_KATEGORI_GAME_BY_KATEGORI;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          setDataSubKategoriGame(response.data.Data);
          setSelectedKategoriGame(true);
          setLoadingAddItem(false);
          setLoadingEditItem(false);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };

  const dataSourceListTeam = data
    ? data.map((item, index) => {
        const createdat = moment(item.createdat).format("DD MMMM YYYY");
        const updatedat = moment(item.udpatedat).format("DD MMMM YYYY");
        const startDate = moment(item.start_date).format("DD MMMM YYYY HH:mm");
        const endDate = moment(item.end_date).format("DD MMMM YYYY HH:mm");
        return {
          key: index,
          id_tournament: item?.id_tournament,
          nama_tournament: item.nama_tournament,
          description: item.description,
          registration_fee: item.registration_fee,
          total_price: item.total_price,
          start_date: startDate,
          end_date: endDate,
          banner: item.banner,
          status: item.status,
          champions: item.champions,
          championsI: item.championsI,
          championsII: item.championsII,
          champions_nama: item.champions_nama,
          champions_nama_i: item.champions_nama_i,
          champions_nama_ii: item.champions_nama_ii,
          id_kategori_game: item.id_kategori_game,
          id_bracket: item.id_bracket,
          isbracket: item.isbracket,
          bracket_url_initial: item.bracket_url_initial,
          createdat: createdat,
          udpatedat: updatedat,
        };
      })
    : [];
  const handleDelete = (record) => {
    setLoadingDeleteItem(true);
    const valueId = {
      id_tournament: record.id_tournament,
    };
    deleteListTeam(valueId);
  };
  const showDrawerEdit = (record) => {
    // getListSubKategoriGameByKategori(record.id_kategori_game);
    setSelectedRowKey(record.key);
    setOpenEditItem(true);
    setEditImage(record.banner);
    setUrlBracketInit(
      record?.bracket_url_initial?.split(" ").join("_").toLowerCase(),
    );
    setDescription(record.description);
    console.log("asdfasdf", dayjs(record.start_date));
    console.log(
      "asdfasdf",
      moment(record.start_date).format("DD MMMM YYYY HH:mm"),
    );

    formAdd.setFieldsValue({
      id_tournament: record?.id_tournament,
      nama_tournament: record.nama_tournament,
      description: record.description,
      registration_fee: record.registration_fee,
      total_price: record.total_price,
      start_date: dayjs(record.start_date),
      end_date: dayjs(record.end_date),
      banner: record.banner,
      status: record.status,
      champions: record.champions,
      championsI: record.championsI,
      championsII: record.championsII,
      champions_nama: record.champions_nama,
      champions_nama_i: record.champions_nama_i,
      champions_nama_ii: record.champions_nama_ii,
      id_kategori_game: record.id_kategori_game,
      id_bracket: record.id_bracket,
      isbracket: record.isbracket,
      bracket_url_initial: record.bracket_url_initial,
      createdat: record.createdat,
      udpatedat: record.updatedat,
    });
  };
  const showDrawerAdd = () => {
    setOpenAddItem(true);
  };
  const onCloseAdd = () => {
    setInformationFile("");
    setBase64ImageAdd("");
    setFileNameAddUploadItem("");
    formAdd.resetFields();
    setOpenAddItem(false);
    setSelectedKategoriGame(false);
    setLoadData(true);
  };
  const onCloseEdit = () => {
    setInformationFile("");
    setBase64ImageEdit("");
    setFileNameEditUploadItem("");
    formAdd.resetFields();
    setOpenEditItem(false);
    setSelectedKategoriGame(false);
    setLoadData(true);
  };
  const onFinishAdd = () => {
    setLoadingAddItem(true);
    setSelectedKategoriGame(false);
    const values = formAdd.getFieldsValue();
    const regexUpload = /^data:image\/(\w+);base64,/;
    if (base64ImageAdd) {
      const matchUpload = base64ImageAdd.match(regexUpload);
      const imageWithoutPrefix = base64ImageAdd.substring(
        matchUpload[0].length,
      );
      values.banner = imageWithoutPrefix;
    }
    values.bracket_url_initial = urlBracketInit;
    setInformationFile("");
    addListTournament(values);
  };
  const onFinishEdit = () => {
    setLoadingEditItem(true);
    setSelectedKategoriGame(false);
    const values = formAdd.getFieldsValue();
    const regexUpload = /^data:image\/(\w+);base64,/;
    console.log("adfdfsad", base64ImageEdit);
    if (base64ImageEdit) {
      const matchUpload = base64ImageEdit.match(regexUpload);
      const imageWithoutPrefix = base64ImageEdit.substring(
        matchUpload[0].length,
      );
      values.banner = imageWithoutPrefix;
    } else {
      values.banner = "-";
    }
    values.id_tournament = data[selectedRowKey].id_tournament;
    values.bracket_url_initial = urlBracketInit;

    setInformationFile("");
    editListTournament(values);
  };
  const beforeAddUploadItem = (file) => {
    setInformationFile(file);
    const isLt600KB = file.size / 1024 / 1024 < MAX_FILE_SIZE;
    if (!isLt600KB) {
      message.error(`File harus lebih kecil dari 600KB!`);
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setBase64ImageAdd(reader.result);
      getImageDimensions(reader.result, setImageDimensionsAddTeam);
    };

    const fileName = file.name;
    setFileNameAddUploadItem(fileName);

    return false;
  };
  const beforeEditUploadItem = (file) => {
    setInformationFile(file);
    const isLt600KB = file.size / 1024 / 1024 < MAX_FILE_SIZE;
    if (!isLt600KB) {
      message.error(`File harus lebih kecil dari 600KB!`);
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setBase64ImageEdit(reader.result);
      getImageDimensions(reader.result, setImageDimensionsEditTeam);
    };

    const fileName = file.name;
    setFileNameEditUploadItem(fileName);

    return false;
  };
  const handleAddKategoriGameChange = (value) => {
    setLoadingAddItem(true);
    getListSubKategoriGameByKategori(value);
  };
  const handleEditKategoriGameChange = (value) => {
    setLoadingEditItem(true);
    getListSubKategoriGameByKategori(value);
    formAdd.resetFields(["id_sub_kategori_game"]);
  };
  const handleSearch = (value) => {
    setSearchText(value);

    const filteredData = dataSourceListTeam.filter((record) => {
      return Object.values(record).some(
        (fieldValue) =>
          fieldValue &&
          fieldValue.toString().toLowerCase().includes(value.toLowerCase()),
      );
    });

    setFilteredDataSource(filteredData);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    //tambah
    setSortOrder({
      columnKey: sorter.columnKey,
      order: sorter.order,
    });
  };
  const getImageDimensions = (url, setImageDimensions) => {
    const img = new window.Image();
    img.src = url;
    img.onload = () => {
      setImageDimensions({ width: img.width, height: img.height });
    };
  };
  const checkFileSize = (file) => {
    if (!file || !file.size) {
      return true;
    }
    const isLt600KB = file.size / 1024 / 1024 < MAX_FILE_SIZE;
    return isLt600KB;
  };
  const fileValidator = (_, value) => {
    const isFileValid = checkFileSize(informationFile);
    const valuesEdit = formAdd.getFieldsValue();
    if (!value || value.length === 0) {
      return Promise.reject(new Error("Please upload a file!"));
    }
    if (!isFileValid) {
      return Promise.reject(new Error(`File harus lebih kecil dari 600KB!`));
    }
    if (valuesEdit.banner && !isFileValid) {
      return Promise.reject(new Error(`File harus lebih kecil dari 600KB!`));
    }
    if (valuesEdit.banner && isFileValid) {
      return Promise.resolve();
    }
    return Promise.resolve();
  };
  return (
    <Layout>
      {contextHolder}
      <Breadcrumb
        items={[
          {
            href: "/dashboard/",
            title: (
              <>
                <HomeOutlined />
                <span>Dashboard</span>
              </>
            ),
          },
          {
            title: "List Tournament",
          },
        ]}
      />
      <Spin
        spinning={openEditItem ? loadingEditItem : loadingAddItem}
        tip="Loading..."
      >
        <Form
          layout="vertical"
          form={formAdd}
          className="dashboard-add-item-drawer-form"
          onFinish={openEditItem ? onFinishEdit : onFinishAdd}
        >
          <Row gutter={16}>
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="nama_tournament"
                label="Nama Tournament"
                rules={[
                  {
                    required: true,
                    message: "Silahkan Masukkan Nama Tournament",
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    setUrlBracketInit(
                      e.target.value?.split(" ").join("_").toLowerCase(),
                    );
                  }}
                  placeholder="Masukan Nama Tournament"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="status"
                label="Status Tournament"
                rules={[
                  {
                    required: true,
                    message: "Silahkan Masukkan Status Tournament",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Pilih Status Tournament"
                >
                  {statusTournaments
                    ? statusTournaments?.map((item) => (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {/* ROW 2 */}
          <Row gutter={16}>
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="registration_fee"
                label="Biaya Registrasi"
                rules={[
                  {
                    required: true,
                    message: "Silahkan Masukkan Biaya Registrasi",
                  },
                ]}
              >
                <Input placeholder="Masukan Biaya Registrasi" type="number" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="id_kategori_game"
                label="Nama Kategori Game"
                rules={[
                  {
                    required: true,
                    message: "Silahkan Masukkan Nama Kategori Game",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Pilih Kategori Game"
                >
                  {dataKategoriGame
                    ? dataKategoriGame?.map((item) => (
                        <Option
                          key={item.id_kategori_game}
                          value={item.id_kategori_game}
                        >
                          {item.nama_kategori_game}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="total_price"
                label="Total Hadiah"
                rules={[
                  {
                    required: true,
                    message: "Silahkan Masukkan Total Hadiah",
                  },
                ]}
              >
                <Input placeholder="Masukan Total Hadiah" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="id_bracket"
                label="Bracket Game"
                rules={[
                  {
                    required: true,
                    message: "Silahkan Masukkan Bracket Game",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Pilih Kategori Game"
                  onChange={handleAddKategoriGameChange}
                >
                  {loadDataBracket
                    ? loadDataBracket?.map((item) => (
                        <Option key={item.id_bracket} value={item.id_bracket}>
                          {item.nama_bracket}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="start_date"
                label="Tanggal Mulai"
                rules={[
                  {
                    required: true,
                    message: "Silahkan Masukkan Tanggal Mulai",
                  },
                ]}
              >
                <DatePicker showTime />
              </Form.Item>
            </Col>
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="banner"
                label="Upload Banner (1200 x 600)"
                rules={[
                  {
                    validator: fileValidator,
                  },
                ]}
              >
                <Space className="upload-images">
                  <Upload
                    customRequest={() => {}}
                    fileList={[]}
                    beforeUpload={
                      openEditItem ? beforeEditUploadItem : beforeAddUploadItem
                    }
                    onRemove={() =>
                      openEditItem
                        ? setBase64ImageEdit("")
                        : setBase64ImageAdd("")
                    }
                  >
                    <Button icon={<UploadOutlined />}>Select Image</Button>
                  </Upload>
                  {openEditItem
                    ? fileNameEditUploadItem
                    : fileNameAddUploadItem}
                  {base64ImageAdd && (
                    <Text>
                      Image Dimensions (Team): {imageDimensionsAddTeam.width} x{" "}
                      {imageDimensionsAddTeam.height}
                    </Text>
                  )}
                  {base64ImageEdit && (
                    <Text>
                      Image Dimensions (Team): {imageDimensionsEditTeam.width} x{" "}
                      {imageDimensionsEditTeam.height}
                    </Text>
                  )}
                </Space>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="end_date"
                label="Tanggal Selesai"
                rules={[
                  {
                    required: true,
                    message: "Silahkan Masukkan Tanggal Selesai",
                  },
                ]}
              >
                <DatePicker showTime />
              </Form.Item>
            </Col>
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="isbracket"
                label="Type Bracket"
                rules={[
                  {
                    required: true,
                    message: "Silahkan Masukkan Status Tournament",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Pilih Type Bracket"
                >
                  <Option key={true} value={true}>
                    Bracket
                  </Option>
                  <Option key={false} value={false}>
                    Non Bracket
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                // name="bracket_url_initial"
                label="URL Bracket Initial"
                rules={[
                  {
                    required: true,
                    message: "Silahkan Masukkan URL Challonge",
                  },
                ]}
              >
                <Input
                  style={{ backgroundColor: "white" }}
                  disabled
                  value={urlBracketInit}
                  placeholder="Silahkan Masukkan URL Challonge"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="description"
                label="Deskripsi Tournament"
                rules={[
                  {
                    required: true,
                    message: "Silahkan Masukkan Deskripsi Tournament",
                  },
                ]}
              >
                <SunEditor
                  setContents={description}
                  height="250px"
                  setOptions={{
                    buttonList: [
                      ["undo", "redo"],
                      [
                        "bold",
                        "underline",
                        "italic",
                        "strike",
                        "subscript",
                        "superscript",
                      ],
                      ["font", "fontSize", "formatBlock"],
                      ["fontColor", "hiliteColor", "textStyle"],
                      ["paragraphStyle", "removeFormat"],
                      ["outdent", "indent"],
                      [
                        "align",
                        "horizontalRule",
                        "list",
                        "lineHeight",
                        "blockquote",
                      ],
                      ["table", "image", "video"],
                      ["link"],
                      ["codeView", "fullScreen", "showBlocks"],
                    ],
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Space className="dashboard-add-item-drawer-form-button">
                <Button onClick={openEditItem ? onCloseEdit : onCloseAdd}>
                  Batal
                </Button>
                <Button type="primary" htmlType="submit">
                  {openEditItem ? "Edit" : "Tambah"}
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Spin>

      <Spin spinning={loadingDeleteItem} tip="Loading...">
        <Flex vertical>
          <Space />

          <Input
            placeholder="Search"
            onChange={(e) => handleSearch(e.target.value)}
            value={searchText}
            style={{ marginBottom: "20px", width: "50%", marginTop: 30 }}
          />
        </Flex>
        <Table
          bordered={true}
          dataSource={searchText ? filteredDataSource : dataSourceListTeam}
          columns={columns}
          rowKey="key"
          scroll={{
            x: 1600,
          }}
          pagination={{ pageSize: 20 }} //tambah
          onChange={handleTableChange} //tambah
        />
      </Spin>
      {console.log("modalll", dataModal)}
      <Modal
        title="Update Juara Tournament"
        open={open}
        onOk={addChampions}
        okText={"Simpan"}
        confirmLoading={confirmLoading}
        onCancel={() => setOpen(false)}
        width={"80%"}
      >
        <Row gutter={16}>
          <Col xs={24} sm={16} lg={8}>
            <Form.Item
              name="champions"
              label="Nama Juara 1"
              rules={[
                {
                  required: false,
                  message: "Silahkan Masukkan Nama Juara",
                },
              ]}
            >
              <Select
                defaultValue={champions}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children
                    ?.toLowerCase()
                    .indexOf(input?.toLowerCase()) >= 0
                }
                placeholder="Pilih Juara"
                onChange={(value, select) => {
                  setChampions(value);
                }}
              >
                {dataListTeam
                  ? dataListTeam?.map((item) => (
                      <Option key={item.id_team} value={item.id_team}>
                        {item.nama_team}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={16} lg={8}>
            <Form.Item
              name="championsI"
              label="Nama Juara 2"
              rules={[
                {
                  required: false,
                  message: "Silahkan Masukkan Nama Juara 2",
                },
              ]}
            >
              <Select
                defaultValue={championsI}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children
                    ?.toLowerCase()
                    .indexOf(input?.toLowerCase()) >= 0
                }
                placeholder="Pilih Juara"
                onChange={(value, select) => {
                  setChampionsI(value);
                }}
              >
                {dataListTeam
                  ? dataListTeam?.map((item) => (
                      <Option key={item.id_team} value={item.id_team}>
                        {item.nama_team}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={16} lg={8}>
            <Form.Item
              name="championsII"
              label="Nama Juara 3"
              rules={[
                {
                  required: false,
                  message: "Silahkan Masukkan Nama Juara 3",
                },
              ]}
            >
              <Select
                defaultValue={championsII}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children
                    ?.toLowerCase()
                    .indexOf(input?.toLowerCase()) >= 0
                }
                placeholder="Pilih Juara"
                onChange={(value, select) => {
                  setChampionsII(value);
                }}
              >
                {dataListTeam
                  ? dataListTeam?.map((item) => (
                      <Option key={item.id_team} value={item.id_team}>
                        {item.nama_team}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    </Layout>
  );
};

export default DashboardListTournament;
