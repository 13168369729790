import { HomeOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Flex,
  Form,
  Input,
  Layout,
  message,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Constant from "../../../config/constans";
const { Text } = Typography;
const { Option } = Select;

const DashboardMasterMap = () => {
  const [openAddItem, setOpenAddItem] = useState(false);
  const [openEditItem, setOpenEditItem] = useState(false);
  const [data, setData] = useState([]);
  const [dataKategoriGame, setDataKategoriGame] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedKategoriGame, setSelectedKategoriGame] = useState(false);
  const [loadingAddItem, setLoadingAddItem] = useState(false);
  const [loadingEditItem, setLoadingEditItem] = useState(false);
  const [loadingDeleteItem, setLoadingDeleteItem] = useState(false);
  const [dataSelectEdit, setDataSelectEdit] = useState({});
  const [sortOrder, setSortOrder] = useState({
    columnKey: null,
    order: null,
  });
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const authToken = localStorage.getItem("token");
  const [formAdd] = Form.useForm();
  const [formEdit] = Form.useForm();
  const MAX_FILE_SIZE = 0.6;

  const columns = [
    {
      title: "Kategori Game",
      dataIndex: "nama_kategori",
      key: "nama_kategori",
      width: "20%",
      sorter: (a, b) => a.nama_kategori.localeCompare(b.nama_kategori), //tambah
      sortOrder: sortOrder.columnKey === "nama_kategori" && sortOrder.order, //tambah
    },
    {
      title: "Nama Map",
      dataIndex: "nama_maps",
      key: "nama_maps",
      width: "20%",
      sorter: (a, b) => a.nama_maps.localeCompare(b.nama_maps), //tambah
      sortOrder: sortOrder.columnKey === "nama_maps" && sortOrder.order, //tambah
    },
    {
      title: "Created Date",
      dataIndex: "createdat",
      key: "createDat",
      width: "20%",
      sorter: (a, b) => moment(a.createdat) - moment(b.createdat), //tambah
      sortOrder: sortOrder.columnKey === "createdat" && sortOrder.order, //tambah
    },
    {
      title: "Updated Date",
      dataIndex: "udpatedat",
      key: "udpatedat",
      width: "20%",
      sorter: (a, b) => moment(a.udpatedat) - moment(b.udpatedat), //tambah
      sortOrder: sortOrder.columnKey === "udpatedat" && sortOrder.order, //tambah
    },
    {
      title: "Actions",
      key: "actions",
      width: "20%",
      render: (_, record) => (
        <span style={{ display: "flex" }}>
          <Button onClick={() => showDrawerEdit(record)}>Edit</Button>
          <Popconfirm
            title="Delete Member"
            description="Apakah yakin ingin menghapus Member?"
            onConfirm={() => handleDelete(record)}
            okText="Hapus"
            cancelText="Tidak"
          >
            <Button style={{ marginLeft: 8 }} danger>
              Delete
            </Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  useEffect(() => {
    getListMasterMaps();
    getListKategoriGame();
  }, [loadData]);

  const getListMasterMaps = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LIST_MASTER_MAP;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          setLoadData(false);
          setData(response.data.Data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          localStorage.removeItem("token");
          localStorage.removeItem("id_admin");
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };
  const getListKategoriGame = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_KATEGORI_GAME;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          setDataKategoriGame(response.data.Data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };

  const addListMaps = (payload) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_POST_LIST_ADD_MASTER_MAP;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setSearchText(""); //tambah
            setLoadData(true);
            setLoadingAddItem(false);
            setOpenAddItem(false);
            messageApi.open({
              type: "success",
              content: "Success Add Max Member",
              duration: 4,
            });
            formAdd.resetFields();
          }, 1000);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
            setLoadingAddItem(false);
            setOpenAddItem(false);
          }, 1000);
        }
      });
  };
  const editListMaps = (payload) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_POST_LIST_UPDATE_MASTER_MAP;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setSearchText(""); //tambah
            setLoadData(true);
            setLoadingEditItem(false);
            setOpenEditItem(false);
            messageApi.open({
              type: "success",
              content: "Success Edit Max Member",
              duration: 4,
            });
            formAdd.resetFields();
          }, 1000);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
            setLoadingEditItem(false);
            setOpenEditItem(false);
            formAdd.resetFields();
          }, 1000);
        }
      });
  };
  const deleteListMaps = (payload) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_POST_LIST_DELETE_MASTER_MAP;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setSearchText(""); //tambah
            setLoadData(true);
            setLoadingDeleteItem(false);
            messageApi.open({
              type: "success",
              content: "Success Delete Member",
              duration: 4,
            });
          }, 1000);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            setLoadingDeleteItem(false);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };

  const dataSourceListMaps = data
    ? data.map((item, index) => {
        const createdat = moment(item.createdat).format("DD MMMM YYYY");
        const updatedat = moment(item.udpatedat).format("DD MMMM YYYY");

        return {
          key: index,
          id_maps: item.id_maps,
          id_kategori_game: item.id_kategori_game,
          nama_kategori: item.nama_kategori,
          nama_maps: item.nama_maps,
          createdat: createdat,
          udpatedat: updatedat,
        };
      })
    : [];
  const handleDelete = (record) => {
    setLoadingDeleteItem(true);
    const valueId = {
      id_maps: record.id_maps,
    };
    deleteListMaps(valueId);
  };
  const showDrawerEdit = (record) => {
    setSelectedRowKey(record.key);
    setOpenEditItem(true);
    const values = formAdd.getFieldsValue();
    values.id_maps = record.id_maps;
    formAdd.setFieldsValue({
      id_maps: record.id_maps,
      id_kategori_game: record.id_kategori_game,
      nama_kategori: record.nama_kategori,
      nama_maps: record.nama_maps,
      createdat: record.createdat,
      udpatedat: record.updatedat,
    });
  };
  const showDrawerAdd = () => {
    setOpenAddItem(true);
  };
  const onCloseAdd = () => {
    formAdd.resetFields();
    setOpenAddItem(false);
    setSelectedKategoriGame(false);
    setLoadData(true);
  };
  const onCloseEdit = () => {
    formAdd.resetFields();
    setOpenEditItem(false);
    setSelectedKategoriGame(false);
    setLoadData(true);
  };
  const onFinishAdd = () => {
    setLoadingAddItem(true);
    setSelectedKategoriGame(false);
    const values = formAdd.getFieldsValue();

    addListMaps(values);
  };
  const onFinishEdit = () => {
    setLoadingEditItem(true);
    setSelectedKategoriGame(false);
    const values = formAdd.getFieldsValue();
    values.id_maps = data[selectedRowKey].id_maps;

    console.log("values", values);

    editListMaps(values);
  };

  const handleSearch = (value) => {
    setSearchText(value);

    const filteredData = dataSourceListMaps.filter((record) => {
      return Object.values(record).some(
        (fieldValue) =>
          fieldValue &&
          fieldValue.toString().toLowerCase().includes(value.toLowerCase()),
      );
    });

    setFilteredDataSource(filteredData);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    //tambah
    setSortOrder({
      columnKey: sorter.columnKey,
      order: sorter.order,
    });
  };

  return (
    <Layout>
      {contextHolder}
      <Breadcrumb
        items={[
          {
            href: "/dashboard/",
            title: (
              <>
                <HomeOutlined />
                <span>Dashboard</span>
              </>
            ),
          },
          {
            title: "List Master Map",
          },
        ]}
      />
      <Spin
        spinning={openEditItem ? loadingEditItem : loadingAddItem}
        tip="Loading..."
      >
        <Form
          layout="vertical"
          form={formAdd}
          className="dashboard-add-item-drawer-form"
          onFinish={openEditItem ? onFinishEdit : onFinishAdd}
        >
          <Row gutter={16}>
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="nama_maps"
                label="Nama Maps"
                rules={[
                  {
                    required: true,
                    message: "Silahkan Masukkan Nama Maps",
                  },
                ]}
              >
                <Input placeholder="Masukan Nama Maps" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="id_kategori_game"
                label="Kategori Game"
                rules={[
                  {
                    required: true,
                    message: "Silahkan Masukkan Kategori Game",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Pilih Kategori Game"
                >
                  {dataKategoriGame
                    ? dataKategoriGame.map((item) => (
                        <Option
                          key={item.id_kategori_game}
                          value={item.id_kategori_game}
                        >
                          {item.nama_kategori_game}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Space className="dashboard-add-item-drawer-form-button">
                <Button onClick={openEditItem ? onCloseEdit : onCloseAdd}>
                  Batal
                </Button>
                <Button type="primary" htmlType="submit">
                  {openEditItem ? "Edit" : "Tambah"}
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Spin>

      <Spin spinning={loadingDeleteItem} tip="Loading...">
        <Flex vertical>
          <Space />

          <Input
            placeholder="Search"
            onChange={(e) => handleSearch(e.target.value)}
            value={searchText}
            style={{ marginBottom: "20px", width: "50%", marginTop: 30 }}
          />
        </Flex>
        {console.log("dataSourceListMaps", dataSourceListMaps)}
        <Table
          bordered={true}
          dataSource={searchText ? filteredDataSource : dataSourceListMaps}
          columns={columns}
          rowKey="key"
          scroll={{
            x: 1600,
          }}
          pagination={{ pageSize: 20 }} //tambah
          onChange={handleTableChange} //tambah
        />
      </Spin>
    </Layout>
  );
};

export default DashboardMasterMap;
