import React, { useState, useEffect } from 'react';
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Constant from "../../../config/constans";
import { PlusOutlined, HomeOutlined } from '@ant-design/icons';
import { Button, Col, Breadcrumb, Drawer, Form, Input, Row, Select, Space, Layout, Table, Spin, message, Popconfirm, Flex } from 'antd';
const { Option } = Select;


const DashboardListSeason = () => {
  const [openAddItem, setOpenAddItem] = useState(false);
  const [openEditItem, setOpenEditItem] = useState(false);
  const [data, setData] = useState([]);
  const [dataMasterSeason, setDataMasterSeason] = useState([]);
  const [dataKategoriGame, setDataKategoriGame] = useState([]);
  const [dataSubKategoriGame, setDataSubKategoriGame] = useState([]);
  const [dataTeam, setDataTeam] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedKategoriGame, setSelectedKategoriGame] = useState(false);
  const [selectedSubKategoriGame, setSelectedSubKategoriGame] = useState(false);
  const [loadingAddItem, setLoadingAddItem] = useState(false);
  const [loadingEditItem, setLoadingEditItem] = useState(false);
  const [loadingDeleteItem, setLoadingDeleteItem] = useState(false);
  const [getIdKategoriSelected, setGetIdKategoriSelected] = useState(false);
  const [sortOrder, setSortOrder] = useState({
    columnKey: null,
    order: null,
  });
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const authToken = localStorage.getItem('token');
  const [formAdd] = Form.useForm();
  const [formEdit] = Form.useForm();

  const columns = [
    {
      title: 'Nama Season',
      dataIndex: 'nama_season',
      key: 'nama_season',
      width: "12.5%",
      sorter: (a, b) => a.nama_season.localeCompare(b.nama_season),
      sortOrder: sortOrder.columnKey === 'nama_season' && sortOrder.order,
    },
    {
      title: 'Nama Kategori Game',
      dataIndex: 'nama_kategori_game',
      key: 'nama_kategori_game',
      width: "12.5%",
      sorter: (a, b) => a.nama_kategori_game.localeCompare(b.nama_kategori_game),
      sortOrder: sortOrder.columnKey === 'nama_kategori_game' && sortOrder.order,
    },
    {
      title: 'Nama Sub Kategori Game',
      dataIndex: 'nama_sub_kategori_game',
      key: 'nama_sub_kategori_game',
      width: "12.5%",
      sorter: (a, b) => a.nama_sub_kategori_game.localeCompare(b.nama_sub_kategori_game),
      sortOrder: sortOrder.columnKey === 'nama_sub_kategori_game' && sortOrder.order,
    },
    {
      title: 'Nama Team',
      dataIndex: 'nama_team',
      key: 'nama_team',
      width: "12.5%",
      sorter: (a, b) => a.nama_team.localeCompare(b.nama_team),
      sortOrder: sortOrder.columnKey === 'nama_team' && sortOrder.order,
    },
    {
      title: 'Image Team',
      dataIndex: 'image_team',
      key: 'image_team',
      width: "10%",
    },
    {
      title: 'Created Date',
      dataIndex: 'createdat',
      key: 'createDat',
      width: "12.5%",
      sorter: (a, b) => moment(a.createDat) - moment(b.createDat),
      sortOrder: sortOrder.columnKey === 'createDat' && sortOrder.order,
    },
    {
      title: 'Updated Date',
      dataIndex: 'udpatedat',
      key: 'udpatedat',
      width: "12.5%",
      sorter: (a, b) => moment(a.udpatedat) - moment(b.udpatedat),
      sortOrder: sortOrder.columnKey === 'udpatedat' && sortOrder.order,
    },
    {
      title: 'Actions',
      key: 'actions',
      width: "12.5%",
      render: (_, record) => (
        <span style={{display:'flex'}}>
          <Button onClick={() => showDrawerEdit(record)}>Edit</Button>
          <Popconfirm
            title="Delete Season"
            description="Apakah yakin ingin menghapus Season?"
            onConfirm={() => handleDelete(record)}
            okText= "Hapus"
            cancelText="Tidak"
          >
            <Button style={{ marginLeft: 8 }} danger>Delete</Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  useEffect(() => {
    getListSeason()
    getListKategoriGame()
    getListSubKategoriGame()
    getListMasterSeason()
  }, [loadData]);


  const getListSeason = () => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_SEASON;
    axios
    .get(URL, { headers })
    .then((response) => {
      if (response.status === 200) {
        setLoadData(false);
        setData(response.data.Data)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        localStorage.removeItem('token');
        localStorage.removeItem('id_admin');
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      }
    });
  }
  const getListMasterSeason = () => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_MASTER_SEASON;
    axios
    .get(URL, { headers })
    .then((response) => {
      if (response.status === 200) {
        setDataMasterSeason(response.data.Data)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      }
    });
  }
  const getListKategoriGame = () => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_KATEGORI_GAME;
    axios
    .get(URL, { headers })
    .then((response) => {
      if (response.status === 200) {
        setDataKategoriGame(response.data.Data)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      }
    });
  }
  const getListSubKategoriGame = () => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_SUB_KATEGORI_GAME;
    axios
    .get(URL, { headers })
    .then((response) => {
      if (response.status === 200) {
        setDataSubKategoriGame(response.data.Data)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      }
    });
  }
  const addListSeason = (payload) => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_ADD_LISTS_SEASON;
    axios
    .post(URL, payload, { headers })
    .then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          setSearchText('')
          setLoadData(true)
          setLoadingAddItem(false);
          setOpenAddItem(false);
          messageApi.open({
            type: 'success',
            content: 'Success Add Season',
            duration: 4,
          });
          formAdd.resetFields();
        },1000)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      } else {
        setTimeout(() => {
          setLoadData(true)
          messageApi.open({
            type: 'error',
            content: error.message,
            duration: 4,
          });
          setLoadingAddItem(false);
          setOpenAddItem(false);
          formAdd.resetFields();
        },1000)
      }
    });
  }
  const editListSeason = (payload) => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_EDIT_LISTS_SEASON;
    axios
    .post(URL, payload, { headers })
    .then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          setSearchText('')
          setLoadData(true)
          setLoadingEditItem(false);
          setOpenEditItem(false);
          messageApi.open({
            type: 'success',
            content: 'Success Edit Season',
            duration: 4,
          });
          formEdit.resetFields();
        },1000)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      } else {
        setTimeout(() => {
          setLoadData(true)
          messageApi.open({
            type: 'error',
            content: error.message,
            duration: 4,
          });
          setLoadingEditItem(false);
          setOpenEditItem(false);
          formEdit.resetFields();
        },1000)
      }
    });
  }
  const deleteListSeason = (payload) => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_DELETE_LISTS_SEASON;
    axios
    .post(URL, payload, { headers })
    .then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          setSearchText('')
          setLoadData(true)
          setLoadingDeleteItem(false);
          messageApi.open({
            type: 'success',
            content: 'Success Delete Season',
            duration: 4,
          });
        },1000)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      } else {
        setTimeout(() => {
          setLoadData(true)
          setLoadingDeleteItem(false);
          messageApi.open({
            type: 'error',
            content: error.message,
            duration: 4,
          });
        },1000)
      }
    });
  }
  const getListSubKategoriGameByKategori = (payloadId) => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const payload = 
    {
      "id_kategori_game": payloadId
    }
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_LISTS_SUB_KATEGORI_GAME_BY_KATEGORI;
    axios
    .post(URL, payload, { headers })
    .then((response) => {
      if (response.status === 200) {
        setDataSubKategoriGame(response.data.Data)
        setSelectedKategoriGame(true);
        setLoadingAddItem(false)
        setLoadingEditItem(false)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      }
    });
  }
  const getListTeamByKategoriAndSubKategori = (payload) => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_LISTS_TEAM_BY_KATEGORI_AND_SUBKATEGORI;
    axios
    .post(URL, payload, { headers })
    .then((response) => {
      if (response.status === 200) {
        setDataTeam(response.data.Data)
        setSelectedSubKategoriGame(true);
        setLoadingAddItem(false)
        setLoadingEditItem(false)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      }
    });
  }
  const dataSourceListSeason = data
  ? data.map((item, index) => {
    const createdat = moment(item.createdat).format("DD MMMM YYYY")
    const updatedat = moment(item.udpatedat).format("DD MMMM YYYY")
    return {
      key: index,
      createdat: createdat,
      id_sub_kategori_game: item.id_sub_kategori_game,
      id_kategori_game: item.id_kategori_game,
      id_team: item.id_team,
      id_season: item.id_season,
      id_master_season: item.id_master_season,
      image_team: item.image_team,
      nama_season: item.nama_season,
      nama_team: item.nama_team,
      nama_kategori_game: item.nama_kategori_game,
      nama_sub_kategori_game: item.nama_sub_kategori_game,
      udpatedat: updatedat,
    };
  })
  : null;
  const handleDelete = (record) => {
    setLoadingDeleteItem(true)
    const valueId = {
      "id_season": record.id_season
    }
    deleteListSeason(valueId)
  };
  const showDrawerEdit = (record) => {
    getListSubKategoriGameByKategori(record.id_kategori_game)
    setSelectedRowKey(record.key);
    setOpenEditItem(true);
    const payloadGetTeam = {
      id_kategori_game : record.id_kategori_game,
      id_sub_kategori_game: record.id_sub_kategori_game,
    }
    getListTeamByKategoriAndSubKategori(payloadGetTeam)
    formEdit.setFieldsValue({
      id_master_season: record.id_master_season,
      updatedDat: record.updatedDat,
      createDat: record.createDat,
      id_sub_kategori_game: record.id_sub_kategori_game,
      id_kategori_game: record.id_kategori_game,
      id_team: record.id_team,
      id_season: record.id_season,
    });
  };
  const showDrawerAdd = () => {
    setOpenAddItem(true);
  };
  const onCloseAdd = () => {
    formAdd.resetFields();
    setOpenAddItem(false);
    setSelectedKategoriGame(false)
    setSelectedSubKategoriGame(false)
    setLoadData(true)
  };
  const onCloseEdit = () => {
    formEdit.resetFields();
    setOpenEditItem(false);
    setSelectedKategoriGame(false)
    setSelectedSubKategoriGame(false)
    setLoadData(true)
  };
  const onFinishAdd = () => {
    setLoadingAddItem(true)
    setSelectedKategoriGame(false)
    setSelectedSubKategoriGame(false)
    const values = formAdd.getFieldsValue();

    addListSeason(values)
  };
  const onFinishEdit = () => {
    setLoadingEditItem(true)
    setSelectedKategoriGame(false)
    setSelectedSubKategoriGame(false)
    const values = formEdit.getFieldsValue();
    values.id_season = data[selectedRowKey].id_season

    editListSeason(values)
  };
  const handleAddKategoriGameChange = (value) => {
    setLoadingAddItem(true)
    setGetIdKategoriSelected(value)
    getListSubKategoriGameByKategori(value)
    formAdd.resetFields(['id_sub_kategori_game']);
    formAdd.resetFields(['id_team']);
  };
  const handleAddSubKategoriGameChange = (value) => {
    const payloadGetTeam = {
      id_kategori_game : getIdKategoriSelected,
      id_sub_kategori_game: value
    }
    setLoadingAddItem(true)
    getListTeamByKategoriAndSubKategori(payloadGetTeam)
    formAdd.resetFields(['id_team']);
  }
  const handleEditKategoriGameChange = (value) => {
    setLoadingEditItem(true)
    setGetIdKategoriSelected(value)
    getListSubKategoriGameByKategori(value)
    formEdit.resetFields(['id_sub_kategori_game']);
    formEdit.resetFields(['id_team']);
  };
  const handleEditSubKategoriGameChange = (value) => {
    setLoadingEditItem(true)
    const payloadGetTeam = {
      id_kategori_game : getIdKategoriSelected,
      id_sub_kategori_game: value
    }
    getListTeamByKategoriAndSubKategori(payloadGetTeam)
    formEdit.resetFields(['id_team']);
  };
  const handleSearch = (value) => {
    setSearchText(value);
  
    const filteredData = dataSourceListSeason.filter((record) => {
      return Object.values(record).some(
        (fieldValue) =>
          fieldValue && fieldValue.toString().toLowerCase().includes(value.toLowerCase())
      );
    });
  
    setFilteredDataSource(filteredData);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    setSortOrder({
      columnKey: sorter.columnKey,
      order: sorter.order,
    });
  };
  return (
    <Layout>
      {contextHolder}
      <Breadcrumb
        items={[
          {
            href: '/dashboard/',
            title: (
              <>
                <HomeOutlined />
                <span>Dashboard</span>
              </>
            ),
          },
          {
            title: "List Team Season",
          },
        ]}
      />
      <Spin spinning={loadingDeleteItem} tip="Loading...">
        <Flex vertical>
          <Space className='dashboard-add-item'>
            <Button className='dashboard-add-item-button' type="primary" onClick={showDrawerAdd} icon={<PlusOutlined />}>Tambah Team Season</Button>  
            <Drawer
              className='dashboard-add-item-drawer'
              title="Tambah Team Season"
              width={720}
              closable={false}
              visible={openAddItem}
            >
              <Spin spinning={loadingAddItem} tip="Loading...">
                <Form layout="vertical" form={formAdd} className='dashboard-add-item-drawer-form' onFinish={onFinishAdd}>
                  <Row gutter={16}>
                    <Col xs={24} sm={16} lg={10}>
                      <Form.Item
                        name="id_master_season"
                        label="Nama Master Season"
                        rules={[
                          {
                            required: true,
                            message: 'Silahkan Masukkan Nama Master Season',
                          },
                        ]}
                      >
                        <Select
                          showSearch 
                          optionFilterProp="children" 
                          filterOption={
                            (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder="Pilih Nama Master Season"
                        >
                          {dataMasterSeason ? dataMasterSeason.map((item) => (
                            <Option key={item.id_master_season} value={item.id_master_season}>
                              {item.nama_master_season}
                            </Option>
                          )) : null}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={16} lg={10}>
                      <Form.Item
                        name="id_kategori_game"
                        label="Nama Kategori Game"
                        rules={[
                          {
                            required: true,
                            message: 'Silahkan Masukkan Nama Kategori Game',
                          },
                        ]}
                      >
                        <Select
                          showSearch 
                          optionFilterProp="children" 
                          filterOption={
                            (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder="Pilih Kategori Game"
                          onChange={handleAddKategoriGameChange}
                        >
                          {dataKategoriGame ? dataKategoriGame.map((item) => (
                            <Option key={item.id_kategori_game} value={item.id_kategori_game}>
                              {item.nama_kategori_game}
                            </Option>
                          )) : null}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  {
                    selectedKategoriGame ? 
                      <Row gutter={16}>
                        <Col xs={24} sm={16} lg={10}>
                          <Form.Item
                            name="id_sub_kategori_game"
                            label="Nama Sub Kategori Game"
                            rules={[
                              {
                                required: true,
                                message: 'Silahkan Masukkan Nama Sub Kategori Game',
                              },
                            ]}
                          >
                            <Select
                              showSearch 
                              optionFilterProp="children" 
                              filterOption={
                                (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              placeholder="Pilih Sub Kategori Game"
                              onChange={handleAddSubKategoriGameChange}
                            >
                              {dataSubKategoriGame ? dataSubKategoriGame.map((item) => (
                                <Option key={item.id_sub_kategori_game} value={item.id_sub_kategori_game}>
                                  {item.nama_sub_kategori_game}
                                </Option>
                              )) : null}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row> 
                    : (null)
                  }
                  { 
                    selectedSubKategoriGame ? 
                      <Row gutter={16}>
                        <Col xs={24} sm={16} lg={10}>
                          <Form.Item
                            name="id_team"
                            label="Nama Team"
                            rules={[
                              {
                                required: true,
                                message: 'Silahkan Masukkan Nama Team',
                              },
                            ]}
                          >
                            <Select
                              showSearch 
                              optionFilterProp="children" 
                              filterOption={
                                (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              placeholder="Pilih Team"
                            >
                              {dataTeam ? dataTeam.map((item) => (
                                <Option key={item.id_team} value={item.id_team}>
                                  {item.nama_team}
                                </Option>
                              )) : null}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    : null 
                  }
                  
                  <Row gutter={16}>
                    <Col span={24}>
                      <Space className='dashboard-add-item-drawer-form-button'>
                        <Button onClick={onCloseAdd}>Batal</Button>
                        <Button  type="primary" htmlType='submit'>Tambah</Button>
                      </Space>
                    </Col>
                  </Row>
                </Form>
              </Spin>
            </Drawer>
          </Space>
          <Input
            placeholder="Search"
            onChange={(e) => handleSearch(e.target.value)}
            value={searchText}
            style={{marginBottom: '20px', width: '50%'}}
          />
        </Flex>
        <Table
          bordered={true}
          dataSource={searchText ? filteredDataSource : dataSourceListSeason}
          columns={columns}
          rowKey="key"
          scroll={{
            x: 1600,
          }}
          pagination={{ pageSize: 20 }}
          onChange={handleTableChange}
        />
        <Drawer
          width={720}
          title="Edit Team Season"
          closable={false}
          visible={openEditItem}
        >
          <Spin spinning={loadingEditItem} tip="Loading...">
            <Form layout="vertical" form={formEdit} onFinish={onFinishEdit} className='dashboard-edit-item-drawer-form'>
              <Row gutter={16}>
                <Col xs={24} sm={16} lg={10}>
                <Form.Item
                  name="id_master_season"
                  label="Nama Master Season"
                  rules={[
                    {
                      required: true,
                      message: 'Silahkan Masukkan Nama Master Season',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children" 
                    filterOption={
                      (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Pilih Nama Master Season"
                  >
                    {dataMasterSeason ? dataMasterSeason.map((item) => (
                      <Option key={item.id_master_season} value={item.id_master_season}>
                        {item.nama_master_season}
                      </Option>
                    )) : null}
                  </Select>
                </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={16} lg={10}>
                  <Form.Item
                    name="id_kategori_game"
                    label="Nama Kategori Game"
                    rules={[
                      {
                        required: true,
                        message: 'Silahkan Masukkan Nama Kategori Game',
                      },
                    ]}
                  >
                    <Select
                      showSearch 
                      optionFilterProp="children" 
                      filterOption={
                        (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="Pilih Kategori Game" 
                      onChange={handleEditKategoriGameChange}
                    >
                      {dataKategoriGame ? dataKategoriGame.map((item) => (
                        <Option key={item.id_kategori_game} value={item.id_kategori_game}>
                          {item.nama_kategori_game}
                        </Option>
                      )) : null}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={16} lg={10}>
                  <Form.Item
                    name="id_sub_kategori_game"
                    label="Nama Sub Kategori Game"
                    rules={[
                      {
                        required: true,
                        message: 'Silahkan Masukkan Nama Sub Kategori Game',
                      },
                    ]}
                  >
                    <Select
                      showSearch 
                      optionFilterProp="children" 
                      filterOption={
                        (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="Pilih Sub Kategori Game"
                      onChange={handleEditSubKategoriGameChange}
                    >
                      {dataSubKategoriGame ? dataSubKategoriGame.map((item) => (
                        <Option key={item.id_sub_kategori_game} value={item.id_sub_kategori_game}>
                          {item.nama_sub_kategori_game}
                        </Option>
                      )) : null}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={16} lg={10}>
                  <Form.Item
                    name="id_team"
                    label="Nama Team"
                    rules={[
                      {
                        required: true,
                        message: 'Silahkan Masukkan Nama Team',
                      },
                    ]}
                  >
                    <Select
                      showSearch 
                      optionFilterProp="children" 
                      filterOption={
                        (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="Pilih Team"
                    >
                      {dataTeam ? dataTeam.map((item) => (
                        <Option key={item.id_team} value={item.id_team}>
                          {item.nama_team}
                        </Option>
                      )) : null}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Space className='dashboard-edit-item-drawer-form-button'>
                    <Button onClick={onCloseEdit}>Batal</Button>
                    <Button  type="primary" htmlType='submit'>Update</Button>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Drawer>
      </Spin>
    </Layout>
  )
};

export default DashboardListSeason;
