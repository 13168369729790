import { HomeOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  Flex,
  Form,
  Image,
  Input,
  Layout,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Constant from "../../../config/constans";
import { uniq, uniqBy } from "lodash";
const { Text } = Typography;
const { Option } = Select;

const DashboardLeaderboardPlayer = () => {
  const [openAddItem, setOpenAddItem] = useState(false);
  const [openEditItem, setOpenEditItem] = useState(false);
  const [data, setData] = useState([]);
  const [dataKategoriGame, setDataKategoriGame] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedKategoriGame, setSelectedKategoriGame] = useState(false);
  const [loadingAddItem, setLoadingAddItem] = useState(false);
  const [loadingEditItem, setLoadingEditItem] = useState(false);
  const [loadingDeleteItem, setLoadingDeleteItem] = useState(false);

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const showModal = (data) => {
    setOpen(true);
    setDataModal(data);
  };

  const handleOk = () => {
    if (!dataModal?.is_approved !== "Approved") {
      setConfirmLoading(true);

      handleApprove(true);
    } else {
      setOpen(false);
    }
  };

  const handleCancel = (e) => {
    console.log("Clicked cancel button", e);
    handleApprove(false);
  };

  const [sortOrder, setSortOrder] = useState({
    columnKey: null,
    order: null,
  });
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const authToken = localStorage.getItem("token");
  const [formAdd] = Form.useForm();
  const [formEdit] = Form.useForm();
  const MAX_FILE_SIZE = 0.6;

  const columns = [
    {
      title: "Photo Profile",
      dataIndex: "photo_profile",
      key: "photo_profile",
      width: "20%",
      sorter: (a, b) => a.photo_profile.localeCompare(b.photo_profile), //tambah
      sortOrder: sortOrder.columnKey === "photo_profile" && sortOrder.order, //tambah
      render: (_, record) => (
        <Image
          src={record?.photo_profile}
          style={{ width: 60, height: 60, borderRadius: 100 }}
        />
      ),
    },
    {
      title: "Nama Player ",
      dataIndex: "nama_player",
      key: "nama_player",
      width: "20%",
      sorter: (a, b) => a.nama_player.localeCompare(b.nama_player), //tambah
      sortOrder: sortOrder.columnKey === "nama_player" && sortOrder.order, //tambah
    },

    {
      title: "Point",
      dataIndex: "point",
      key: "point",
      width: "20%",
      sorter: (a, b) => a.point.localeCompare(b.point), //tambah
      sortOrder: sortOrder.columnKey === "point" && sortOrder.order, //tambah
    },

    {
      title: "Created Date",
      dataIndex: "createdat",
      key: "createDat",
      width: "20%",
      sorter: (a, b) => moment(a.createdat) - moment(b.createdat), //tambah
      sortOrder: sortOrder.columnKey === "createdat" && sortOrder.order, //tambah
    },
    {
      title: "Updated Date",
      dataIndex: "udpatedat",
      key: "udpatedat",
      width: "20%",
      sorter: (a, b) => moment(a.udpatedat) - moment(b.udpatedat), //tambah
      sortOrder: sortOrder.columnKey === "udpatedat" && sortOrder.order, //tambah
    },
    // {
    //   title: "Actions",
    //   key: "actions",
    //   width: "20%",
    //   render: (_, record) => (
    //     <Flex gap={"small"}>
    //       {/* <Button type="primary" onClick={() => showModal(record)}>
    //         Detail
    //       </Button> */}
    //       {/* <Button onClick={() => showDrawerEdit(record)}>Edit</Button> */}
    //       <Popconfirm
    //         title="Delete Member"
    //         description="Apakah yakin ingin menghapus Member?"
    //         onConfirm={() => handleDelete(record)}
    //         okText="Hapus"
    //         cancelText="Tidak"
    //       >
    //         <Button style={{ marginLeft: 8 }} danger>
    //           Delete
    //         </Button>
    //       </Popconfirm>
    //     </Flex>
    //   ),
    // },
  ];

  const columnTeam = [
    {
      title: "Photo Profile ",
      dataIndex: "photo_profile",
      key: "photo_profile",
      width: "20%",
      sorter: (a, b) => a.photo_profile.localeCompare(b.photo_profile), //tambah
      sortOrder: sortOrder.columnKey === "photo_profile" && sortOrder.order, //tambah
      render: (_, record) => (
        <Image
          src={record?.photo_profile}
          style={{ width: 60, height: 60, borderRadius: 100 }}
        />
      ),
    },
    {
      title: "Nama  ",
      dataIndex: "nama",
      key: "nama",
      width: "20%",
      sorter: (a, b) => a.nama.localeCompare(b.nama), //tambah
      sortOrder: sortOrder.columnKey === "nama" && sortOrder.order, //tambah
    },
    {
      title: "Nickname ",
      dataIndex: "nickname",
      key: "nickname",
      width: "20%",
      sorter: (a, b) => a.nickname.localeCompare(b.nickname), //tambah
      sortOrder: sortOrder.columnKey === "nickname" && sortOrder.order, //tambah
    },

    {
      title: "Email ",
      dataIndex: "email",
      key: "email",
      width: "20%",
      sorter: (a, b) => a.email.localeCompare(b.email), //tambah
      sortOrder: sortOrder.columnKey === "email" && sortOrder.order, //tambah
    },
    {
      title: "badge_royalty ",
      dataIndex: "badge_royalty",
      key: "badge_royalty",
      width: "20%",
      sorter: (a, b) => a.badge_royalty.localeCompare(b.badge_royalty), //tambah
      sortOrder: sortOrder.columnKey === "badge_royalty" && sortOrder.order, //tambah
    },
    {
      title: "No HP ",
      dataIndex: "no_hp",
      key: "no_hp",
      width: "20%",
      sorter: (a, b) => a.no_hp.localeCompare(b.no_hp), //tambah
      sortOrder: sortOrder.columnKey === "no_hp" && sortOrder.order, //tambah
    },
  ];

  useEffect(() => {
    getListMaxMember();
    getListKategoriGame();
  }, [loadData]);

  const getListMaxMember = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH +
      Constant.URL_GET_LIST_MASTER_LEADERBOARD_PLAYER;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          setLoadData(false);
          console.log("responseeee", response?.data?.Data);
          setData(response?.data?.Data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          localStorage.removeItem("token");
          localStorage.removeItem("id_admin");
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };
  const getListKategoriGame = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_KATEGORI_GAME;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          setDataKategoriGame(response.data.Data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };

  const handleApprove = (is_approved) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_POST_APPROVED_TOURNAMENT;
    axios
      .post(
        URL,
        {
          id_room_tournament: dataModal?.id_room_tournament,
          is_approved: is_approved,
        },
        { headers },
      )
      .then((response) => {
        if (response.status === 200) {
          setConfirmLoading(false);
          setLoadData(!loadData);
          setTimeout(() => {
            messageApi.open({
              type: "success",
              content: is_approved ? "Approved !" : "Rejected !",
              duration: 4,
            });

            setOpen(false);

            // formAdd.resetFields();
          }, 1000);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
            setLoadingAddItem(false);
            setOpenAddItem(false);
          }, 1000);
        }
      });
  };
  const editListMaxMember = (payload) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_POST_EDIT_LISTS_MASTER_MAXMEMBER;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setSearchText(""); //tambah
            setLoadData(true);
            setLoadingEditItem(false);
            setOpenEditItem(false);
            messageApi.open({
              type: "success",
              content: "Success Edit Max Member",
              duration: 4,
            });
            formAdd.resetFields();
          }, 1000);
        }
      })
      .catch((error) => {
        setLoadingEditItem(false);
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
            setLoadingEditItem(false);
            setOpenEditItem(false);
            formAdd.resetFields();
          }, 1000);
        }
      });
  };
  const deleteMemberPeserta = (payload) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_DELETE_PESERTA_TOURNAMENT;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setSearchText(""); //tambah
            setLoadData(true);
            setLoadingDeleteItem(false);
            messageApi.open({
              type: "success",
              content: "Success Delete Member",
              duration: 4,
            });
          }, 1000);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            setLoadingDeleteItem(false);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };

  const dataSourceListMemberTournament = data
    ? data.map((item, index) => {
        const createdat = moment(item.createdat).format("DD MMMM YYYY");
        const updatedat = moment(item.udpatedat).format("DD MMMM YYYY");
        const registdat = moment(item.date_register).format("DD MMMM YYYY");

        return {
          key: index,

          id_leaderboard_player: item?.id_leaderboard_player,
          id_player: item?.id_player,
          nama_player: item?.nama_player,
          photo_profile: item?.photo_profile,
          point: item?.point,
          createdat: createdat,
          udpatedat: updatedat,
        };
      })
    : [];
  const handleDelete = (record) => {
    setLoadingDeleteItem(true);
    const valueId = {
      id_room_tournament: record.id_room_tournament,
    };
    deleteMemberPeserta(valueId);
  };
  const showDrawerEdit = (record) => {
    setSelectedRowKey(record.key);
    setOpenEditItem(true);
    formAdd.setFieldsValue({
      id_leaderboard_player: record?.id_leaderboard_player,
      id_player: record?.id_player,
      nama_player: record?.nama_player,
      photo_profile: record?.photo_profile,
      point: record?.point,
      updatedDat: record.updatedDat,
      createDat: record.createDat,
    });
  };
  const showDrawerAdd = () => {
    setOpenAddItem(true);
  };
  const onCloseAdd = () => {
    setIsSearch(false);
    formAdd.resetFields();
    setOpenAddItem(false);
    setSelectedKategoriGame(false);
    setLoadData(true);
  };
  const onCloseEdit = () => {
    formAdd.resetFields();
    setOpenEditItem(false);
    setSelectedKategoriGame(false);
    setLoadData(true);
  };
  const onFinishAdd = () => {
    setLoadingAddItem(true);
    setSelectedKategoriGame(false);
    const values = formAdd.getFieldsValue();

    if (values?.date_register) {
      const selectedDate = values?.date_register.format("DD MMMM YYYY");
      values.date_register = selectedDate;
    }

    console.log("wwww", values);

    // addListMaxMember(values);
    handleSearch(values);
  };
  const onFinishEdit = () => {
    setLoadingEditItem(true);
    setSelectedKategoriGame(false);
    const values = formAdd.getFieldsValue();

    values.id_max_member = data[selectedRowKey].id_max_member;

    editListMaxMember(values);
  };

  const handleSearch = (value) => {
    // setSearchText(value);
    setIsSearch(true);
    console.log(
      "dataSourceListMemberTournament",
      dataSourceListMemberTournament,
      value,
    );
    const filteredResults1 = dataSourceListMemberTournament.filter((item) => {
      const matchesNamaTournament =
        value?.nama_player === "" ||
        item.nama_player
          ?.toLowerCase()
          .includes(value?.nama_player?.toLowerCase());

      return matchesNamaTournament;
    });

    let tmp = uniqBy([...filteredResults1]);

    setFilteredDataSource(tmp);
    // setFilteredDataSource(filteredData);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    //tambah
    setSortOrder({
      columnKey: sorter.columnKey,
      order: sorter.order,
    });
  };

  return (
    <Layout>
      {contextHolder}
      <Breadcrumb
        items={[
          {
            href: "/dashboard/",
            title: (
              <>
                <HomeOutlined />
                <span>Dashboard</span>
              </>
            ),
          },
          {
            title: "List Leaderboard Player",
          },
        ]}
      />
      <Spin
        // spinning={openEditItem ? loadingEditItem : loadingAddItem}
        spinning={false}
        tip="Loading..."
      >
        <Form
          layout="vertical"
          form={formAdd}
          className="dashboard-add-item-drawer-form"
          onFinish={openEditItem ? onFinishEdit : onFinishAdd}
        >
          <h3>Cari Berdasarkan</h3>

          <Row gutter={16}>
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="nama_player"
                label="Nama Player"
                rules={[
                  {
                    required: false,
                    message: "Silahkan Masukkan Nama Player",
                  },
                ]}
              >
                <Input placeholder="Masukan Nama Player" />
              </Form.Item>
            </Col>
          </Row>
          {/* <Row gutter={16}>
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="id_kategori_game"
                label="Kategori Game"
                rules={[
                  {
                    required: false,
                    message: "Silahkan Masukkan Kategori Game",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                  placeholder="Pilih Kategori Game"
                >
                  {dataKategoriGame
                    ? dataKategoriGame.map((item) => (
                        <Option
                          key={item.nama_kategori_game}
                          value={item.nama_kategori_game}
                        >
                          {item.nama_kategori_game}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
          </Row> */}

          <Row gutter={16} style={{ marginBottom: 20 }}>
            <Col span={24}>
              <Space className="dashboard-add-item-drawer-form-button">
                <Button onClick={onCloseAdd}>Batal</Button>
                <Button type="primary" htmlType="submit">
                  {"Cari"}
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Spin>

      <Spin spinning={loadingDeleteItem} tip="Loading...">
        <Flex vertical style={{ marginBottom: 10 }}>
          <Space />

          {/* <Input
            placeholder="Search"
            onChange={(e) => handleSearch(e.target.value)}
            value={searchText}
            style={{ marginBottom: "20px", width: "50%", marginTop: 30 }}
          /> */}
        </Flex>

        <Table
          bordered={true}
          dataSource={
            isSearch ? filteredDataSource : dataSourceListMemberTournament
          }
          columns={columns}
          rowKey="key"
          scroll={{
            x: 1600,
          }}
          pagination={{ pageSize: 20 }} //tambah
          onChange={handleTableChange} //tambah
        />
      </Spin>
      <Modal
        title="Detail Tournament"
        open={open}
        onOk={handleOk}
        okText={dataModal?.is_approved == "Approved" ? "Ok" : "Approve"}
        confirmLoading={confirmLoading}
        onCancel={() => setOpen(false)}
        cancelText={"Rejected"}
        width={"80%"}
        cancelButtonProps={{
          onClick: (e) => handleCancel(),
        }}
      >
        <Row>
          <Col span={10}>Nama Tournament</Col>
          <Col span={1}>:</Col>
          <Col span={10}>{dataModal?.nama_tournament} </Col>

          <Col span={10}>Deskripsi Team </Col>
          <Col span={1}>: </Col>
          <Col span={10}>{dataModal?.nama_team} </Col>

          <Col span={10}>Nama Team </Col>
          <Col span={1}>: </Col>
          <Col span={10}>{dataModal?.nama_team} </Col>

          <Col span={10}>Kategori Game </Col>
          <Col span={1}>: </Col>
          <Col span={10}>{dataModal?.nama_kategori_game} </Col>

          <Col span={10}>Status Approve </Col>
          <Col span={1}>: </Col>
          <Col span={10}>{dataModal?.is_approved}</Col>

          <Col span={10}>Tanggal Register </Col>
          <Col span={1}>: </Col>
          <Col span={10}>{moment().format("DD MMMM YYYY hh:mm")}</Col>
        </Row>
        <div style={{ marginTop: 20 }} />
        <Table
          bordered={true}
          dataSource={dataModal?.teams}
          columns={columnTeam}
          rowKey="key"
          scroll={{
            x: 1600,
          }}
          pagination={{ pageSize: 20 }} //tambah
          onChange={handleTableChange} //tambah
        />
      </Modal>
    </Layout>
  );
};

export default DashboardLeaderboardPlayer;
