import { HomeOutlined } from "@ant-design/icons";
import {
  Alert,
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  Flex,
  Form,
  Image,
  Input,
  Layout,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Constant from "../../../config/constans";
import { uniq, uniqBy } from "lodash";
import dayjs from "dayjs";
const { Text } = Typography;
const { Option } = Select;

const DashboardListBracketTournament = () => {
  const [openAddItem, setOpenAddItem] = useState(false);
  const [openEditItem, setOpenEditItem] = useState(false);
  const [data, setData] = useState([]);
  const [dataKategoriGame, setDataKategoriGame] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedKategoriGame, setSelectedKategoriGame] = useState(false);
  const [loadingAddItem, setLoadingAddItem] = useState(false);
  const [loadingEditItem, setLoadingEditItem] = useState(false);
  const [loadingDeleteItem, setLoadingDeleteItem] = useState(false);
  const [dataListTournament, setdataListTournament] = useState(false);
  const [dataListBracket, setDataListBracket] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const showModal = (data) => {
    setOpen(true);
    setDataModal(data);
  };
  const [selectTournament, setSelectTournament] = useState({});
  const [selectFormatTournament, setSelectFormatTournament] = useState({});
  const [selectJumlahBracketTournament, setSelectJumlahBracketTournament] =
    useState(2);
  const [dataDetailTournament, setDataDetailTournament] = useState({});
  console.log("dataDetailTournament", dataDetailTournament);
  const [responseTournamentChallonge, setResponseTournamentChallonge] =
    useState({});

  const [isLoading, setIsLoading] = useState(false);

  const handleOk = () => {
    if (!dataModal?.is_approved !== "Approved") {
      setConfirmLoading(true);

      handleApprove(true);
    } else {
      setOpen(false);
    }
  };

  const handleCancel = (e) => {
    console.log("Clicked cancel button", e);
    handleApprove(false);
  };

  const [sortOrder, setSortOrder] = useState({
    columnKey: null,
    order: null,
  });
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const authToken = localStorage.getItem("token");
  const [formAdd] = Form.useForm();
  const [formEdit] = Form.useForm();
  const MAX_FILE_SIZE = 0.6;

  const dataFormatBracket = [
    "single elimination",
    "double elimination",
    "round robin",
  ];

  const columns = [
    {
      title: "Nama Tournament ",
      dataIndex: "name_tournament",
      key: "name_tournament",
      width: "20%",
      sorter: (a, b) => a.name_tournament.localeCompare(b.name_tournament), //tambah
      sortOrder: sortOrder.columnKey === "name_tournament" && sortOrder.order, //tambah
    },
    {
      title: "Nama bracket ",
      dataIndex: "nama_bracket",
      key: "nama_bracket",
      width: "20%",
      sorter: (a, b) => a.nama_bracket.localeCompare(b.nama_bracket), //tambah
      sortOrder: sortOrder.columnKey === "nama_bracket" && sortOrder.order, //tambah
    },
    {
      title: "Format bracket ",
      dataIndex: "format_bracket",
      key: "format_bracket",
      width: "20%",
      sorter: (a, b) => a.format_bracket.localeCompare(b.format_bracket), //tambah
      sortOrder: sortOrder.columnKey === "format_bracket" && sortOrder.order, //tambah
      render: (_, record) => (
        <div>{dataFormatBracket[record?.format_bracket]}</div>
      ),
    },
    {
      title: "URL bracket ",
      dataIndex: "url_bracket_challonge",
      key: "url_bracket_challonge",
      width: "20%",
      sorter: (a, b) =>
        a.url_bracket_challonge.localeCompare(b.url_bracket_challonge), //tambah
      sortOrder:
        sortOrder.columnKey === "url_bracket_challonge" && sortOrder.order, //tambah
    },
    {
      title: "Created Date",
      dataIndex: "createdat",
      key: "createDat",
      width: "20%",
      sorter: (a, b) => moment(a.createdat) - moment(b.createdat), //tambah
      sortOrder: sortOrder.columnKey === "createdat" && sortOrder.order, //tambah
    },
    {
      title: "Updated Date",
      dataIndex: "udpatedat",
      key: "udpatedat",
      width: "20%",
      sorter: (a, b) => moment(a.udpatedat) - moment(b.udpatedat), //tambah
      sortOrder: sortOrder.columnKey === "udpatedat" && sortOrder.order, //tambah
    },
    {
      title: "Actions",
      key: "actions",
      width: "20%",
      render: (_, record) => (
        <Flex gap={"small"}>
          {record?.raw_res_challonge[0]?.data?.attributes?.state !=
          "pending" ? (
            <Alert
              // onClick={() => handleDelete(record)}
              message="Sedang berlangsung"
              type="info"
            />
          ) : (
            <>
              <Button
                type="primary"
                htmlType="button"
                onClick={() => handleStartTournamentChallonge(record)}
              >
                Start
              </Button>
              <Button onClick={() => showDrawerEdit(record)}>Edit</Button>
              <Popconfirm
                title="Delete Member"
                description="Apakah yakin ingin menghapus Member?"
                onConfirm={() => handleDelete(record)}
                okText="Hapus"
                cancelText="Tidak"
              >
                <Button style={{ marginLeft: 8 }} danger>
                  Delete
                </Button>
              </Popconfirm>
            </>
          )}
        </Flex>
      ),
    },
  ];

  const columnTeam = [
    {
      title: "Photo Profile ",
      dataIndex: "photo_profile",
      key: "photo_profile",
      width: "20%",
      sorter: (a, b) => a.photo_profile.localeCompare(b.photo_profile), //tambah
      sortOrder: sortOrder.columnKey === "photo_profile" && sortOrder.order, //tambah
      render: (_, record) => (
        <Image
          src={record?.photo_profile}
          style={{ width: 60, height: 60, borderRadius: 100 }}
        />
      ),
    },
    {
      title: "Nama  ",
      dataIndex: "nama",
      key: "nama",
      width: "20%",
      sorter: (a, b) => a.nama.localeCompare(b.nama), //tambah
      sortOrder: sortOrder.columnKey === "nama" && sortOrder.order, //tambah
    },
    {
      title: "Nickname ",
      dataIndex: "nickname",
      key: "nickname",
      width: "20%",
      sorter: (a, b) => a.nickname.localeCompare(b.nickname), //tambah
      sortOrder: sortOrder.columnKey === "nickname" && sortOrder.order, //tambah
    },

    {
      title: "Email ",
      dataIndex: "email",
      key: "email",
      width: "20%",
      sorter: (a, b) => a.email.localeCompare(b.email), //tambah
      sortOrder: sortOrder.columnKey === "email" && sortOrder.order, //tambah
    },
    // {
    //   title: "badge_royalty ",
    //   dataIndex: "badge_royalty",
    //   key: "badge_royalty",
    //   width: "20%",
    //   sorter: (a, b) => a.badge_royalty.localeCompare(b.badge_royalty), //tambah
    //   sortOrder: sortOrder.columnKey === "badge_royalty" && sortOrder.order, //tambah
    // },
    {
      title: "No HP ",
      dataIndex: "no_hp",
      key: "no_hp",
      width: "20%",
      sorter: (a, b) => a.no_hp.localeCompare(b.no_hp), //tambah
      sortOrder: sortOrder.columnKey === "no_hp" && sortOrder.order, //tambah
    },
  ];

  const getListBracket = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_MASTER_BRACKET;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          setDataListBracket(response.data.Data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          localStorage.removeItem("token");
          localStorage.removeItem("id_admin");
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };

  const getListTournament = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_MASTER_ROOM_TOURNAMENT;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          setdataListTournament(response.data.Data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          localStorage.removeItem("token");
          localStorage.removeItem("id_admin");
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };

  const getDetailTournament = (team) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_DETAIL_TOURNAMENT;
    axios
      .post(
        URL,
        {
          id_tournament: selectTournament?.value,
        },
        { headers },
      )
      .then((response) => {
        if (response.status === 200) {
          console.log("asdfasdfsadfa", response.data.Data[0]);
          setDataDetailTournament(response.data.Data[0]);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          localStorage.removeItem("token");
          localStorage.removeItem("id_admin");
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };

  useEffect(() => {
    getDetailTournament();
  }, [selectTournament?.value]);

  useEffect(() => {
    getListBracket();
    getListTournament();
    getListMasterBracketTournament();
    getListKategoriGame();
  }, [loadData]);

  const getListMasterBracketTournament = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_GET_LIST_MASTER_BRACKET_CHALLONGE;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          setLoadData(false);
          setData(response.data.Data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          localStorage.removeItem("token");
          localStorage.removeItem("id_admin");
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };
  const getListKategoriGame = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_KATEGORI_GAME;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          setDataKategoriGame(response.data.Data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };

  const addListBracketTournament = async (payload) => {
    setLoadingAddItem(true);

    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH +
      Constant.URL_POST_LIST_ADD_MASTER_BRACKET_CHALLONGE;
    await axios
      .post(URL, payload, { headers })
      .then((response) => {
        setLoadingAddItem(false);

        if (response.status === 200) {
          setTimeout(() => {
            setSearchText(""); //tambah
            setLoadData(true);
            setLoadingAddItem(false);
            setOpenAddItem(false);
            messageApi.open({
              type: "success",
              content: "Success Add Bracket Member",
              duration: 4,
            });
            formAdd.resetFields();
          }, 1000);
        }
      })
      .catch((error) => {
        setLoadingAddItem(false);

        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
            setLoadingAddItem(false);
            setOpenAddItem(false);
          }, 1000);
        }
      });
  };

  const handleStartTournamentChallonge = async (record) => {
    setLoadingAddItem(true);
    const headers = {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/vnd.api+json",
      Accept: "application/json",
      "Authorization-Type": "v1",
      Authorization: Constant.API_KEY_CHALLONGE,
      "Access-Control-Allow-Origin": "https://api.challonge.com",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    };

    let payload = {
      data: {
        type: "TournamentState",
        attributes: {
          state: "start",
        },
      },
    };
    console.log("responseTournamentChallonge11", responseTournamentChallonge);
    const URL =
      Constant.URL_API_CHALLONGE +
      Constant.URL_PUT_TOURNAMENT_STATE_CHALLONGE(
        record?.id_challonge || responseTournamentChallonge?.data?.id,
      );

    let payloadTest = {
      host: URL,
      payload: payload,
      auth: Constant.API_KEY_CHALLONGE,
      method: "PUT",
    };
    await axios
      .post(
        Constant.URL_MASTER_PATH + Constant.URL_POST_INTERNAL_CHALLONGE,
        payloadTest,
      )
      .then((response) => {
        if (response.data) {
          messageApi.open({
            type: "success",
            content: "Start tournament challonge",
            duration: 4,
          });
          setLoadingAddItem(false);
          let raw_res_challonge = [...record?.raw_res_challonge];
          raw_res_challonge[0] = response?.data;
          console.log("handleStartTournamentChallonge", response?.data);
          let payload = {
            ...record,
            raw_res_challonge: raw_res_challonge,
            udpatedat: new Date(),
            createdat: dayjs(record?.createdat).format(
              "[YYYYescape] YYYY-MM-DDTHH:mm:ssZ[Z]",
            ),
          };
          editListBracket(payload);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          setLoadingAddItem(false);
          setLoadingDeleteItem(false);
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
        }, 1000);
      });
  };

  const handleApprove = (is_approved) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_POST_APPROVED_TOURNAMENT;
    axios
      .post(
        URL,
        {
          id_bracket_tournament: dataModal?.id_bracket_tournament,
          is_approved: is_approved,
        },
        { headers },
      )
      .then((response) => {
        if (response.status === 200) {
          setConfirmLoading(false);
          setLoadData(!loadData);
          setTimeout(() => {
            messageApi.open({
              type: "success",
              content: is_approved ? "Approved !" : "Rejected !",
              duration: 4,
            });

            setOpen(false);

            // formAdd.resetFields();
          }, 1000);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
            setLoadingAddItem(false);
            setOpenAddItem(false);
          }, 1000);
        }
      });
  };

  const handleDeleteTournamentChallonge = async (
    id_tournament_challonge,
    record,
  ) => {
    const headers = {
      "Content-Type": "application/vnd.api+json",
      Accept: "application/json",
      "Authorization-Type": "v1",
      Authorization: Constant.API_KEY_CHALLONGE,
      "Access-Control-Allow-Origin": "https://api.challonge.com",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    };

    const URL =
      Constant.URL_API_CHALLONGE +
      Constant.URL_DELETE_LIST_TOURNAMENT_CHALLONGE(id_tournament_challonge);

    // const response = await fetch(URL, {
    //   method: "DELETE",
    //   headers: headers,
    // }).then((item) => {
    //   let valueId = {
    //     id_bracket_tournament: record.id_bracket_tournament,
    //   };
    //   deleteMemberPeserta(valueId);
    // });

    let payloadTest = {
      host: URL,
      payload: "",
      auth: Constant.API_KEY_CHALLONGE,
      method: "DELETE",
    };

    axios
      .post(
        Constant.URL_MASTER_PATH + Constant.URL_POST_INTERNAL_CHALLONGE,
        payloadTest,
      )
      .then((item) => {
        let valueId = {
          id_bracket_tournament: record.id_bracket_tournament,
        };
        deleteMemberPeserta(valueId);
      });

    // console.log("responseresponse", await response);
  };

  const editListBracket = (payload) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH +
      Constant.URL_POST_LIST_UPDATE_MASTER_BRACKET_CHALLONGE;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setSearchText(""); //tambah
            setLoadData(true);
            setLoadingEditItem(false);
            setOpenEditItem(false);
            messageApi.open({
              type: "success",
              content: "Success Edit Bracket Member",
              duration: 4,
            });
            formAdd.resetFields();
          }, 1000);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
            setLoadingEditItem(false);
            setOpenEditItem(false);
            formAdd.resetFields();
          }, 1000);
        }
      });
  };
  const deleteMemberPeserta = (payload) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH +
      Constant.URL_POST_LIST_DELETE_MASTER_BRACKET_CHALLONGE;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setSearchText(""); //tambah
            setLoadData(true);
            setLoadingDeleteItem(false);
            messageApi.open({
              type: "success",
              content: "Success Delete Member",
              duration: 4,
            });
          }, 1000);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            setLoadingDeleteItem(false);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };

  const dataSourceListMemberTournament = data
    ? data.map((item, index) => {
        const createdat = moment(item.createdat).format("DD MMMM YYYY");
        const updatedat = moment(item.udpatedat).format("DD MMMM YYYY");
        const registdat = moment(item.date_register).format("DD MMMM YYYY");

        return {
          key: index,
          id_bracket_tournament: item?.id_bracket_tournament,
          id_bracket: item?.id_bracket,
          id_tournament: item?.id_tournament,
          format_bracket: item?.format_bracket,
          raw_res_challonge: item?.raw_res_challonge,
          id_challonge: item?.id_challonge,
          url_bracket_challonge: item?.url_bracket_challonge,
          createdat: createdat,
          nama_bracket: item?.nama_bracket,
          name_tournament: item?.name_tournament,
          udpatedat: updatedat,
        };
      })
    : [];
  const handleDelete = (record) => {
    setLoadingDeleteItem(true);

    handleDeleteTournamentChallonge(record?.id_challonge, record);
  };
  const showDrawerEdit = (record) => {
    setSelectedRowKey(record.key);
    setOpenEditItem(true);
    formAdd.setFieldsValue({
      id_bracket_tournament: record?.id_bracket_tournament,
      id_bracket: record?.id_bracket,
      id_tournament: record?.id_tournament,
      format_bracket: record?.format_bracket,
      raw_res_challonge: record?.raw_res_challonge,
      id_challonge: record?.id_challonge,
      url_bracket_challonge: record?.url_bracket_challonge,
      createdat: record?.createdat,
      nama_bracket: record?.nama_bracket,
      name_tournament: record?.name_tournament,
      udpatedat: record?.udpatedat,
    });
  };
  const showDrawerAdd = () => {
    setOpenAddItem(true);
  };
  const onCloseAdd = () => {
    setIsSearch(false);
    formAdd.resetFields();
    setOpenAddItem(false);
    setOpenEditItem(false);
    setSelectedKategoriGame(false);
    setLoadData(true);
  };
  const onCloseEdit = () => {
    formAdd.resetFields();
    setOpenEditItem(false);
    setSelectedKategoriGame(false);
    setLoadData(true);
  };

  const addTournamentChallonge = async () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/vnd.api+json",
      Accept: "application/json",
      "Authorization-Type": "v1",
      Authorization: Constant.API_KEY_CHALLONGE,
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "POST",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept, Authorization",
      "Access-Control-Allow-Credentials": true,
    };

    let payload = {
      data: {
        type: "Tournaments",
        attributes: {
          name: selectTournament?.children,
          url: dataDetailTournament?.bracket_url_initial,
          tournament_type: selectFormatTournament?.children,
          game_name: dataDetailTournament?.nama_kategori_game,
          private: false,
          starts_at: "2025-08-15T16:33:02.462Z",
          description: dataDetailTournament?.description,
          notifications: {
            upon_matches_open: true,
            upon_tournament_ends: true,
          },
          match_options: {
            consolation_matches_target_rank: 3,
            accept_attachments: false,
          },
          registration_options: {
            open_signup: false,
            signup_cap: selectJumlahBracketTournament?.children,
            check_in_duration: 15,
          },
          seeding_options: {
            hide_seeds: false,
            sequential_pairings: false,
          },
          station_options: {
            auto_assign: false,
            only_start_matches_with_assigned_stations: false,
          },
          group_stage_enabled: false,
          group_stage_options: {
            stage_type: selectFormatTournament?.children,
            group_size: selectJumlahBracketTournament?.children,
            participant_count_to_advance_per_group: 2,
            rr_iterations: 1,
            ranked_by: "match wins",
            rr_pts_for_match_win: 1,
            rr_pts_for_match_tie: 0.5,
            rr_pts_for_game_win: 0,
            rr_pts_for_game_tie: 0,
            split_participants: false,
          },
          double_elimination_options: {
            split_participants: false,
            grand_finals_modifier: "",
          },
          round_robin_options: {
            iterations: 2,
            ranking: "",
            pts_for_game_win: 1,
            pts_for_game_tie: 0,
            pts_for_match_win: 1,
            pts_for_match_tie: 0.5,
          },
          swiss_options: {
            rounds: 2,
            pts_for_game_win: 1,
            pts_for_game_tie: 0,
            pts_for_match_win: 1,
            pts_for_match_tie: 0.5,
          },
          free_for_all_options: {
            max_participants: 4,
          },
        },
      },
    };

    const URL =
      Constant.URL_API_CHALLONGE + Constant.URL_POST_TOURNAMENT_CHALLONGE;
    let payloadTest = {
      host: URL,
      payload: payload,
      auth: Constant.API_KEY_CHALLONGE,
      method: "POST",
    };

    axios
      .post(
        Constant.URL_MASTER_PATH + Constant.URL_POST_INTERNAL_CHALLONGE,
        payloadTest,
      )
      .then((res) => {
        setLoadingAddItem(false);
        let response = res.data;
        console.log("response addTournamentChallongeInternal", response);

        if (response.data?.data) {
          messageApi.open({
            type: "success",
            content: "Success create tournament challonge",
            duration: 4,
          });
          const values = formAdd.getFieldsValue();
          console.log("setResponseTournamentChallonge", response?.data);
          setResponseTournamentChallonge(response?.data);
          values.raw_res_challonge = [response?.data];
          values.id_challonge = response?.data?.id;
          values.url_bracket_challonge =
            response?.data?.data?.attributes?.full_challonge_url;

          console.log("wwww", values);
          // addListBracketTournament(values);
          console.log(
            "addTournamentChallonge",
            response?.data,
            response?.data?.data?.attributes?.full_challonge_url,
          );
          if (response?.data?.data) {
            addParticipantChallonge(response?.data);
          }
        } else {
          messageApi.open({
            type: "error",
            content: "Data sudah ada di challonge",
            duration: 4,
          });
        }
      })
      .catch((error) => {
        console.log("responseee", error);
        setTimeout(() => {
          setLoadData(true);
          setLoadingDeleteItem(false);
          setLoadingAddItem(false);

          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
        }, 1000);
      });
  };

  const shuffleSeeds = (array) => {
    const seeds = array.map((item) => item.seed); // Extract all seed values
    for (let i = seeds.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [seeds[i], seeds[j]] = [seeds[j], seeds[i]]; // Shuffle the seeds
    }

    return array.map((item, index) => ({
      ...item,
      seed: seeds[index],
    }));
  };

  const addParticipantChallonge = async (reponseTournamentChallonge) => {
    setLoadingAddItem(true);
    const headers = {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/vnd.api+json",
      Accept: "application/json",
      "Authorization-Type": "v1",
      Authorization: Constant.API_KEY_CHALLONGE,
      "Access-Control-Allow-Origin": "https://api.challonge.com",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    };

    let tmpPeserta = [];

    dataDetailTournament?.list_peserta &&
      shuffleSeeds(dataDetailTournament?.list_peserta)?.map((item, index) => {
        if (item?.is_approved == "2") {
          tmpPeserta.push({
            name: item?.nama_team,
            seed: index + 1,
            misc: item?.id_team,
            email: item?.nama_team,
            username: item?.nama_team,
            // ...item,
          });
        }
      });

    let payload = {
      data: {
        type: "Participants",
        attributes: {
          participants: tmpPeserta,
          // participants: [
          //   {
          //     name: "AllBossFights",
          //     seed: 1,
          //     misc: "",
          //     email: "me%40allbossfights.xyz",
          //     username: "AllBossFights",
          //   },
          // ],
        },
      },
    };

    const URL =
      Constant.URL_API_CHALLONGE +
      Constant.URL_POST_PARTICIPANT_CHALLONGE(
        reponseTournamentChallonge?.data?.id,
      );
    let payloadTest = {
      host: URL,
      payload: payload,
      auth: Constant.API_KEY_CHALLONGE,
      method: "POST",
    };
    await axios
      .post(
        Constant.URL_MASTER_PATH + Constant.URL_POST_INTERNAL_CHALLONGE,
        payloadTest,
      )
      .then((response) => {
        if (response.data) {
          messageApi.open({
            type: "success",
            content: "Success create tournament challonge",
            duration: 4,
          });
          setLoadingAddItem(false);

          console.log("addParticipantChallonge", response?.data);

          const values = formAdd.getFieldsValue();

          values.raw_res_challonge = [
            reponseTournamentChallonge,
            response?.data,
          ];
          values.id_challonge = reponseTournamentChallonge?.data?.id;
          values.url_bracket_challonge =
            reponseTournamentChallonge?.data?.attributes?.full_challonge_url;
          values.nama_bracket_tournament =
            reponseTournamentChallonge?.data?.attributes?.name;

          console.log("wwww", values, reponseTournamentChallonge?.data);
          addListBracketTournament(values);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          setLoadData(true);
          setLoadingAddItem(false);

          setLoadingDeleteItem(false);
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
        }, 1000);
      });
  };

  const onFinishAdd = () => {
    setLoadingAddItem(true);
    setSelectedKategoriGame(false);

    addTournamentChallonge();
  };
  const onFinishEdit = () => {
    setLoadingEditItem(true);
    setSelectedKategoriGame(false);
    const values = formAdd.getFieldsValue();
    console.log("asdfasdf", values);

    values.raw_res_challonge = data[selectedRowKey]?.raw_res_challonge;
    values.id_challonge = data[selectedRowKey]?.id_challonge;
    values.url_bracket_challonge = data[selectedRowKey]?.url_bracket_challonge;
    values.id_bracket_tournament = data[selectedRowKey].id_bracket_tournament;

    editListBracket(values);
  };

  const handleSearch = (value) => {
    setSearchText(value);
    setIsSearch(true);

    // const filteredResults1 = dataSourceListMemberTournament.filter((item) => {
    //   const matchesNamaTournament =
    //     value?.nama_tournament === "" ||
    //     item.nama_tournament
    //       ?.toLowerCase()
    //       .includes(value?.nama_tournament?.toLowerCase());

    //   return matchesNamaTournament;
    // });

    // const filteredResults2 = dataSourceListMemberTournament.filter((item) => {
    //   const matchesDateRegister =
    //     value?.date_register === "" ||
    //     item.date_register
    //       .toLowerCase()
    //       .includes(value?.date_register?.toLowerCase());

    //   return matchesDateRegister;
    // });

    // const filteredResults3 = dataSourceListMemberTournament.filter((item) => {
    //   const matchesNamaTeam =
    //     value?.nama_kategori_game === "" ||
    //     item.nama_kategori_game
    //       ?.toLowerCase()
    //       .includes(value?.nama_kategori_game?.toLowerCase());

    //   return matchesNamaTeam;
    // });

    const filteredData = dataSourceListMemberTournament.filter((record) => {
      return Object.values(record).some(
        (fieldValue) =>
          fieldValue &&
          fieldValue.toString().toLowerCase().includes(value.toLowerCase()),
      );
    });

    // let tmp = uniqBy([
    //   ...filteredResults1,
    //   ...filteredResults2,
    //   ...filteredResults3,
    // ]);

    // setFilteredDataSource(tmp);
    setFilteredDataSource(filteredData);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    //tambah
    setSortOrder({
      columnKey: sorter.columnKey,
      order: sorter.order,
    });
  };

  return (
    <Layout>
      {contextHolder}
      <Breadcrumb
        items={[
          {
            href: "/dashboard/",
            title: (
              <>
                <HomeOutlined />
                <span>Dashboard</span>
              </>
            ),
          },
          {
            title: "List Bracket Tournament",
          },
        ]}
      />
      <Spin
        spinning={openEditItem ? loadingEditItem : loadingAddItem}
        tip="Loading..."
      >
        <Form
          layout="vertical"
          form={formAdd}
          className="dashboard-add-item-drawer-form"
          onFinish={openEditItem ? onFinishEdit : onFinishAdd}
        >
          <h3>Bracket Tournament</h3>
          <Row gutter={16}>
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="id_tournament"
                label="Nama Tournament"
                rules={[
                  {
                    required: true,
                    message: "Silahkan Masukkan Nama Tournament",
                  },
                ]}
              >
                {console.log("dataListTournament11", dataListTournament)}
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                  onChange={(value, select) => {
                    setSelectTournament(select);
                  }}
                  placeholder="Pilih Nama Tournament"
                >
                  {dataListTournament
                    ? dataListTournament.map((item) =>
                        item?.isbracket ? (
                          <Option
                            key={item.id_tournament}
                            value={item.id_tournament}
                          >
                            {item.nama_tournament}
                          </Option>
                        ) : null,
                      )
                    : null}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <h3>Detail Tournament</h3>
          <Row gutter={16}>
            {/* <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="nama_bracket_tournament"
                label="Nama Bracket Tournament"
                rules={[
                  {
                    required: true,
                    message: "Silahkan Masukkan Nama Bracket Tournament",
                  },
                ]}
              >
                <Input placeholder="Masukan Nama Bracket Tournament" />
              </Form.Item>
            </Col> */}
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="format_bracket"
                label="Format Bracket"
                rules={[
                  {
                    required: true,
                    message: "Silahkan Masukkan Format Bracket",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                  onChange={(value, select) => {
                    setSelectFormatTournament(select);
                  }}
                  placeholder="Pilih Format Bracket"
                >
                  {dataFormatBracket.map((item, value) => {
                    return (
                      <Option key={`${value + 1}`} value={`${value + 1}`}>
                        {item}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="id_bracket"
                label="Jumlah Bracket"
                rules={[
                  {
                    required: true,
                    message: "Silahkan Masukkan Jumlah Bracket",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                  onChange={(value, select) => {
                    setSelectJumlahBracketTournament(select);
                  }}
                  placeholder="Pilih Format Bracket"
                >
                  {dataListBracket.map((item, value) => {
                    return (
                      <Option key={item?.id_bracket} value={item?.id_bracket}>
                        {item?.nama_bracket}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginBottom: 20 }}>
            <Col span={24}>
              <Space className="dashboard-add-item-drawer-form-button">
                <Button onClick={onCloseAdd}>Batal</Button>
                <Button type="primary" htmlType="submit">
                  {openEditItem ? "Edit" : "Simpan"}
                </Button>
                {/* <Button
                  type="primary"
                  htmlType="button"
                  onClick={handleStartTournamentChallonge}
                >
                  Start Tournament
                </Button> */}
              </Space>
            </Col>
          </Row>
        </Form>
      </Spin>

      <Spin spinning={loadingDeleteItem} tip="Loading...">
        <Flex vertical style={{ marginBottom: 10 }}>
          <Space />

          <Input
            placeholder="Search"
            onChange={(e) => handleSearch(e.target.value)}
            value={searchText}
            style={{ marginBottom: "20px", width: "50%", marginTop: 30 }}
          />
        </Flex>

        <Table
          bordered={true}
          dataSource={
            searchText ? filteredDataSource : dataSourceListMemberTournament
          }
          columns={columns}
          rowKey="key"
          scroll={{
            x: 1600,
          }}
          pagination={{ pageSize: 20 }} //tambah
          onChange={handleTableChange} //tambah
        />
      </Spin>
      <Modal
        title="Detail Tournament"
        open={open}
        onOk={handleOk}
        okText={dataModal?.is_approved == "Approved" ? "Ok" : "Approve"}
        confirmLoading={confirmLoading}
        onCancel={() => setOpen(false)}
        cancelText={"Rejected"}
        width={"80%"}
        cancelButtonProps={{
          onClick: (e) => handleCancel(),
        }}
      >
        <Row>
          <Col span={10}>Nama Tournament</Col>
          <Col span={1}>:</Col>
          <Col span={10}>{dataModal?.nama_tournament} </Col>

          <Col span={10}>Deskripsi Team </Col>
          <Col span={1}>: </Col>
          <Col span={10}>{dataModal?.nama_team} </Col>

          <Col span={10}>Nama Team </Col>
          <Col span={1}>: </Col>
          <Col span={10}>{dataModal?.nama_team} </Col>

          <Col span={10}>Kategori Game </Col>
          <Col span={1}>: </Col>
          <Col span={10}>{dataModal?.nama_kategori_game} </Col>

          <Col span={10}>Status Approve </Col>
          <Col span={1}>: </Col>
          <Col span={10}>{dataModal?.is_approved}</Col>

          <Col span={10}>Tanggal Register </Col>
          <Col span={1}>: </Col>
          <Col span={10}>{moment().format("DD MMMM YYYY hh:mm")}</Col>
        </Row>
        <div style={{ marginTop: 20 }} />
        <Table
          bordered={true}
          dataSource={dataModal?.teams}
          columns={columnTeam}
          rowKey="key"
          scroll={{
            x: 1600,
          }}
          pagination={{ pageSize: 20 }} //tambah
          onChange={handleTableChange} //tambah
        />
      </Modal>
    </Layout>
  );
};

export default DashboardListBracketTournament;
