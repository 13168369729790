import { HomeOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Flex,
  Form,
  Input,
  Layout,
  message,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Constant from "../../../config/constans";
const { Text } = Typography;
const { Option } = Select;

const DashboardListMasterBracket = () => {
  const [openAddItem, setOpenAddItem] = useState(false);
  const [openEditItem, setOpenEditItem] = useState(false);
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [loadingAddItem, setLoadingAddItem] = useState(false);
  const [loadingEditItem, setLoadingEditItem] = useState(false);
  const [loadingDeleteItem, setLoadingDeleteItem] = useState(false);
  const [sortOrder, setSortOrder] = useState({
    columnKey: null,
    order: null,
  });
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const authToken = localStorage.getItem("token");
  const [formAdd] = Form.useForm();
  const [formEdit] = Form.useForm();
  const MAX_FILE_SIZE = 0.6;

  const columns = [
    {
      title: "Nama Bracket",
      dataIndex: "nama_bracket",
      key: "nama_bracket",
      width: "20%",
      sorter: (a, b) => a.nama_bracket.localeCompare(b.nama_bracket), //tambah
      sortOrder: sortOrder.columnKey === "nama_bracket" && sortOrder.order, //tambah
    },
    {
      title: "Bracket Value",
      dataIndex: "bracket_value",
      key: "bracket_value",
      width: "20%",
      sorter: (a, b) => a.bracket_value.localeCompare(b.bracket_value), //tambah
      sortOrder: sortOrder.columnKey === "bracket_value" && sortOrder.order, //tambah
    },
    {
      title: "Created Date",
      dataIndex: "createdat",
      key: "createDat",
      width: "20%",
      sorter: (a, b) => moment(a.createdat) - moment(b.createdat), //tambah
      sortOrder: sortOrder.columnKey === "createdat" && sortOrder.order, //tambah
    },
    {
      title: "Updated Date",
      dataIndex: "udpatedat",
      key: "udpatedat",
      width: "20%",
      sorter: (a, b) => moment(a.udpatedat) - moment(b.udpatedat), //tambah
      sortOrder: sortOrder.columnKey === "udpatedat" && sortOrder.order, //tambah
    },
    {
      title: "Actions",
      key: "actions",
      width: "20%",
      render: (_, record) => (
        <span style={{ display: "flex" }}>
          <Button onClick={() => showDrawerEdit(record)}>Edit</Button>
          <Popconfirm
            title="Delete Bracket"
            description="Apakah yakin ingin menghapus Bracket?"
            onConfirm={() => handleDelete(record)}
            okText="Hapus"
            cancelText="Tidak"
          >
            <Button style={{ marginLeft: 8 }} danger>
              Delete
            </Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  useEffect(() => {
    getListBracket();
  }, [loadData]);

  const getListBracket = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_MASTER_BRACKET;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          setLoadData(false);
          setData(response.data.Data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          localStorage.removeItem("token");
          localStorage.removeItem("id_admin");
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };

  const addListBracket = (payload) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_POST_ADD_LISTS_MASTER_BRACKET;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setSearchText(""); //tambah
            setLoadData(true);
            setLoadingAddItem(false);
            setOpenAddItem(false);
            messageApi.open({
              type: "success",
              content: "Success Add Team",
              duration: 4,
            });
            formAdd.resetFields();
          }, 1000);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
            setLoadingAddItem(false);
            setOpenAddItem(false);
          }, 1000);
        }
      });
  };
  const editListBracket = (payload) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_POST_EDIT_LISTS_MASTER_BRACKET;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setSearchText(""); //tambah
            setLoadData(true);
            setLoadingEditItem(false);
            setOpenEditItem(false);
            messageApi.open({
              type: "success",
              content: "Success Edit Team",
              duration: 4,
            });
            formAdd.resetFields();
          }, 1000);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
            setLoadingEditItem(false);
            setOpenEditItem(false);
            formAdd.resetFields();
          }, 1000);
        }
      });
  };
  const deleteListBracket = (payload) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_POST_DELETE_LISTS_MASTER_BRACKET;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setSearchText(""); //tambah
            setLoadData(true);
            setLoadingDeleteItem(false);
            messageApi.open({
              type: "success",
              content: "Success Delete Bracket",
              duration: 4,
            });
          }, 1000);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            setLoadingDeleteItem(false);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };

  const dataSourceListBracket = data
    ? data.map((item, index) => {
        const createdat = moment(item.createdat).format("DD MMMM YYYY");
        const updatedat = moment(item.udpatedat).format("DD MMMM YYYY");
        return {
          key: index,
          id_bracket: item.id_bracket,
          nama_bracket: item.nama_bracket,
          bracket_value: item.bracket_value,
          udpatedat: updatedat,
          createdat: createdat,
        };
      })
    : [];
  const handleDelete = (record) => {
    setLoadingDeleteItem(true);
    const valueId = {
      id_bracket: record.id_bracket,
    };
    deleteListBracket(valueId);
  };
  const showDrawerEdit = (record) => {
    setSelectedRowKey(record.key);
    setOpenEditItem(true);

    formAdd.setFieldsValue({
      id_bracket: record.id_bracket,
      nama_bracket: record.nama_bracket,
      bracket_value: record.bracket_value,
    });
  };

  const onCloseAdd = () => {
    formAdd.resetFields();
    setOpenAddItem(false);
    setLoadData(true);
  };
  const onCloseEdit = () => {
    formAdd.resetFields();
    setOpenEditItem(false);
    setLoadData(true);
  };
  const onFinishAdd = () => {
    setLoadingAddItem(true);
    const values = formAdd.getFieldsValue();
    addListBracket(values);
  };
  const onFinishEdit = () => {
    setLoadingEditItem(true);
    const values = formAdd.getFieldsValue();
    values.id_bracket = data[selectedRowKey].id_bracket;
    editListBracket(values);
  };

  const handleSearch = (value) => {
    setSearchText(value);

    const filteredData = dataSourceListBracket.filter((record) => {
      return Object.values(record).some(
        (fieldValue) =>
          fieldValue &&
          fieldValue.toString().toLowerCase().includes(value.toLowerCase()),
      );
    });

    setFilteredDataSource(filteredData);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    //tambah
    setSortOrder({
      columnKey: sorter.columnKey,
      order: sorter.order,
    });
  };

  return (
    <Layout>
      {contextHolder}
      <Breadcrumb
        items={[
          {
            href: "/dashboard/",
            title: (
              <>
                <HomeOutlined />
                <span>Dashboard</span>
              </>
            ),
          },
          {
            title: "List Master Bracket",
          },
        ]}
      />

      <Spin
        spinning={openEditItem ? loadingEditItem : loadingAddItem}
        tip="Loading..."
      >
        <Form
          layout="vertical"
          form={formAdd}
          className="dashboard-add-item-drawer-form"
          onFinish={openEditItem ? onFinishEdit : onFinishAdd}
        >
          <Row gutter={16}>
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="nama_bracket"
                label="Nama Bracket"
                rules={[
                  {
                    required: true,
                    message: "Silahkan Masukkan Nama Bracket",
                  },
                ]}
              >
                <Input placeholder="Masukan Nama Bracket" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="bracket_value"
                label="Value Bracket"
                rules={[
                  {
                    required: true,
                    message: "Silahkan Masukkan Value Bracket",
                  },
                ]}
              >
                <Input placeholder="Masukan Value Bracket" maxLength={2} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Space className="dashboard-add-item-drawer-form-button">
                <Button onClick={openEditItem ? onCloseEdit : onCloseAdd}>
                  Batal
                </Button>
                <Button type="primary" htmlType="submit">
                  {openEditItem ? "Edit" : "Tambah"}
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Spin>

      <Spin spinning={loadingDeleteItem} tip="Loading...">
        <Flex vertical>
          <Input
            placeholder="Search"
            onChange={(e) => handleSearch(e.target.value)}
            value={searchText}
            style={{ marginBottom: "20px", width: "50%", marginTop: 30 }}
          />
        </Flex>
        <Table
          bordered={true}
          dataSource={searchText ? filteredDataSource : dataSourceListBracket}
          columns={columns}
          rowKey="key"
          scroll={{
            x: 1600,
          }}
          pagination={{ pageSize: 20 }} //tambah
          onChange={handleTableChange} //tambah
        />
      </Spin>
    </Layout>
  );
};

export default DashboardListMasterBracket;
