import React, { useState, useEffect } from "react";
import './index.scss';
import { useNavigate } from "react-router-dom";
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Layout, Row, Col, Typography, Form, Input, Spin, Button, Space, message } from "antd";
import axios from "axios";

const { Title } = Typography;
const { Footer, Content } = Layout;

const Login = () => {
    const [loadingLogin, setLoadingLogin] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const login = (payload) => {
      axios
        .post("http://104.248.150.29/admin/login", payload)
        .then((response) => {
          console.log(response);
          if (response.status === 200 && response.data.Code === "0") {
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('id_admin', response.data.id_admin);
            messageApi.open({
              type: 'success',
              content: 'Success Login',
              duration: 4,
            });
            setTimeout(() => {
              setLoadingLogin(false)
              form.resetFields();
              navigate("/dashboard");
            },1000)
          } else {
            setLoadingLogin(false)
            messageApi.open({
              type: 'error',
              content: response.data.Message,
              duration: 4,
            });
          }
        })
        .catch((error) => {
          console.log(error, 'error');
          setTimeout(() => {
            setLoadingLogin(false)
            messageApi.open({
              type: 'error',
              content: error.message,
              duration: 4,
            });
            form.resetFields();
          },1000)
        });
    };

    const onFinish = (values) => {
        setLoadingLogin(true)
        login(values)
    };
    return (
      <Spin spinning={loadingLogin} tip="Loading...">
        {contextHolder}
        <Layout className="dashboard-login">
            <Content>
              <Row gutter={[16]}>
                <Col xs={{ span: 24 }} className="dashboard-login-form">
                  <Space className="dashboard-form">
                    <Title level={3}>Login</Title>
                    <Form form={form} onFinish={onFinish} layout="vertical">
                      <Row gutter={16}>
                        <Col xs={24}>
                          <Form.Item
                              label="Email"
                              name="email_admin"
                              rules={[
                                {
                                  type: 'email',
                                  message: 'The input is not valid E-mail!',
                                },
                                {
                                  required: true,
                                  message: "Please input your email!",
                                },
                              ]}
                          >
                              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" style={{ backgroundColor: 'unset' }} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col xs={24}>
                          <Form.Item
                              label="Password"
                              name="password_admin"
                              rules={[
                                  {
                                      required: true,
                                      message: "Please input your password!",
                                  },
                              ]}
                          >
                              <Input.Password
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Password"
                              />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col xs={24}>
                          <Form.Item>
                            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                                Sign In
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Space>
                </Col>
              </Row>
            </Content>
            <Footer className="dashboard-login-footer">
              <p>Score Quest ©2023</p>
            </Footer>
        </Layout>
      </Spin>

    );
};

export default Login;
