import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Constant from "../../../config/constans";
import { PlusOutlined, UploadOutlined, HomeOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Breadcrumb,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Layout,
  Typography,
  Table,
  Upload,
  Spin,
  Image,
  message,
  Popconfirm,
  Flex,
  DatePicker,
  Modal,
} from "antd";
import SunEditor from "suneditor-react";
import dayjs from "dayjs";
const { Text } = Typography;
const { Option } = Select;

const DashboardReportScoreNonBracket = () => {
  const [openAddItem, setOpenAddItem] = useState(false);
  const [openEditItem, setOpenEditItem] = useState(false);
  const [toggleJumlahPoint, setToggleJumlahPoint] = useState(false);
  const [data, setData] = useState([]);
  const [dataMaps, setDataMaps] = useState([]);
  const [dataKategoriGame, setDataKategoriGame] = useState([]);
  const [dataSubKategoriGame, setDataSubKategoriGame] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [loadDataBracket, setLoadDataBracket] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedKategoriGame, setSelectedKategoriGame] = useState(false);
  const [loadingAddItem, setLoadingAddItem] = useState(false);
  const [loadingEditItem, setLoadingEditItem] = useState(false);
  const [loadingDeleteItem, setLoadingDeleteItem] = useState(false);
  const [base64ImageEdit, setBase64ImageEdit] = useState("");
  const [base64ImageAdd, setBase64ImageAdd] = useState("");
  const [fileNameEditUploadItem, setFileNameEditUploadItem] = useState("");
  const [fileNameAddUploadItem, setFileNameAddUploadItem] = useState("");
  const [imageDimensionsAddTeam, setImageDimensionsAddTeam] = useState({
    width: 0,
    height: 0,
  });
  const [imageDimensionsEditTeam, setImageDimensionsEditTeam] = useState({
    width: 0,
    height: 0,
  });
  const [informationFile, setInformationFile] = useState(null);
  const [editImage, setEditImage] = useState("");
  const [sortOrder, setSortOrder] = useState({
    columnKey: null,
    order: null,
  });
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const authToken = localStorage.getItem("token");
  const [formAdd] = Form.useForm();
  const [formEdit] = Form.useForm();
  const MAX_FILE_SIZE = 0.6;
  const [dataListPlayerA, setDataListPlayerA] = useState([]);
  const [dataListPlayerB, setDataListPlayerB] = useState([]);
  const [dataListPlayerAModal, setDataListPlayerAModal] = useState([]);
  const [dataListPlayerBModal, setDataListPlayerBModal] = useState([]);
  const [dataScorePlayerReq, setDataScorePlayerReq] = useState([]);
  const [dataScorePlayerReqModal, setDataScorePlayerReqModal] = useState([]);
  const [dataListTournament, setdataListTournament] = useState([]);
  const [dataListTeam, setdataListTeam] = useState(false);
  const [selectTeamA, setSelectTeamA] = useState({});
  const [selectTeamB, setSelectTeamB] = useState({});
  const [selectTournament, setSelectTournament] = useState({});
  const [selectMaps, setSelectMaps] = useState({});
  const [isSearch, setIsSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const [dataFirstTop, setDataFirstTop] = useState([]);

  const handleOnChangeJumlahKill = (team, item, index, nama, value) => {
    console.log("itemasdfa", item);
    dataScorePlayerReq[index] = {
      ...dataScorePlayerReq[index],
      id_user: item?.teams[0]?.id_user,
      id_team: item?.id_team,
      [nama]: value,
    };

    let calc =
      parseInt(dataScorePlayerReq[index]?.jml_kill) +
      parseInt(dataScorePlayerReq[index]?.position);
    dataScorePlayerReq[index] = {
      ...dataScorePlayerReq[index],
      kalkulasi: `${calc < 1 ? 0 : calc}`,
    };
  };
  const showModal = (data) => {
    console.log("asdasdasssss", data);
    setOpen(true);
    setDataModal(data);
    setToggleJumlahPoint(false);

    setSelectTournament(data?.id_tournament);
  };

  const handleFirstCalc = () => {
    dataModal?.list_score_kill &&
      dataModal?.list_score_kill?.map((item, index) => {
        if (item?.jml_kill) {
          handleOnChangeJumlahKillModal(
            "A",
            item,
            index,
            "jml_kill",
            item?.jml_kill,
          );
        }
        if (item?.position) {
          handleOnChangeJumlahKillModal(
            "A",
            item,
            index,
            "position",
            item?.position,
          );
        }
      });
  };

  const handleOnChangeJumlahKillModal = (team, item, index, nama, value) => {
    dataScorePlayerReqModal[index] = {
      ...dataScorePlayerReqModal[index],
      tbl_score_kill_non_bracket: item?.tbl_score_kill_non_bracket,
      id_score_non_bracket: item?.id_score_non_bracket,
      id_score_kill: item?.id_score_kill,
      id_user: item?.id_user,
      id_team: item?.id_team,
      [nama]: value,
    };

    let calc =
      parseInt(dataScorePlayerReqModal[index]?.jml_kill) +
      parseInt(dataScorePlayerReqModal[index]?.position);
    dataScorePlayerReqModal[index] = {
      ...dataScorePlayerReqModal[index],
      kalkulasi: `${calc < 1 ? 0 : calc}`,
    };
  };

  const handleCalculate = () => {
    let tmp = [];
    dataScorePlayerReq.map((item, index) => {
      let calc = parseInt(item?.jml_kill) + parseInt(item?.position);
      console.log("calc", calc);
      tmp.push({
        ...item,
        kalkulasi: `${calc < 1 ? 0 : calc}`,
      });
    });
    setDataScorePlayerReq(tmp);
  };
  const handleCalculateModal = () => {
    let tmp = [];
    console.log("dataScorePlayerReqModal", dataScorePlayerReqModal);
    dataScorePlayerReqModal.map((item, index) => {
      let calc = parseInt(item?.jml_kill) + parseInt(item?.position);
      tmp.push({
        ...item,
        kalkulasi: `${calc < 1 ? 0 : calc}`,
      });
    });

    setDataScorePlayerReqModal(tmp);
  };

  const statusTournaments = [
    {
      label: "Active Dan Daftar",
      value: "1",
    },
    {
      label: "Active Dan Full",
      value: "2",
    },
    {
      label: "Inactive",
      value: "3",
    },
  ];

  const columns = [
    {
      title: "Nama Tournament",
      dataIndex: "name_tournament",
      key: "name_tournament",
      width: "20%",
      sorter: (a, b) => a.name_tournament.localeCompare(b.name_tournament), //tambah
      sortOrder: sortOrder.columnKey === "name_tournament" && sortOrder.order, //tambah
    },
    {
      title: "Uratan 1",
      dataIndex: "name_team",
      key: "name_team",
      width: "20%",
      sorter: (a, b) => a.name_team.localeCompare(b.name_team), //tambah
      sortOrder: sortOrder.columnKey === "name_team" && sortOrder.order, //tambah
    },

    {
      title: "Nama Maps",
      dataIndex: "name_maps",
      key: "name_maps",
      width: "20%",
      sorter: (a, b) => a.name_maps.localeCompare(b.name_maps), //tambah
      sortOrder: sortOrder.columnKey === "name_maps" && sortOrder.order, //tambah
    },
    {
      title: "Created Date",
      dataIndex: "createdat",
      key: "createDat",
      width: "20%",
      sorter: (a, b) => moment(a.createdat) - moment(b.createdat), //tambah
      sortOrder: sortOrder.columnKey === "createdat" && sortOrder.order, //tambah
    },
    {
      title: "Updated Date",
      dataIndex: "udpatedat",
      key: "udpatedat",
      width: "20%",
      sorter: (a, b) => moment(a.udpatedat) - moment(b.udpatedat), //tambah
      sortOrder: sortOrder.columnKey === "udpatedat" && sortOrder.order, //tambah
    },
    {
      title: "Actions",
      key: "actions",
      width: "20%",
      render: (_, record) => (
        <Space>
          <Button type="primary" onClick={() => showModal(record)}>
            Detail
          </Button>
          <Button onClick={() => showDrawerEdit(record)}>Edit</Button>
          {/* <Popconfirm
            title="Delete Tournament"
            description="Apakah yakin ingin menghapus Tournament?"
            onConfirm={() => handleDelete(record)}
            okText="Hapus"
            cancelText="Tidak"
          >
            <Button style={{ marginLeft: 8 }} danger>
              Delete
            </Button>
          </Popconfirm> */}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getListTeam();
  }, [selectTournament]);

  useEffect(() => {
    handleFirstCalc();
  }, [dataModal?.list_score_kill]);

  useEffect(() => {
    getListTeam();
    getListReportScoreNonBracket();
    getListTournament();
  }, [loadData]);

  useEffect(() => {
    if (selectTournament) {
      getListMasterMaps();
    }
  }, [selectTournament]);

  const handleUpdateScoreKill = async (payload) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const URL =
      Constant.URL_MASTER_PATH +
      Constant.URL_POST_LIST_UPDATE_KILL_REPORT_SCORE_NON_BRACKET;
    axios
      .post(URL, { ...payload }, { headers })
      .then((response) => {
        if (response.status === 200) {
          setLoadingDeleteItem(false);
          messageApi.open({
            type: "success",
            content: "Success Update",
            duration: 4,
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };

  const getListMasterMaps = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LIST_MASTER_MAP;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          let data = response.data.Data;
          let byTournament = dataListTournament?.find(
            (item) => item?.id_tournament == selectTournament,
          );
          let byCategoryGame = data?.filter(
            (item) => item?.id_kategori_game == byTournament?.id_kategori_game,
          );
          setDataMaps(byCategoryGame);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          localStorage.removeItem("token");
          localStorage.removeItem("id_admin");
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };

  const getListTournament = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_MASTER_ROOM_TOURNAMENT;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          setdataListTournament(response.data.Data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          localStorage.removeItem("token");
          localStorage.removeItem("id_admin");
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };

  const getListTeam = (team) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_DETAIL_TOURNAMENT;
    axios
      .post(
        URL,
        {
          id_tournament: selectTournament,
        },
        { headers },
      )
      .then((response) => {
        if (response.status === 200) {
          let listPesertaApprove = response.data.Data[0]?.list_peserta.filter(
            (item) => item?.is_approved == "2",
          );
          setDataFirstTop(
            listPesertaApprove.find(
              (item) => item.id_tournament == selectTournament,
            ),
          );
          setdataListTeam(listPesertaApprove);
        } else {
        }
      })
      .catch((error) => {
        setdataListTeam([]);
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          localStorage.removeItem("token");
          localStorage.removeItem("id_admin");
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };
  const getListReportScoreNonBracket = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH +
      Constant.URL_GET_LIST_MASTER_REPORT_SCORE_NON_BRACKET;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          setData(response.data.Data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };
  const getListSubKategoriGame = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_SUB_KATEGORI_GAME;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          setDataSubKategoriGame(response.data.Data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };
  const addListReportScoreNonBracket = (payload) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH +
      Constant.URL_POST_LIST_ADD_REPORT_SCORE_NON_BRACKET;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setSearchText(""); //tambah
            setLoadData(true);
            setLoadingAddItem(false);
            setOpenAddItem(false);
            window.location.reload();
            messageApi.open({
              type: "success",
              content: "Success Add Report Score Non Bracket",
              duration: 4,
            });
            formAdd.resetFields();
          }, 1000);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
            setLoadingAddItem(false);
            setOpenAddItem(false);
          }, 1000);
        }
      });
  };

  const editListReportScoreNonBracket = (payload) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH +
      Constant.URL_POST_LIST_UPDATE_REPORT_SCORE_NON_BRACKET;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setSearchText(""); //tambah
            setLoadData(true);
            setLoadingEditItem(false);
            setdataListTeam([]);
            messageApi.open({
              type: "success",
              content: "Success Edit Report Score Non Bracket",
              duration: 4,
            });
            formAdd.resetFields();
          }, 1000);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
            setLoadingAddItem(false);
            setOpenAddItem(false);
          }, 1000);
        }
      });
  };

  const dataSourceListTeam = data
    ? data.map((item, index) => {
        const createdat = moment(item.createdat).format("DD MMMM YYYY");
        const updatedat = moment(item.updatedat).format("DD MMMM YYYY");
        return {
          key: index,
          id_score_non_bracket: item?.id_score_non_bracket,
          id_tournament: item?.id_tournament,
          score_player_req: item?.score_player_req,
          name_tournament: item?.name_tournament,
          firsttop: item?.firsttop,
          id_map: item?.id_map,
          name_maps: item?.name_maps,
          name_team: item?.name_team,
          list_score_kill: item?.list_score_kill,
          createdat: createdat,
          udpatedat: updatedat,
        };
      })
    : [];

  const showDrawerEdit = (record) => {
    // getListSubKategoriGameByKategori(record.id_kategori_game);
    setSelectedRowKey(record.key);
    setOpenEditItem(true);
    setEditImage(record.banner);
    setSelectTournament(record?.id_tournament);
    formAdd.setFieldsValue({
      id_score_non_bracket: record?.id_score_non_bracket,
      id_tournament: record?.id_tournament,
      score_player_req: record?.score_player_req,
      name_tournament: record?.name_tournament,
      firsttop: record?.firsttop,
      id_map: record?.id_map,
      name_maps: record?.name_maps,
      list_score_kill: record?.list_score_kill,
      createdat: record.createdat,
      udpatedat: record.updatedat,
    });
  };
  const showDrawerAdd = () => {
    setOpenAddItem(true);
  };
  const onCloseAdd = () => {
    setInformationFile("");
    setBase64ImageAdd("");
    setFileNameAddUploadItem("");
    formAdd.resetFields();
    setOpenAddItem(false);
    setSelectedKategoriGame(false);
    setLoadData(true);
  };
  const onCloseEdit = () => {
    setInformationFile("");
    setBase64ImageEdit("");
    setFileNameEditUploadItem("");
    formAdd.resetFields();
    setOpenEditItem(false);
    setSelectedKategoriGame(false);
    setLoadData(true);
  };
  const onFinishAdd = () => {
    setLoadingAddItem(true);
    setSelectedKategoriGame(false);
    const values = formAdd.getFieldsValue();

    values.score_player_req = dataScorePlayerReq;
    console.log("values", values);
    setInformationFile("");
    addListReportScoreNonBracket(values);
  };

  const onFinishEdit = () => {
    setLoadingEditItem(true);

    setSelectedKategoriGame(false);
    const values = formAdd.getFieldsValue();
    console.log("values", values);
    values.id_score_non_bracket = data[selectedRowKey]?.id_score_non_bracket;
    setInformationFile("");
    editListReportScoreNonBracket(values);
  };

  const handleSearch = (value) => {
    setSearchText(value);

    const filteredData = dataSourceListTeam.filter((record) => {
      return Object.values(record).some(
        (fieldValue) =>
          fieldValue &&
          fieldValue.toString().toLowerCase().includes(value.toLowerCase()),
      );
    });

    setFilteredDataSource(filteredData);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    //tambah
    setSortOrder({
      columnKey: sorter.columnKey,
      order: sorter.order,
    });
  };
  const getImageDimensions = (url, setImageDimensions) => {
    const img = new window.Image();
    img.src = url;
    img.onload = () => {
      setImageDimensions({ width: img.width, height: img.height });
    };
  };
  const checkFileSize = (file) => {
    if (!file || !file.size) {
      return true;
    }
    const isLt600KB = file.size / 1024 / 1024 < MAX_FILE_SIZE;
    return isLt600KB;
  };
  const fileValidator = (_, value) => {
    const isFileValid = checkFileSize(informationFile);
    const valuesEdit = formAdd.getFieldsValue();
    if (!value || value.length === 0) {
      return Promise.reject(new Error("Please upload a file!"));
    }
    if (!isFileValid) {
      return Promise.reject(new Error(`File harus lebih kecil dari 600KB!`));
    }
    if (valuesEdit.banner && !isFileValid) {
      return Promise.reject(new Error(`File harus lebih kecil dari 600KB!`));
    }
    if (valuesEdit.banner && isFileValid) {
      return Promise.resolve();
    }
    return Promise.resolve();
  };
  return (
    <Layout>
      {contextHolder}
      <Breadcrumb
        items={[
          {
            href: "/dashboard/",
            title: (
              <>
                <HomeOutlined />
                <span>Dashboard</span>
              </>
            ),
          },
          {
            title: "Score Report Bracket Non Bracket",
          },
        ]}
      />
      <Spin
        spinning={openEditItem ? loadingEditItem : loadingAddItem}
        tip="Loading..."
      >
        <Form
          layout="vertical"
          form={formAdd}
          className="dashboard-add-item-drawer-form"
          onFinish={openEditItem ? onFinishEdit : onFinishAdd}
        >
          <h3>Report Score Non Bracket</h3>
          <Row gutter={16}>
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="id_tournament"
                label="Nama Tournament"
                rules={[
                  {
                    required: false,
                    message: "Silahkan Masukkan Nama Tournament",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                  placeholder="Pilih Nama Tournament"
                  onChange={(value, select) => {
                    setSelectTournament(value);
                    setToggleJumlahPoint(true);
                    console.log("www", select);
                  }}
                >
                  {dataListTournament
                    ? dataListTournament.map(
                        (item) => {
                          return item?.isbracket == false ? (
                            <Option
                              key={item.id_tournament}
                              value={item.id_tournament}
                            >
                              {item.nama_tournament}
                            </Option>
                          ) : null;
                        },
                        // ) : null,
                      )
                    : null}
                </Select>
              </Form.Item>
            </Col>
            {console.log("selectTournament", dataFirstTop)}
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="id_map"
                label="Nama Maps"
                rules={[
                  {
                    required: false,
                    message: "Silahkan Masukkan Nama Maps",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                  placeholder="Pilih Nama Maps"
                  onChange={(value, select) => {
                    setSelectMaps(select);
                  }}
                >
                  {console.log("asdfas", selectTournament, dataMaps)}
                  {dataMaps
                    ? dataMaps.map((item) => (
                        <Option key={item.id_maps} value={item.id_maps}>
                          {item.nama_maps}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="firsttop"
                label="Urutan 1"
                rules={[
                  {
                    required: false,
                    message: "Silahkan Masukkan Urutan 1",
                  },
                ]}
              >
                {console.log("asdfasdfasdf", dataFirstTop)}
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                  placeholder="Pilih First Top"
                  onChange={(value, select) => {
                    setSelectMaps(select);
                  }}
                >
                  {dataListTeam
                    ? dataListTeam.map((item) => (
                        <Option key={item.id_team} value={item.id_team}>
                          {item.nama_team}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {!openEditItem && toggleJumlahPoint && (
            <>
              <h3>Jumlah Point</h3>
              <Row gutter={24}>
                {/* TEAM AAAA */}

                <Flex vertical>
                  {dataListTeam &&
                    dataListTeam.map((item, index) => {
                      return (
                        <Col>
                          {/* <div style={{ marginBottom: 10 }}>{item?.nama_team}</div> */}
                          <Space style={{ marginBottom: 20 }}>
                            <Input
                              style={{
                                marginRight: 10,
                                width: "100%",
                                backgroundColor: "#fff",
                                color: "#000",
                              }}
                              placeholder="kill point"
                              value={item?.nama_team}
                              disabled
                            />
                            <Input
                              style={{ width: 100 }}
                              placeholder="kill point"
                              type="number"
                              min={0}
                              onChange={(event) => {
                                handleOnChangeJumlahKill(
                                  "A",
                                  item,
                                  index,
                                  "jml_kill",
                                  event?.target?.value,
                                );
                              }}
                            />

                            <Input
                              style={{ width: 100 }}
                              placeholder="placement point"
                              type="number"
                              min={0}
                              onChange={(event) => {
                                handleOnChangeJumlahKill(
                                  "A",
                                  item,
                                  index,
                                  "position",
                                  event?.target?.value,
                                );
                              }}
                            />

                            <Input
                              style={{
                                marginRight: 10,
                                backgroundColor: "#fff",
                                color: "#000",
                                width: 100,
                              }}
                              value={Number(
                                dataScorePlayerReq[index]?.kalkulasi || "",
                              )}
                              disabled
                            />
                          </Space>
                        </Col>
                      );
                    })}
                </Flex>
              </Row>
              <Space />

              <Button
                type="primary"
                htmlType="button"
                onClick={() => handleCalculate()}
              >
                {"Calculate"}
              </Button>
            </>
          )}

          <Space />

          <Row gutter={16} style={{ marginTop: 20 }}>
            <Col span={24}>
              <Space className="dashboard-add-item-drawer-form-button">
                <Button onClick={openEditItem ? onCloseEdit : onCloseAdd}>
                  Batal
                </Button>
                <Button type="primary" htmlType="submit">
                  {openEditItem ? "Edit" : "Simpan"}
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Spin>

      {/* <Spin spinning={loadingDeleteItem} tip="Loading...">
        <Flex style={{ marginTop: 20 }}>
          <Col span={24}>
            <iframe
              src="https://challonge.com/rjrf0d6t/module"
              width="100%"
              height="500"
              frameborder="0"
              scrolling="auto"
              allowtransparency="true"
            ></iframe>
          </Col>
        </Flex>
      </Spin> */}
      <div style={{ marginTop: 50 }} />

      <Spin
        // spinning={openEditItem ? loadingEditItem : loadingAddItem}
        spinning={false}
        tip="Loading..."
      >
        <Flex vertical>
          <Space />

          <Input
            placeholder="Search"
            onChange={(e) => handleSearch(e.target.value)}
            value={searchText}
            style={{ marginBottom: "20px", width: "50%", marginTop: 30 }}
          />
        </Flex>
        {console.log("objdataSourceListTeamect", dataSourceListTeam)}
        <Table
          bordered={true}
          dataSource={searchText ? filteredDataSource : dataSourceListTeam}
          columns={columns}
          rowKey="key"
          scroll={{
            x: 1600,
          }}
          pagination={{ pageSize: 20 }} //tambah
          onChange={handleTableChange} //tambah
        />
      </Spin>
      <Modal
        title="Jumlah Point"
        open={open}
        // onOk={handleOk}
        okText={dataModal?.is_approved == "Approved" ? "Ok" : "Approve"}
        confirmLoading={confirmLoading}
        onCancel={() => setOpen(false)}
        cancelText={"Rejected"}
        width={"80%"}
        footer={null}
        // cancelButtonProps={{
        //   onClick: (e) => handleCancel(),
        // }}
      >
        <Row gutter={24}>
          {/* TEAM AAAA */}

          <Col>
            <div
              onClick={() => {
                console.log(dataScorePlayerReq, "dataScorePlayerReq");
              }}
              style={{ marginBottom: 10 }}
            >
              Team
            </div>

            <Flex vertical>
              {dataModal?.list_score_kill &&
                dataModal?.list_score_kill?.map((item, index) => {
                  return (
                    <Space style={{ marginBottom: 20 }}>
                      <Input
                        style={{
                          marginRight: 10,
                          width: "100%",
                          backgroundColor: "#fff",
                          color: "#000",
                        }}
                        placeholder="kill point"
                        value={item?.name_team}
                        disabled
                      />
                      <Input
                        defaultValue={item?.jml_kill}
                        style={{ width: 100 }}
                        placeholder="kill point"
                        type="number"
                        min={0}
                        onChange={(event) => {
                          handleOnChangeJumlahKillModal(
                            "A",
                            item,
                            index,
                            "jml_kill",
                            event?.target?.value,
                          );
                        }}
                      />

                      <Input
                        defaultValue={item?.position}
                        style={{ width: 100 }}
                        placeholder="placement point"
                        type="number"
                        min={0}
                        onChange={(event) => {
                          handleOnChangeJumlahKillModal(
                            "A",
                            item,
                            index,
                            "position",
                            event?.target?.value,
                          );
                        }}
                      />

                      <Input
                        style={{
                          marginRight: 10,
                          backgroundColor: "#fff",
                          color: "#000",
                          width: 100,
                        }}
                        placeholder="kalkulasi"
                        value={
                          Number(dataScorePlayerReqModal[index]?.kalkulasi) ||
                          item?.kalkulasi ||
                          ""
                        }
                        disabled
                      />
                      <Button
                        type="primary"
                        onClick={() =>
                          handleUpdateScoreKill(dataScorePlayerReqModal[index])
                        }
                      >
                        Save
                      </Button>
                    </Space>
                  );
                })}
            </Flex>
          </Col>
        </Row>

        {dataModal?.list_score_kill?.length && (
          <Button
            type="primary"
            htmlType="button"
            onClick={() => handleCalculateModal()}
          >
            {"Calculate"}
          </Button>
        )}
      </Modal>
    </Layout>
  );
};

export default DashboardReportScoreNonBracket;
