import React, { useState, useEffect } from 'react';
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Constant from "../../../config/constans";
import { PlusOutlined, UploadOutlined, HomeOutlined } from '@ant-design/icons';
import { Button, Col, Breadcrumb, Drawer, Form, Input, Row, Select, Space, Layout, Typography, Table, Upload, Spin, Image, message, Popconfirm, Flex } from 'antd';
const { Text } = Typography;
const { Option } = Select;


const DashboardListVideo = () => {
  const [openAddItem, setOpenAddItem] = useState(false);
  const [openEditItem, setOpenEditItem] = useState(false);
  const [data, setData] = useState([]);
  const [dataKategoriGame, setDataKategoriGame] = useState([]);
  const [dataSubKategoriGame, setDataSubKategoriGame] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedKategoriGame, setSelectedKategoriGame] = useState(false);
  const [loadingAddItem, setLoadingAddItem] = useState(false);
  const [loadingEditItem, setLoadingEditItem] = useState(false);
  const [loadingDeleteItem, setLoadingDeleteItem] = useState(false);
  const [base64ImageEdit, setBase64ImageEdit] = useState('');
  const [base64ImageAdd, setBase64ImageAdd] = useState('');
  const [fileNameEditUploadItem, setFileNameEditUploadItem] = useState('');
  const [fileNameAddUploadItem, setFileNameAddUploadItem] = useState('');
  const [imageDimensionsAddVideo, setImageDimensionsAddVideo] = useState({ width: 0, height: 0 });
  const [imageDimensionsEditVideo, setImageDimensionsEditVideo] = useState({ width: 0, height: 0 });
  const [informationFile, setInformationFile] = useState(null)
  const [editImage, setEditImage] = useState('');
  const [sortOrder, setSortOrder] = useState({
    columnKey: null,
    order: null,
  });
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const authToken = localStorage.getItem('token');
  const [formAdd] = Form.useForm();
  const [formEdit] = Form.useForm();
  const MAX_FILE_SIZE = 0.6;

  const columns = [
    {
      title: 'Nama Video',
      dataIndex: 'nama_video',
      key: 'nama_video',
      width: "20%",
      sorter: (a, b) => a.nama_video.localeCompare(b.nama_video),
      sortOrder: sortOrder.columnKey === 'nama_video' && sortOrder.order,
    },
    {
      title: 'Deskripsi',
      dataIndex: 'deskripsi',
      key: 'deskripsi',
      width: "20%",
    },
    {
      title: 'URL Video',
      dataIndex: 'url_video',
      key: 'url_video',
      width: "20%",
    },
    {
      title: 'Thumbnail Video',
      dataIndex: 'thumbnail_video',
      key: 'thumbnail_video',
      width: "18%",
    },
    {
      title: 'Tanggal Video',
      dataIndex: 'tanggal_video',
      key: 'tanggal_video',
      width: "20%",
      sorter: (a, b) => moment(a.tanggal_video) - moment(b.tanggal_video),
      sortOrder: sortOrder.columnKey === 'tanggal_video' && sortOrder.order,
    },
    {
      title: 'Nama Kategori Game',
      dataIndex: 'nama_kategori_game',
      key: 'nama_kategori_game',
      width: "20%",
      sorter: (a, b) => a.nama_kategori_game.localeCompare(b.nama_kategori_game),
      sortOrder: sortOrder.columnKey === 'nama_kategori_game' && sortOrder.order,
    },
    {
      title: 'Nama Sub Kategori Game',
      dataIndex: 'nama_sub_kategori_game',
      key: 'nama_sub_kategori_game',
      width: "20%",
      sorter: (a, b) => a.nama_sub_kategori_game.localeCompare(b.nama_sub_kategori_game),
      sortOrder: sortOrder.columnKey === 'nama_sub_kategori_game' && sortOrder.order,
    },
    {
      title: 'Created Date',
      dataIndex: 'createdat',
      key: 'createDat',
      width: "20%",
      sorter: (a, b) => moment(a.createdat) - moment(b.createdat),
      sortOrder: sortOrder.columnKey === 'createdat' && sortOrder.order,
    },
    {
      title: 'Updated Date',
      dataIndex: 'udpatedat',
      key: 'udpatedat',
      width: "20%",
      sorter: (a, b) => moment(a.udpatedat) - moment(b.udpatedat),
      sortOrder: sortOrder.columnKey === 'udpatedat' && sortOrder.order,
    },
    {
      title: 'Actions',
      key: 'actions',
      width: "20%",
      render: (_, record) => (
        <span style={{display:'flex'}}>
          <Button onClick={() => showDrawerEdit(record)}>Edit</Button>
          <Popconfirm
            title="Delete Video"
            description="Apakah yakin ingin menghapus Video?"
            onConfirm={() => handleDelete(record)}
            okText= "Hapus"
            cancelText="Tidak"
          >
            <Button style={{ marginLeft: 8 }} danger>Delete</Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  useEffect(() => {
    getListVideo()
    getListKategoriGame()
    getListSubKategoriGame()
  }, [loadData]);


  const getListVideo = () => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_VIDEO;
    axios
    .get(URL, { headers })
    .then((response) => {
      if (response.status === 200) {
        setLoadData(false);
        setData(response.data.Data)
        setBase64ImageEdit('')
        setBase64ImageAdd('')
        setFileNameAddUploadItem('')
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        localStorage.removeItem('token');
        localStorage.removeItem('id_admin');
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      }
    });
  }
  const getListKategoriGame = () => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_KATEGORI_GAME;
    axios
    .get(URL, { headers })
    .then((response) => {
      if (response.status === 200) {
        setDataKategoriGame(response.data.Data)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      }
    });
  }
  const getListSubKategoriGame = () => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_SUB_KATEGORI_GAME;
    axios
    .get(URL, { headers })
    .then((response) => {
      if (response.status === 200) {
        setDataSubKategoriGame(response.data.Data)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      }
    });
  }

  const addListVideo = (payload) => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_ADD_LISTS_VIDEO;
    axios
    .post(URL, payload, { headers })
    .then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          setSearchText('')
          setLoadData(true)
          setLoadingAddItem(false);
          setOpenAddItem(false);
          messageApi.open({
            type: 'success',
            content: 'Success Add Video',
            duration: 4,
          });
          formAdd.resetFields();
        },1000)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      } else {
        setTimeout(() => {
          setLoadData(true)
          messageApi.open({
            type: 'error',
            content: error.message,
            duration: 4,
          });
          setLoadingAddItem(false);
          setOpenAddItem(false);
          formAdd.resetFields();
        },1000)
      }
    });
  }
  const editListVideo = (payload) => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_EDIT_LISTS_VIDEO;
    axios
    .post(URL, payload, { headers })
    .then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          setSearchText('')
          setLoadData(true)
          setLoadingEditItem(false);
          setOpenEditItem(false);
          messageApi.open({
            type: 'success',
            content: 'Success Edit Video',
            duration: 4,
          });
          formEdit.resetFields();
        },1000)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      } else {
        setTimeout(() => {
          setLoadData(true)
          messageApi.open({
            type: 'error',
            content: error.message,
            duration: 4,
          });
          setLoadingEditItem(false);
          setOpenEditItem(false);
          formEdit.resetFields();
        },1000)
      }
    });
  }
  const deleteListVideo = (payload) => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_DELETE_LISTS_VIDEO;
    axios
    .post(URL, payload, { headers })
    .then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          setSearchText('')
          setLoadData(true)
          setLoadingDeleteItem(false);
          messageApi.open({
            type: 'success',
            content: 'Success Delete Video',
            duration: 4,
          });
        },1000)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      } else {
        setTimeout(() => {
          setLoadData(true)
          setLoadingDeleteItem(false);
          messageApi.open({
            type: 'error',
            content: error.message,
            duration: 4,
          });
        },1000)
      }
    });
  }
  const getListSubKategoriGameByKategori = (payloadId) => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const payload = 
    {
      "id_kategori_game": payloadId
    }
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_LISTS_SUB_KATEGORI_GAME_BY_KATEGORI;
    axios
    .post(URL, payload, { headers })
    .then((response) => {
      if (response.status === 200) {
        setDataSubKategoriGame(response.data.Data)
        setSelectedKategoriGame(true);
        setLoadingAddItem(false)
        setLoadingEditItem(false)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      }
    });
  }

  const dataSourceListVideo = data
  ? data.map((item, index) => {
    const createdat = moment(item.createdat).format("DD MMMM YYYY")
    const updatedat = moment(item.udpatedat).format("DD MMMM YYYY")
    const tanggalVideo = moment(item.tanggal_video).format("DD MMMM YYYY")
    return {
      key: index,
      createdat: createdat,
      id_sub_kategori_game: item.id_sub_kategori_game,
      id_kategori_game: item.id_kategori_game,
      id_video: item.id_video,
      thumbnail_video: item.thumbnail_video,
      nama_video: item.nama_video,
      udpatedat: updatedat,
      tanggal_video: tanggalVideo,
      deskripsi: item.deskripsi,
      url_video: item.url_video,
      nama_kategori_game: item.nama_kategori_game,
      nama_sub_kategori_game: item.nama_sub_kategori_game,
    };
  })
  : null;

  const handleDelete = (record) => {
    setLoadingDeleteItem(true)
    const valueId = {
      "id_video": record.id_video
    }
    deleteListVideo(valueId)
  };

  const showDrawerEdit = (record) => {
    getListSubKategoriGameByKategori(record.id_kategori_game)
    setSelectedRowKey(record.key);
    setOpenEditItem(true);
    setEditImage(record.thumbnail_video)
    formEdit.setFieldsValue({
      createdat: record.createdat,
      id_sub_kategori_game: record.id_sub_kategori_game,
      id_kategori_game: record.id_kategori_game,
      id_video: record.id_video,
      thumbnail_video: record.thumbnail_video,
      nama_video: record.nama_video,
      udpatedat: record.udpatedat,
      tanggal_video: record.tanggal_video,
      deskripsi: record.deskripsi,
      url_video: record.url_video,
      nama_kategori_game: record.nama_kategori_game,
      nama_sub_kategori_game: record.nama_sub_kategori_game,
    });
  };

  const showDrawerAdd = () => {
    setOpenAddItem(true);
  };
  const onCloseAdd = () => {
    setInformationFile('')
    setBase64ImageAdd('')
    setFileNameAddUploadItem('')
    formAdd.resetFields();
    setOpenAddItem(false);
    setSelectedKategoriGame(false)
    setLoadData(true)
  };
  const onCloseEdit = () => {
    setInformationFile('')
    setBase64ImageEdit('')
    setFileNameEditUploadItem('')
    formEdit.resetFields();
    setOpenEditItem(false);
    setSelectedKategoriGame(false)
    setLoadData(true)
  };
  const onFinishAdd = () => {
    setLoadingAddItem(true)
    setSelectedKategoriGame(false)
    const values = formAdd.getFieldsValue();
    const regexUpload = /^data:image\/(\w+);base64,/;
    if (base64ImageAdd) {
      const matchUpload = base64ImageAdd.match(regexUpload);
      const imageWithoutPrefix = base64ImageAdd.substring(matchUpload[0].length);
      values.thumbnail_video = imageWithoutPrefix;
    }
    values.tanggal_video = moment().format();
    setInformationFile('')
    addListVideo(values)
  };
  const onFinishEdit = () => {
    setLoadingEditItem(true)
    setSelectedKategoriGame(false)
    const values = formEdit.getFieldsValue();
    const regexUpload = /^data:image\/(\w+);base64,/;
    if (base64ImageEdit) {
      const matchUpload = base64ImageEdit.match(regexUpload);
      const imageWithoutPrefix = base64ImageEdit.substring(matchUpload[0].length);
      values.thumbnail_video = imageWithoutPrefix;
    } else {
      values.thumbnail_video = '-'
    }
    values.id_video = data[selectedRowKey].id_video;
    values.tanggal_video = moment().format();
    setInformationFile('')
    editListVideo(values)
  };
  const beforeAddUploadItem = (file) => {
    setInformationFile(file)
    const isLt600KB = ((file.size / 1024) / 1024) < MAX_FILE_SIZE;
    if (!isLt600KB) {
      message.error(`File harus lebih kecil dari 600KB!`);
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setBase64ImageAdd(reader.result);
      getImageDimensions(reader.result, setImageDimensionsAddVideo);
    };

    const fileName = file.name;
    setFileNameAddUploadItem(fileName);

    return false;
  };
  const beforeEditUploadItem = (file) => {
    setInformationFile(file)
    const isLt600KB = ((file.size / 1024) / 1024) < MAX_FILE_SIZE;
    if (!isLt600KB) {
      message.error(`File harus lebih kecil dari 600KB!`);
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setBase64ImageEdit(reader.result);
      getImageDimensions(reader.result, setImageDimensionsEditVideo);
    };

    const fileName = file.name;
    setFileNameEditUploadItem(fileName);

    return false;
  };
  const handleAddKategoriGameChange = (value) => {
    setLoadingAddItem(true)
    getListSubKategoriGameByKategori(value)
  };
  const handleEditKategoriGameChange = (value) => {
    setLoadingEditItem(true)
    getListSubKategoriGameByKategori(value)
    formEdit.resetFields(['id_sub_kategori_game']);
  };
  const handleSearch = (value) => {
    setSearchText(value);
  
    const filteredData = dataSourceListVideo.filter((record) => {
      return Object.values(record).some(
        (fieldValue) =>
          fieldValue && fieldValue.toString().toLowerCase().includes(value.toLowerCase())
      );
    });
  
    setFilteredDataSource(filteredData);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    setSortOrder({
      columnKey: sorter.columnKey,
      order: sorter.order,
    });
  };
  const getImageDimensions = (url, setImageDimensions) => {
    const img = new window.Image();
    img.src = url;
    img.onload = () => {
      setImageDimensions({ width: img.width, height: img.height });
    };
  };
  const checkFileSize = (file) => {
    if (!file || !file.size) {
      return true;
    }
    const isLt600KB = ((file.size / 1024) / 1024) < MAX_FILE_SIZE;
    return isLt600KB;
  };
  const fileValidator = (_, value) => {
    const isFileValid = checkFileSize(informationFile);
    const valuesEdit = formEdit.getFieldsValue();
    if (!value || value.length === 0) {
      return Promise.reject(new Error('Please upload a file!'));
    }
    if (!isFileValid) {
      return Promise.reject(new Error(`File harus lebih kecil dari 600KB!`));
    }
    if (valuesEdit.thumbnail_video && !isFileValid) {
      return Promise.reject(new Error(`File harus lebih kecil dari 600KB!`));
    }
    if (valuesEdit.thumbnail_video && isFileValid) {
      return Promise.resolve();
    }
    return Promise.resolve();
  };
  return (
    <Layout>
      {contextHolder}
      <Breadcrumb
        items={[
          {
            href: '/dashboard/',
            title: (
              <>
                <HomeOutlined />
                <span>Dashboard</span>
              </>
            ),
          },
          {
            title: "List Video",
          },
        ]}
      />
      <Spin spinning={loadingDeleteItem} tip="Loading...">
        <Flex vertical>
          <Space className='dashboard-add-item'>
            <Button className='dashboard-add-item-button' type="primary" onClick={showDrawerAdd} icon={<PlusOutlined />}>Tambah Video</Button>  
            <Drawer
              className='dashboard-add-item-drawer'
              title="Tambah Video"
              width={720}
              closable={false}
              visible={openAddItem}
            >
              <Spin spinning={loadingAddItem} tip="Loading...">
                <Form layout="vertical" form={formAdd} className='dashboard-add-item-drawer-form' onFinish={onFinishAdd}>
                  <Row gutter={16}>
                    <Col xs={24} sm={16} lg={10}>
                      <Form.Item
                        name="nama_video"
                        label="Nama Video"
                        rules={[
                          {
                            required: true,
                            message: 'Silahkan Masukkan Nama Video',
                          },
                        ]}
                      >
                        <Input placeholder="Masukan Nama Video" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={16} lg={10}>
                      <Form.Item
                        name="id_kategori_game"
                        label="Nama Kategori Game"
                        rules={[
                          {
                            required: true,
                            message: 'Silahkan Masukkan Nama Kategori Game',
                          },
                        ]}
                      >
                        <Select
                          showSearch 
                          optionFilterProp="children" 
                          filterOption={
                            (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder="Pilih Kategori Game" 
                          onChange={handleAddKategoriGameChange}
                        >
                          {dataKategoriGame ? dataKategoriGame.map((item) => (
                            <Option key={item.id_kategori_game} value={item.id_kategori_game}>
                              {item.nama_kategori_game}
                            </Option>
                          )) : null}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  {
                    selectedKategoriGame ? 
                      <Row gutter={16}>
                        <Col xs={24} sm={16} lg={10}>
                          <Form.Item
                            name="id_sub_kategori_game"
                            label="Nama Sub Kategori Game"
                            rules={[
                              {
                                required: true,
                                message: 'Silahkan Masukkan Nama Sub Kategori Game',
                              },
                            ]}
                          >
                            <Select
                              showSearch 
                              optionFilterProp="children" 
                              filterOption={
                                (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              placeholder="Pilih Sub Kategori Game"
                            >
                              {dataSubKategoriGame ? dataSubKategoriGame.map((item) => (
                                <Option key={item.id_sub_kategori_game} value={item.id_sub_kategori_game}>
                                  {item.nama_sub_kategori_game}
                                </Option>
                              )) : null}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row> 
                    : (null)
                  }
                  <Row gutter={16}>
                    <Col xs={24} sm={16} lg={10}>
                      <Form.Item
                        name="deskripsi"
                        label="Deskripsi"
                        rules={[
                          {
                            required: true,
                            message: 'Silahkan Masukkan Nama Deskripsi',
                          },
                        ]}
                      >
                        <Input placeholder="Masukan Nama Deskripsi" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={16} lg={10}>
                      <Form.Item
                        name="url_video"
                        label="URL Video"
                        rules={[
                          {
                            required: true,
                            message: 'Silahkan Masukkan URL Video',
                          },
                        ]}
                      >
                        <Input placeholder="Masukan URL Video" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={16} lg={10}>
                      <Form.Item
                        name="thumbnail_video"
                        label="Thumbnail Video"
                        rules={[
                          {
                            validator: fileValidator,
                          },
                        ]}
                      >
                        <Space className='upload-images'>
                          <Upload
                            customRequest={() => {}}
                            fileList={[]}
                            beforeUpload={beforeAddUploadItem}
                            onRemove={() => setBase64ImageAdd('')}
                          >
                            <Button icon={<UploadOutlined />}>Select Image</Button>
                          </Upload>
                          {fileNameAddUploadItem}
                          {base64ImageAdd && (
                            <Text>
                              Image Dimensions (Thumbnail): {imageDimensionsAddVideo.width} x {imageDimensionsAddVideo.height}
                            </Text>
                          )}
                        </Space>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Space className='dashboard-add-item-drawer-form-button'>
                        <Button onClick={onCloseAdd}>Batal</Button>
                        <Button  type="primary" htmlType='submit'>Tambah</Button>
                      </Space>
                    </Col>
                  </Row>
                </Form>
              </Spin>
            </Drawer>
          </Space>
          <Input
            placeholder="Search"
            onChange={(e) => handleSearch(e.target.value)}
            value={searchText}
            style={{marginBottom: '20px', width: '50%'}}
          />
        </Flex>
        <Table
          bordered={true}
          dataSource={searchText ? filteredDataSource : dataSourceListVideo}
          columns={columns}
          rowKey="key"
          scroll={{
            x: 1400,
          }}
          pagination={{ pageSize: 20 }}
          onChange={handleTableChange}
        />
        <Drawer
          width={720}
          title="Edit Team"
          closable={false}
          visible={openEditItem}
        >
          <Spin spinning={loadingEditItem} tip="Loading...">
            <Form layout="vertical" form={formEdit} onFinish={onFinishEdit} className='dashboard-edit-item-drawer-form'>
            <Row gutter={16}>
              <Col xs={24} sm={16} lg={10}>
                <Form.Item
                  name="nama_video"
                  label="Nama Video"
                  rules={[
                    {
                      required: true,
                      message: 'Silahkan Masukkan Nama Video',
                    },
                  ]}
                >
                  <Input placeholder="Masukan Nama Video" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={16} lg={10}>
                <Form.Item
                  name="id_kategori_game"
                  label="Nama Kategori Game"
                  rules={[
                    {
                      required: true,
                      message: 'Silahkan Masukkan Nama Kategori Game',
                    },
                  ]}
                >
                  <Select
                    showSearch 
                    optionFilterProp="children" 
                    filterOption={
                      (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Pilih Kategori Game" 
                    onChange={handleEditKategoriGameChange}
                  >
                    {dataKategoriGame ? dataKategoriGame.map((item) => (
                      <Option key={item.id_kategori_game} value={item.id_kategori_game}>
                        {item.nama_kategori_game}
                      </Option>
                    )) : null}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={16} lg={10}>
                <Form.Item
                  name="id_sub_kategori_game"
                  label="Nama Sub Kategori Game"
                  rules={[
                    {
                      required: true,
                      message: 'Silahkan Masukkan Nama Sub Kategori Game',
                    },
                  ]}
                >
                  <Select
                    showSearch 
                    optionFilterProp="children" 
                    filterOption={
                      (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Pilih Sub Kategori Game"
                  >
                    {dataSubKategoriGame ? dataSubKategoriGame.map((item) => (
                      <Option key={item.id_sub_kategori_game} value={item.id_sub_kategori_game}>
                        {item.nama_sub_kategori_game}
                      </Option>
                    )) : null}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={16} lg={10}>
                <Form.Item
                  name="deskripsi"
                  label="Deskripsi"
                  rules={[
                    {
                      required: true,
                      message: 'Silahkan Masukkan Nama Deskripsi',
                    },
                  ]}
                >
                  <Input placeholder="Masukan Nama Deskripsi" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={16} lg={10}>
                <Form.Item
                  name="url_video"
                  label="URL Video"
                  rules={[
                    {
                      required: true,
                      message: 'Silahkan Masukkan URL Video',
                    },
                  ]}
                >
                  <Input placeholder="Masukan URL Video" />
                </Form.Item>
              </Col>
            </Row>
              <Row gutter={16}>
                <Col xs={24} sm={16} lg={10}>
                  <Form.Item
                    name="thumbnail_video"
                    label="Thumbnail Video"
                    rules={[
                      {
                        validator: fileValidator,
                      },
                    ]}
                  >
                    {
                      base64ImageEdit ? 
                      null : 
                        <Space className='preview-image'>
                          <Text>Preview Thumbnail Video</Text>
                          <Image
                            width={200}
                            src={editImage}
                          />
                        </Space>
                    }
                    <Space className='upload-images'>
                      <Upload
                        customRequest={() => {}}
                        fileList={[]}
                        beforeUpload={beforeEditUploadItem}
                        onRemove={() => setBase64ImageEdit('')}
                      >
                        <Button icon={<UploadOutlined />}>Select Image</Button>
                      </Upload>
                      {fileNameEditUploadItem}
                      {base64ImageEdit && (
                        <Text>
                          Image Dimensions (Thumbnail): {imageDimensionsEditVideo.width} x {imageDimensionsEditVideo.height}
                        </Text>
                      )}
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Space className='dashboard-edit-item-drawer-form-button'>
                    <Button onClick={onCloseEdit}>Batal</Button>
                    <Button  type="primary" htmlType='submit'>Update</Button>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Drawer>
      </Spin>
    </Layout>
  )
};

export default DashboardListVideo;
