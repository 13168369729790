import React, { useState, useEffect } from 'react';
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Constant from "../../../config/constans";
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Table, message, Input, Button, Flex } from 'antd';
import * as XLSX from 'xlsx';

const DashboardListUser = () => {
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [sortOrder, setSortOrder] = useState({
    columnKey: null,
    order: null,
  });
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const authToken = localStorage.getItem('token');

  const columns = [
    {
      title: 'Nama',
      dataIndex: 'nama',
      key: 'nama',
      width: "20%",
      sorter: (a, b) => a.nama.localeCompare(b.nama),
      sortOrder: sortOrder.columnKey === 'nama' && sortOrder.order,
    },
    {
      title: 'Nomor Handphone',
      dataIndex: 'no_hp',
      key: 'no_hp',
      width: "10%",
      sorter: (a, b) => a.no_hp.localeCompare(b.no_hp),
      sortOrder: sortOrder.columnKey === 'no_hp' && sortOrder.order,
    },
    {
      title: 'Jenis Kelamin',
      dataIndex: 'jenis_kelamin',
      key: 'jenis_kelamin',
      width: "10%",
      sorter: (a, b) => a.jenis_kelamin.localeCompare(b.jenis_kelamin),
      sortOrder: sortOrder.columnKey === 'jenis_kelamin' && sortOrder.order,
      render: (text) => (
        <p style={{ textAlign: 'center' }}>{text}</p>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: "20%",
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortOrder: sortOrder.columnKey === 'email' && sortOrder.order,
    },
    {
      title: 'Device Id',
      dataIndex: 'device_id',
      key: 'device_id',
      width: "10%",
      sorter: (a, b) => a.device_id.localeCompare(b.device_id),
      sortOrder: sortOrder.columnKey === 'device_id' && sortOrder.order,
      render: (text) => (
        <p style={{ textAlign: 'center' }}>{text}</p>
      ),
    },
    {
      title: 'Tanggal Lahir',
      dataIndex: 'tanggal_lahir',
      key: 'tanggal_lahir',
      width: "20%",
      sorter: (a, b) => a.tanggal_lahir.localeCompare(b.tanggal_lahir),
      sortOrder: sortOrder.columnKey === 'tanggal_lahir' && sortOrder.order,
    },
    {
      title: 'Foto Profil',
      dataIndex: 'photo_profile',
      key: 'photo_profile',
      width: "10%",
    },
    {
      title: 'Created Date',
      dataIndex: 'createdat',
      key: 'createDat',
      width: "20%",
      sorter: (a, b) => a.createdat.localeCompare(b.createdat),
      sortOrder: sortOrder.columnKey === 'createdat' && sortOrder.order,
    },
    {
      title: 'Updated Date',
      dataIndex: 'updatedat',
      key: 'updatedat',
      width: "20%",
      sorter: (a, b) => a.updatedat.localeCompare(b.updatedat),
      sortOrder: sortOrder.columnKey === 'updatedat' && sortOrder.order,
    },
  ];

  useEffect(() => {
    getListUsers();
  }, [loadData]);

  const getListUsers = () => {
    const headers = {
      "Authorization": `Bearer ${authToken}`
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_USER;
    axios
    .get(URL, { headers })
    .then((response) => {
      if (response.status === 200) {
        setLoadData(false);
        setData(response.data.Data)
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
        localStorage.removeItem('token');
        localStorage.removeItem('id_admin');
        messageApi.open({
          type: 'error',
          content: error.message,
          duration: 4,
        });
        setTimeout(() => {
          navigate("/");
        },1000)
      }
    });
  }

  const dataSourceListUsers = data
  ? data.map((item, index) => {
    const jenisKelamin = item.jenis_kelamin === 'L' ? "Laki - Laki" : "Perempuan"
    const tanggalLahir = moment(item.tanggal_lahir).format("DD MMMM YYYY")
    const createdat = moment(item.create_date).format("DD MMMM YYYY")
    const updatedat = moment(item.update_date).format("DD MMMM YYYY")
    const checkDeviceId = item.device_id ? item.device_id : '-';
    return {
      key: index,
      createdat: createdat,
      updatedat: updatedat,
      id_user: item.id_user,
      nama: item.nama,
      no_hp: item.no_hp,
      jenis_kelamin: jenisKelamin,
      email: item.email,
      device_id: checkDeviceId,
      tanggal_lahir: tanggalLahir,
      photo_profile: item.photo_profile,
    };
  })
  : null;

  const handleSearch = (value) => {
    setSearchText(value);
  
    const filteredData = dataSourceListUsers.filter((record) => {
      return Object.values(record).some(
        (fieldValue) =>
          fieldValue && fieldValue.toString().toLowerCase().includes(value.toLowerCase())
      );
    });
  
    setFilteredDataSource(filteredData);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setSortOrder({
      columnKey: sorter.columnKey,
      order: sorter.order,
    });
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(dataSourceListUsers);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');
    XLSX.writeFile(workbook, 'users.xlsx');
  };

  return (
    <Layout>
      {contextHolder}
      <Breadcrumb
        items={[
          {
            href: '/dashboard/',
            title: (
              <>
                <HomeOutlined />
                <span>Dashboard</span>
              </>
            ),
          },
          {
            title: "List Users",
          },
        ]}
      />
      <Flex justify='space-between'>
        <Input
          placeholder="Search"
          onChange={(e) => handleSearch(e.target.value)}
          value={searchText}
          style={{marginBottom: '20px', width: '50%'}}
        />
        <Button onClick={exportToExcel} style={{ marginBottom: '20px' }}>Export to Excel</Button>
      </Flex>
      <Table
        bordered={true}
        dataSource={searchText ? filteredDataSource : dataSourceListUsers}
        columns={columns}
        rowKey="key"
        scroll={{
          x: 1600,
        }}
        pagination={{ pageSize: 20 }}
        onChange={handleTableChange}
      />
    </Layout>
  )
};

export default DashboardListUser;
