import { HomeOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  Flex,
  Form,
  Image,
  Input,
  Layout,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tabs,
  Typography,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Constant from "../../../config/constans";
import { uniq, uniqBy } from "lodash";
const { Text } = Typography;
const { Option } = Select;

const DashboardScoreboard = () => {
  const [openAddItem, setOpenAddItem] = useState(false);
  const [openEditItem, setOpenEditItem] = useState(false);
  const [data, setData] = useState([]);
  const [dataNonBracket, setDataNonBracket] = useState([]);
  const [dataBracket, setDataBracket] = useState([]);
  const [dataKategoriGame, setDataKategoriGame] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [filteredDataSourceBracket, setFilteredDataSourceBracket] = useState(
    [],
  );
  const [filteredDataSourceNonBracket, setFilteredDataSourceNonBracket] =
    useState([]);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedKategoriGame, setSelectedKategoriGame] = useState(false);
  const [loadingAddItem, setLoadingAddItem] = useState(false);
  const [loadingEditItem, setLoadingEditItem] = useState(false);
  const [loadingDeleteItem, setLoadingDeleteItem] = useState(false);
  const [selectTournament, setSelectTournament] = useState({});

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [dataModal, setDataModal] = useState({});

  const showModal = (data) => {
    setOpen(true);
    setDataModal(data);
    setSelectTournament(data?.id_tournament);
  };

  const handleOk = () => {
    if (!dataModal?.is_approved !== "Approved") {
      setConfirmLoading(true);

      handleApprove(true);
    } else {
      setOpen(false);
    }
  };

  const handleCancel = (e) => {
    console.log("Clicked cancel button", e);
    handleApprove(false);
  };

  const [sortOrder, setSortOrder] = useState({
    columnKey: null,
    order: null,
  });
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const authToken = localStorage.getItem("token");
  const [formAdd] = Form.useForm();
  const [formEdit] = Form.useForm();
  const MAX_FILE_SIZE = 0.6;

  const columnsBracket = [
    {
      title: "Nama Tournament ",
      dataIndex: "name_tournament",
      key: "name_tournament",
      width: "20%",
      sorter: (a, b) => a.name_tournament.localeCompare(b.name_tournament), //tambah
      sortOrder: sortOrder.columnKey === "name_tournament" && sortOrder.order, //tambah
    },
    {
      title: "image_team_a",
      dataIndex: "image_team_a",
      key: "image_team_a",
      width: "20%",
      sorter: (a, b) => a.image_team_a.localeCompare(b.image_team_a), //tambah
      sortOrder: sortOrder.columnKey === "image_team_a" && sortOrder.order, //tambah
      render: (_, record) => (
        <Image
          src={record?.image_team_a}
          style={{ width: 60, height: 60, borderRadius: 100 }}
        />
      ),
    },
    {
      title: "Nama Team A",
      dataIndex: "name_team_a",
      key: "name_team_a",
      width: "20%",
      sorter: (a, b) => a.name_team_a.localeCompare(b.name_team_a), //tambah
      sortOrder: sortOrder.columnKey === "name_team_a" && sortOrder.order, //tambah
    },
    {
      title: "Score Team A",
      dataIndex: "score_team_a",
      key: "score_team_a",
      width: "20%",
      sorter: (a, b) => a.score_team_a.localeCompare(b.score_team_a), //tambah
      sortOrder: sortOrder.columnKey === "score_team_a" && sortOrder.order, //tambah
    },
    {
      title: "image_team_b",
      dataIndex: "image_team_b",
      key: "image_team_b",
      width: "20%",
      sorter: (a, b) => a.image_team_b.localeCompare(b.image_team_b), //tambah
      sortOrder: sortOrder.columnKey === "image_team_b" && sortOrder.order, //tambah
      render: (_, record) => (
        <Image
          src={record?.image_team_b}
          style={{ width: 60, height: 60, borderRadius: 100 }}
        />
      ),
    },
    {
      title: "Nama Team B",
      dataIndex: "name_team_b",
      key: "name_team_b",
      width: "20%",
      sorter: (a, b) => a.name_team_b.localeCompare(b.name_team_b), //tambah
      sortOrder: sortOrder.columnKey === "name_team_b" && sortOrder.order, //tambah
    },
    {
      title: "Score Team B",
      dataIndex: "score_team_b",
      key: "score_team_b",
      width: "20%",
      sorter: (a, b) => a.score_team_b.localeCompare(b.score_team_b), //tambah
      sortOrder: sortOrder.columnKey === "score_team_b" && sortOrder.order, //tambah
    },
    {
      title: "Nama MVP",
      dataIndex: "name_mvp",
      key: "name_mvp",
      width: "20%",
      sorter: (a, b) => a.name_mvp.localeCompare(b.name_mvp), //tambah
      sortOrder: sortOrder.columnKey === "name_mvp" && sortOrder.order, //tambah
    },
    {
      title: "Nama Winner",
      dataIndex: "name_winner",
      key: "name_winner",
      width: "20%",
      sorter: (a, b) => a.name_winner.localeCompare(b.name_winner), //tambah
      sortOrder: sortOrder.columnKey === "name_winner" && sortOrder.order, //tambah
    },

    {
      title: "Created Date",
      dataIndex: "createdat",
      key: "createDat",
      width: "20%",
      sorter: (a, b) => moment(a.createdat) - moment(b.createdat), //tambah
      sortOrder: sortOrder.columnKey === "createdat" && sortOrder.order, //tambah
    },
    {
      title: "Updated Date",
      dataIndex: "udpatedat",
      key: "udpatedat",
      width: "20%",
      sorter: (a, b) => moment(a.udpatedat) - moment(b.udpatedat), //tambah
      sortOrder: sortOrder.columnKey === "udpatedat" && sortOrder.order, //tambah
    },
    // {
    //   title: "Actions",
    //   key: "actions",
    //   width: "20%",
    //   render: (_, record) => (
    //     <Flex gap={"small"}>
    //       <Popconfirm
    //         title="Delete "
    //         description="Apakah yakin ingin menghapus ?"
    //         onConfirm={() => handleDelete(record)}
    //         okText="Hapus"
    //         cancelText="Tidak"
    //       >
    //         <Button style={{ marginLeft: 8 }} danger>
    //           Delete
    //         </Button>
    //       </Popconfirm>
    //     </Flex>
    //   ),
    // },
  ];

  const columnTeam = [
    {
      title: "Photo Profile ",
      dataIndex: "photo_profile",
      key: "photo_profile",
      width: "20%",
      sorter: (a, b) => a.photo_profile.localeCompare(b.photo_profile), //tambah
      sortOrder: sortOrder.columnKey === "photo_profile" && sortOrder.order, //tambah
      render: (_, record) => (
        <Image
          src={record?.photo_profile}
          style={{ width: 60, height: 60, borderRadius: 100 }}
        />
      ),
    },
    {
      title: "Nama  ",
      dataIndex: "nama",
      key: "nama",
      width: "20%",
      sorter: (a, b) => a.nama.localeCompare(b.nama), //tambah
      sortOrder: sortOrder.columnKey === "nama" && sortOrder.order, //tambah
    },
    {
      title: "Nickname ",
      dataIndex: "nickname",
      key: "nickname",
      width: "20%",
      sorter: (a, b) => a.nickname.localeCompare(b.nickname), //tambah
      sortOrder: sortOrder.columnKey === "nickname" && sortOrder.order, //tambah
    },

    {
      title: "Email ",
      dataIndex: "email",
      key: "email",
      width: "20%",
      sorter: (a, b) => a.email.localeCompare(b.email), //tambah
      sortOrder: sortOrder.columnKey === "email" && sortOrder.order, //tambah
    },
    {
      title: "badge_royalty ",
      dataIndex: "badge_royalty",
      key: "badge_royalty",
      width: "20%",
      sorter: (a, b) => a.badge_royalty.localeCompare(b.badge_royalty), //tambah
      sortOrder: sortOrder.columnKey === "badge_royalty" && sortOrder.order, //tambah
    },
    {
      title: "No HP ",
      dataIndex: "no_hp",
      key: "no_hp",
      width: "20%",
      sorter: (a, b) => a.no_hp.localeCompare(b.no_hp), //tambah
      sortOrder: sortOrder.columnKey === "no_hp" && sortOrder.order, //tambah
    },
  ];

  let refTabs = useRef();
  useEffect(() => {
    getListMaxMember();
    getListKategoriGame();
    onChange(1);
  }, [loadData]);

  const getListMaxMember = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_GET_LIST_MASTER_SCOREBOARD;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          setLoadData(false);
          setDataBracket(response.data.Data[0]?.scoreBracket);
          setDataNonBracket(response.data.Data[1]?.scoreNonBracket);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          localStorage.removeItem("token");
          localStorage.removeItem("id_admin");
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };
  const getListKategoriGame = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_KATEGORI_GAME;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          setDataKategoriGame(response.data.Data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };

  const addListMaxMember = (payload) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_POST_ADD_LISTS_MASTER_MAXMEMBER;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setSearchText(""); //tambah
            setLoadData(true);
            setLoadingAddItem(false);
            setOpenAddItem(false);
            messageApi.open({
              type: "success",
              content: "Success Add Max Member",
              duration: 4,
            });
            formAdd.resetFields();
          }, 1000);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
            setLoadingAddItem(false);
            setOpenAddItem(false);
          }, 1000);
        }
      });
  };

  const handleApprove = (is_approved) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_POST_APPROVED_TOURNAMENT;
    axios
      .post(
        URL,
        {
          id_room_tournament: dataModal?.id_room_tournament,
          is_approved: is_approved,
        },
        { headers },
      )
      .then((response) => {
        if (response.status === 200) {
          setConfirmLoading(false);
          setLoadData(!loadData);
          setTimeout(() => {
            messageApi.open({
              type: "success",
              content: is_approved ? "Approved !" : "Rejected !",
              duration: 4,
            });

            setOpen(false);

            // formAdd.resetFields();
          }, 1000);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
            setLoadingAddItem(false);
            setOpenAddItem(false);
          }, 1000);
        }
      });
  };
  const editListMaxMember = (payload) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_POST_EDIT_LISTS_MASTER_MAXMEMBER;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setSearchText(""); //tambah
            setLoadData(true);
            setLoadingEditItem(false);
            setOpenEditItem(false);
            messageApi.open({
              type: "success",
              content: "Success Edit Max Member",
              duration: 4,
            });
            formAdd.resetFields();
          }, 1000);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
            setLoadingEditItem(false);
            setOpenEditItem(false);
            formAdd.resetFields();
          }, 1000);
        }
      });
  };
  const deleteMemberPeserta = (payload) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_DELETE_PESERTA_TOURNAMENT;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setSearchText(""); //tambah
            setLoadData(true);
            setLoadingDeleteItem(false);
            messageApi.open({
              type: "success",
              content: "Success Delete Member",
              duration: 4,
            });
          }, 1000);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadData(true);
            setLoadingDeleteItem(false);
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };

  const dataSourceListBracket = dataBracket
    ? dataBracket.map((item, index) => {
        const createdat = moment(item.createdat).format("DD MMMM YYYY");
        const updatedat = moment(item.udpatedat).format("DD MMMM YYYY");
        const registdat = moment(item.date_register).format("DD MMMM YYYY");
        return {
          key: index,
          id_score: item?.id_score,
          id_tournament: item?.id_tournament,
          id_team_a: item?.id_team_a,
          id_team_b: item?.id_team_b,
          score_team_a: item?.score_team_a,
          score_team_b: item?.score_team_b,
          mvp: item?.mvp,
          winner: item?.winner,

          name_team_a: item?.name_team_a,
          name_team_b: item?.name_team_b,
          name_mvp: item?.name_mvp,
          name_winner: item?.name_winner,
          id_kategori_game: item?.id_kategori_game,
          name_kategori: item?.name_kategori,
          name_tournament: item?.name_tournament,
          image_team_a: item?.image_team_a,
          image_team_b: item?.image_team_b,
          createdat: createdat,
          udpatedat: updatedat,
        };
      })
    : [];

  const dataSourceListNonBracket = dataNonBracket
    ? dataNonBracket.map((item, index) => {
        const createdat = moment(item.createdat).format("DD MMMM YYYY");
        const updatedat = moment(item.udpatedat).format("DD MMMM YYYY");
        const registdat = moment(item.date_register).format("DD MMMM YYYY");
        return {
          key: index,
          id_score_non_bracket: item?.id_score_non_bracket,
          id_tournament: item?.id_tournament,
          score_player_req: item?.score_player_req,
          name_tournament: item?.name_tournament,
          firsttop: item?.firsttop,
          id_map: item?.id_map,
          name_maps: item?.name_maps,
          name_team: item?.name_team,
          list_score: item?.list_score,
          createdat: createdat,
          udpatedat: updatedat,
        };
      })
    : [];

  const handleDelete = (record) => {
    setLoadingDeleteItem(true);
    const valueId = {
      id_room_tournament: record.id_room_tournament,
    };
    deleteMemberPeserta(valueId);
  };
  const showDrawerEdit = (record) => {
    setSelectedRowKey(record.key);
    setOpenEditItem(true);
    formAdd.setFieldsValue({
      badger: record?.badger,
      banner: record?.banner,
      date_register: record?.date_register,
      deskripsi_team: record?.deskripsi_team,
      id_room_tournament: record?.id_room_tournament,
      id_team: record?.id_team,
      id_tournament: record?.id_tournament,
      image_team: record?.image_team,
      is_approved: record?.is_approved ? "Approved" : "Rejected",
      nama_team: record?.nama_team,
      nama_tournament: record?.nama_tournament,
      teams: record?.teams,
      updatedDat: record.updatedDat,
      createDat: record.createDat,
    });
  };
  const showDrawerAdd = () => {
    setOpenAddItem(true);
  };
  const onCloseAdd = () => {
    setIsSearch(false);
    formAdd.resetFields();
    setOpenAddItem(false);
    setSelectedKategoriGame(false);
    setLoadData(true);
  };
  const onCloseEdit = () => {
    formAdd.resetFields();
    setOpenEditItem(false);
    setSelectedKategoriGame(false);
    setLoadData(true);
  };
  const onFinishAdd = () => {
    setLoadingAddItem(true);
    setSelectedKategoriGame(false);
    const values = formAdd.getFieldsValue();

    if (values?.date_register) {
      const selectedDate = values?.date_register.format("DD MMMM YYYY");
      values.date_register = selectedDate;
    }

    console.log("wwww", values);

    // addListMaxMember(values);
    if (selectTab == 1) {
      handleSearchBracket(values);
    } else {
      handleSearchNonBracket(values);
    }
  };
  const onFinishEdit = () => {
    setLoadingEditItem(true);
    setSelectedKategoriGame(false);
    const values = formAdd.getFieldsValue();

    values.id_max_member = data[selectedRowKey].id_max_member;

    editListMaxMember(values);
  };

  const handleSearchBracket = (value) => {
    setSearchText(value);
    setIsSearch(true);

    // const filteredResults1 = dataSourceListBracket.filter((item) => {
    //   const matchesNamaTournament =
    //     value?.nama_tournament === "" ||
    //     item.nama_tournament
    //       ?.toLowerCase()
    //       .includes(value?.nama_tournament?.toLowerCase());

    //   return matchesNamaTournament;
    // });

    // const filteredResults2 = dataSourceListBracket.filter((item) => {
    //   const matchesDateRegister =
    //     value?.date_register === "" ||
    //     item.date_register
    //       .toLowerCase()
    //       .includes(value?.date_register?.toLowerCase());

    //   return matchesDateRegister;
    // });

    // const filteredResults3 = dataSourceListBracket.filter((item) => {
    //   const matchesNamaTeam =
    //     value?.nama_kategori_game === "" ||
    //     item.nama_kategori_game
    //       ?.toLowerCase()
    //       .includes(value?.nama_kategori_game?.toLowerCase());

    //   return matchesNamaTeam;
    // });

    const filteredData = dataSourceListBracket.filter((record) => {
      return Object.values(record).some(
        (fieldValue) =>
          fieldValue &&
          fieldValue.toString().toLowerCase().includes(value.toLowerCase()),
      );
    });

    // let tmp = uniqBy([
    //   ...filteredResults1,
    //   ...filteredResults2,
    //   ...filteredResults3,
    // ]);

    setFilteredDataSourceBracket(filteredData);
    // setFilteredDataSource(filteredData);
  };

  const handleSearchNonBracket = (value) => {
    setSearchText(value);
    setIsSearch(true);

    // const filteredResults1 = dataSourceListNonBracket.filter((item) => {
    //   const matchesNamaTournament =
    //     value?.nama_tournament === "" ||
    //     item.nama_tournament
    //       ?.toLowerCase()
    //       .includes(value?.nama_tournament?.toLowerCase());

    //   return matchesNamaTournament;
    // });

    // const filteredResults2 = dataSourceListNonBracket.filter((item) => {
    //   const matchesDateRegister =
    //     value?.date_register === "" ||
    //     item.date_register
    //       .toLowerCase()
    //       .includes(value?.date_register?.toLowerCase());

    //   return matchesDateRegister;
    // });

    // const filteredResults3 = dataSourceListNonBracket.filter((item) => {
    //   const matchesNamaTeam =
    //     value?.nama_kategori_game === "" ||
    //     item.nama_kategori_game
    //       ?.toLowerCase()
    //       .includes(value?.nama_kategori_game?.toLowerCase());

    //   return matchesNamaTeam;
    // });

    const filteredData = dataSourceListNonBracket.filter((record) => {
      return Object.values(record).some(
        (fieldValue) =>
          fieldValue &&
          fieldValue.toString().toLowerCase().includes(value.toLowerCase()),
      );
    });

    // let tmp = uniqBy([
    //   ...filteredResults1,
    //   ...filteredResults2,
    //   ...filteredResults3,
    // ]);

    // setFilteredDataSource(tmp);
    setFilteredDataSourceNonBracket(filteredData);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    //tambah
    setSortOrder({
      columnKey: sorter.columnKey,
      order: sorter.order,
    });
  };
  const [selectTab, setSelectTab] = useState("1");
  const onChange = (key) => {
    setSearchText("");
    setSelectTab(`${key}`);
  };

  const columnsNonBracket = [
    {
      title: "Nama Tournament",
      dataIndex: "name_tournament",
      key: "name_tournament",
      width: "20%",
      sorter: (a, b) => a.name_tournament.localeCompare(b.name_tournament), //tambah
      sortOrder: sortOrder.columnKey === "name_tournament" && sortOrder.order, //tambah
    },
    {
      title: "Urutan 1",
      dataIndex: "name_team",
      key: "name_team",
      width: "20%",
      sorter: (a, b) => a.name_team.localeCompare(b.name_team), //tambah
      sortOrder: sortOrder.columnKey === "name_team" && sortOrder.order, //tambah
    },

    {
      title: "Nama Maps",
      dataIndex: "name_maps",
      key: "name_maps",
      width: "20%",
      sorter: (a, b) => a.name_maps.localeCompare(b.name_maps), //tambah
      sortOrder: sortOrder.columnKey === "name_maps" && sortOrder.order, //tambah
    },
    {
      title: "Created Date",
      dataIndex: "createdat",
      key: "createDat",
      width: "20%",
      sorter: (a, b) => moment(a.createdat) - moment(b.createdat), //tambah
      sortOrder: sortOrder.columnKey === "createdat" && sortOrder.order, //tambah
    },
    {
      title: "Updated Date",
      dataIndex: "udpatedat",
      key: "udpatedat",
      width: "20%",
      sorter: (a, b) => moment(a.udpatedat) - moment(b.udpatedat), //tambah
      sortOrder: sortOrder.columnKey === "udpatedat" && sortOrder.order, //tambah
    },
    {
      title: "Actions",
      key: "actions",
      width: "20%",
      render: (_, record) => (
        <Space>
          <Button type="primary" onClick={() => showModal(record)}>
            Detail
          </Button>
          {/* <Popconfirm
            title="Delete Tournament"
            description="Apakah yakin ingin menghapus Tournament?"
            onConfirm={() => handleDelete(record)}
            okText="Hapus"
            cancelText="Tidak"
          >
            <Button style={{ marginLeft: 8 }} danger>
              Delete
            </Button>
          </Popconfirm> */}
        </Space>
      ),
    },
  ];
  const items = [
    {
      key: "1",
      label: "Scoreboard Bracket",
      children: (
        <>
          <Table
            bordered={true}
            dataSource={
              isSearch && selectTab == 1
                ? filteredDataSourceBracket
                : dataSourceListBracket
            }
            columns={columnsBracket}
            rowKey="key"
            scroll={{
              x: 1600,
            }}
            pagination={{ pageSize: 20 }} //tambah
            onChange={handleTableChange} //tambah
          />
        </>
      ),
    },
    {
      key: "2",
      label: "Scoreboard Non Bracket",
      children: (
        <>
          <Table
            bordered={true}
            dataSource={
              isSearch && selectTab == 2
                ? filteredDataSourceNonBracket
                : dataSourceListNonBracket
            }
            columns={columnsNonBracket}
            rowKey="key"
            scroll={{
              x: 1600,
            }}
            pagination={{ pageSize: 20 }} //tambah
            onChange={handleTableChange} //tambah
          />
        </>
      ),
    },
  ];

  return (
    <Layout>
      {contextHolder}
      <Breadcrumb
        items={[
          {
            href: "/dashboard/",
            title: (
              <>
                <HomeOutlined />
                <span>Dashboard</span>
              </>
            ),
          },
          {
            title: "List Scoreboard",
          },
        ]}
      />
      {/* <Spin
        // spinning={openEditItem ? loadingEditItem : loadingAddItem}
        spinning={false}
        tip="Loading..."
      >
        <Form
          layout="vertical"
          form={formAdd}
          className="dashboard-add-item-drawer-form"
          onFinish={openEditItem ? onFinishEdit : onFinishAdd}
        >
          <h3>Cari Berdasarkan</h3>

          <Row gutter={16}>
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="nama_tournament"
                label="Nama Tournament"
                rules={[
                  {
                    required: false,
                    message: "Silahkan Masukkan Nama Tournament",
                  },
                ]}
              >
                <Input placeholder="Masukan Nama Tournament" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={16} lg={8}>
              <Form.Item
                name="id_kategori_game"
                label="Kategori Game"
                rules={[
                  {
                    required: false,
                    message: "Silahkan Masukkan Kategori Game",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                  placeholder="Pilih Kategori Game"
                >
                  {dataKategoriGame
                    ? dataKategoriGame.map((item) => (
                        <Option
                          key={item.nama_kategori_game}
                          value={item.nama_kategori_game}
                        >
                          {item.nama_kategori_game}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginBottom: 20 }}>
            <Col span={24}>
              <Space className="dashboard-add-item-drawer-form-button">
                <Button onClick={onCloseAdd}>Batal</Button>
                <Button type="primary" htmlType="submit">
                  {"Cari"}
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Spin> */}

      <Spin spinning={loadingDeleteItem} tip="Loading...">
        <Flex vertical>
          <Space />

          <Input
            placeholder="Search"
            onChange={(e) =>
              selectTab == 1
                ? handleSearchBracket(e.target.value)
                : handleSearchNonBracket(e.target.value)
            }
            value={searchText}
            style={{ marginBottom: "20px", width: "50%", marginTop: 30 }}
          />
        </Flex>
        <Tabs
          defaultActiveKey="1"
          activeKey={selectTab}
          items={items}
          onChange={onChange}
        />
        <div style={{ marginTop: 20 }} />

        {/* <Table
          bordered={true}
          dataSource={
            isSearch ? filteredDataSource : dataSourceListMemberTournament
          }
          columns={columns}
          rowKey="key"
          scroll={{
            x: 1600,
          }}
          pagination={{ pageSize: 20 }} //tambah
          onChange={handleTableChange} //tambah
        /> */}
      </Spin>
      <Modal
        title="Jumlah Point"
        open={open}
        // onOk={handleOk}
        okText={dataModal?.is_approved == "Approved" ? "Ok" : "Approve"}
        confirmLoading={confirmLoading}
        onCancel={() => setOpen(false)}
        cancelText={"Rejected"}
        width={"80%"}
        footer={null}
        // cancelButtonProps={{
        //   onClick: (e) => handleCancel(),
        // }}
      >
        <Row gutter={24}>
          {/* TEAM AAAA */}

          <Col>
            {console.log("dataModal12322", dataModal)}
            {dataModal?.list_score &&
              dataModal?.list_score?.map((item, index) => {
                return (
                  <Space style={{ marginBottom: 20 }}>
                    <Input
                      style={{
                        marginRight: 10,
                        width: "100%",
                        backgroundColor: "#fff",
                        color: "#000",
                      }}
                      placeholder="kill"
                      value={item?.name_team}
                      disabled
                    />
                    <Input
                      style={{
                        marginRight: 10,
                        width: "100%",
                        backgroundColor: "#fff",
                        color: "#000",
                      }}
                      defaultValue={item?.jml_kill}
                      placeholder="kill"
                      type="number"
                      min={0}
                      disabled
                    />

                    <Input
                      style={{
                        marginRight: 10,
                        width: "100%",
                        backgroundColor: "#fff",
                        color: "#000",
                      }}
                      defaultValue={item?.position}
                      placeholder="posisi"
                      type="number"
                      min={0}
                      disabled
                    />

                    <Input
                      style={{
                        marginRight: 10,
                        backgroundColor: "#fff",
                        color: "#000",
                        width: 100,
                      }}
                      placeholder="kalkulasi"
                      defaultValue={item?.kalkulasi}
                      disabled
                    />
                    {/* <Button
                      type="primary"
                      onClick={() =>
                        handleUpdateScoreKill(dataScorePlayerReqModal[index])
                      }
                    >
                      Save
                    </Button> */}
                  </Space>
                );
              })}
          </Col>
        </Row>

        {/* {dataModal?.list_score_kill?.length && (
          <Button
            type="primary"
            htmlType="button"
            onClick={() => handleCalculateModal()}
          >
            {"Calculate"}
          </Button>
        )} */}
      </Modal>
    </Layout>
  );
};

export default DashboardScoreboard;
